import React,{useState, useEffect} from 'react';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { setDoctorAvailability,getDoctorAvailability } from '../../../api/apiHandler';
import Swal from 'sweetalert2';


function SetTimeslot() {

    const [doctorAvailable,setDoctorAvailable] = useState(null);
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    useEffect(()=>{
        getDoctorAvailable();
    },[])

    const getDoctorAvailable = () => {
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        getDoctorAvailability({}).then((response) => {
            
            response = JSON.parse(response)
            if (response.code == process.env.REACT_APP_SUCCESS) {
                Swal.close();
                // console.log("res",response.data);
                setDoctorAvailable(response.data)
            } else if(response.code == process.env.REACT_APP_ERROR){
                Swal.close();
                setDoctorAvailable([]);
            } else {
                console.log("else part");
                Swal.close();
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
        // Swal.close();
    }

    // const handleAvailability = (e) => {
    //     console.log("e-------",e.target.checked);
    // }

    const validationSchema = Yup.object().shape(
        // dayNames.reduce((acc, day) => {
        //     acc[`${day}_start`] = Yup.string()
        //       .required(`Please select ${day} start time`)
        //       .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, `Please select valid ${day} start time`);
        
        //     acc[`${day}_end`] = Yup.string()
        //       .required(`Please select ${day} end time`)
        //       .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, `Please select valid ${day} end time`);
            
        //     return acc;
        //   }, {})

        dayNames.reduce((acc, day) => {
        acc[`${day}_start`] = Yup.string()
            .required(`Please select ${day} start time`)
            .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, `Please select valid ${day} start time`);
    
        acc[`${day}_end`] = Yup.string()
            .required(`Please select ${day} end time`)
            .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, `Please select valid ${day} end time`)
            .test(
            `is-greater-than-start-${day}`,
            `${day} end time must be greater than ${day} start time`,
            function (value) {
                const { [`${day}_start`]: startTime } = this.parent;
                if (!startTime || !value) return true; // If either start or end time is not provided, let Yup.string().required() handle it.
                const [startHour, startMinute] = startTime.split(':').map(Number);
                const [endHour, endMinute] = value.split(':').map(Number);
                if (endHour > startHour) return true;
                if (endHour === startHour && endMinute > startMinute) return true;
                return false;
            }
            );
    
        return acc;
        }, {})
    );

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = async (data) => {
        const timeSlotObj = {
            data: dayNames.map((day, index) => ({
              day: index,
              start_time: data[`${day}_start`],
              end_time: data[`${day}_end`],
              is_available: data[`${day}_isAvailable`] ? 1 : 0,
            })),
        };

        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        setDoctorAvailability(timeSlotObj).then((response) => {
            response = JSON.parse(response)
            // Swal.close();
            if (response.code == process.env.REACT_APP_SUCCESS) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(()=>{
                    getDoctorAvailable();
                },2000)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }
    if (doctorAvailable == null) return <div className="container"><div className="mt-5"></div></div>
  return (
    <main className="flex-column">
        <Header/>
        <section className="share-medical">
            <div className="container">
            <div>
                <p className="font_22 font_600 mb-0">Set Timeslot</p>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/" className="font_14">
                        Home
                        </Link>
                    </li>
                    <li
                        className="breadcrumb-item active font_14"
                        aria-current="page"
                    >
                        Set Timeslot
                    </li>
                    </ol>
                </nav>
            </div>
            <div className="feedback-sec">
                <div className="card p-2 p-sm-4">
                <div className="row">
                    <div className="col-lg-2">
                    <p className="font_20 font_500">Weekday</p>
                    </div>
                    {/* <div className="col-lg-5">
                                <p className="font_18 font_500"></p>
                            </div>
                            <div className="col-lg-5">
                                <p className="font_18 font_500">Select end time</p>
                            </div> */}
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {doctorAvailable != null && doctorAvailable.length > 0 ? (doctorAvailable.map((item,index)=>{
                        const dayIndex = item.day;
                        const dayName = dayNames[dayIndex];
                        return(
                        <>
                            <div className="row" key={index}>
                                <div className="col-md-12 col-lg-2 col-12">
                                    <p className="font_20 font_500 ">{dayName}</p>
                                    <div className="form-check form-timeset form-switch justify-content-start">
                                        <label className="form-check-label font_500" htmlFor={`flexSwitchCheckDefault_${dayIndex}`}>
                                            Availability
                                        </label>
                                        {item.is_available == 1 ?
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={`${dayName}_isAvailable`}
                                                name={`${dayName}_isAvailable`}
                                                checked={item.is_available == 1}
                                                readOnly={item.is_available == 1}
                                                {...register(`${dayName}_isAvailable`)}
                                            /> :
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={`${dayName}_isAvailable`}
                                                name={`${dayName}_isAvailable`}
                                                {...register(`${dayName}_isAvailable`)}
                                            />
                                        }
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5 col-12">
                                    <div className="mb-3">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label"
                                        >
                                            Select start time
                                        </label>
                                        <div
                                            className="input-group p-0 time time-input"
                                            id={`timepicker1-${dayIndex}`}
                                        >
                                            <input
                                                className="form-control rounded-3"
                                                type="time"
                                                placeholder="HH:MM"
                                                id={`${dayName}_start`}
                                                name={`${dayName}_start`}
                                                // value={item.start_time}
                                                defaultValue={item.start_time} // Set the default value
                                                {...register(`${dayName}_start`)} // You may need to adjust the name attribute
                                            />
                                            <div className="invalid-feedback">{errors[`${dayName}_start`]?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5 col-12">
                                    <div className="mb-3">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label"
                                        >
                                            Select end time
                                        </label>
                                        <div
                                            className="input-group time p-0 time-input"
                                            id={`timepicker2-${dayIndex}`}
                                        >
                                            <input
                                                className="form-control rounded-3"
                                                type="time"
                                                placeholder="HH:MM"
                                                id={`${dayName}_end`}
                                                name={`${dayName}_end`}
                                                // value={item.end_time}
                                                defaultValue={item.end_time} // Set the default value
                                                {...register(`${dayName}_end`)} // You may need to adjust the name attribute
                                            />
                                            <div className="invalid-feedback">{errors[`${dayName}_end`]?.message}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </>
                        )
                    })) : 
                        (<>
                            <div className="row">
                                <div className="col-md-12 col-lg-2 col-12">
                                    <p className="font_20 font_500 ">{`Sunday`}</p>
                                    <div className="form-check form-timeset form-switch justify-content-start">
                                        <label className="form-check-label font_500" htmlFor="flexSwitchCheckDefault">
                                            Availability
                                        </label>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`${"Sunday"}_isAvailable`}
                                            name={`${"Sunday"}_isAvailable`}
                                            {...register(`${"Sunday"}_isAvailable`)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5 col-12">
                                    <div className="mb-3">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label"
                                        >
                                            Select start time
                                        </label>
                                        <div
                                            className="input-group p-0 time time-input"
                                            id={`timepicker1-${0}`}
                                        >
                                            <input
                                                className="form-control rounded-3"
                                                type="time"
                                                placeholder="HH:MM"
                                                id={`${"Sunday"}_start`}
                                                name={`${"Sunday"}_start`}
                                                {...register(`${"Sunday"}_start`)} // You may need to adjust the name attribute
                                            />
                                            <div className="invalid-feedback">{errors[`${"Sunday"}_start`]?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5 col-12">
                                    <div className="mb-3">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label"
                                        >
                                            Select end time
                                        </label>
                                        <div
                                            className="input-group time p-0 time-input"
                                            id={`timepicker2-${0}`}
                                        >
                                            <input
                                                className="form-control rounded-3"
                                                type="time"
                                                placeholder="HH:MM"
                                                id={`${"Sunday"}_end`}
                                                name={`${"Sunday"}_end`}
                                                {...register(`${"Sunday"}_end`)} // You may need to adjust the name attribute
                                            />
                                            <div className="invalid-feedback">{errors[`${"Sunday"}_end`]?.message}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-12 col-lg-2 col-12">
                                    <p className="font_20 font_500 ">{`Monday`}</p>
                                    <div className="form-check form-timeset form-switch justify-content-start">
                                        <label className="form-check-label font_500" htmlFor="flexSwitchCheckDefault">
                                            Availability
                                        </label>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`${"Monday"}_isAvailable`}
                                            name={`${"Monday"}_isAvailable`}
                                            {...register(`${"Monday"}_isAvailable`)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5 col-12">
                                    <div className="mb-3">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label"
                                        >
                                            Select start time
                                        </label>
                                        <div
                                            className="input-group p-0 time time-input"
                                            id={`timepicker1-${1}`}
                                        >
                                            <input
                                                className="form-control rounded-3"
                                                type="time"
                                                placeholder="HH:MM"
                                                id={`${"Monday"}_start`}
                                                name={`${"Monday"}_start`}
                                                {...register(`${"Monday"}_start`)} // You may need to adjust the name attribute
                                            />
                                            <div className="invalid-feedback">{errors[`${"Monday"}_start`]?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5 col-12">
                                    <div className="mb-3">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label"
                                        >
                                            Select end time
                                        </label>
                                        <div
                                            className="input-group time p-0 time-input"
                                            id={`timepicker2-${1}`}
                                        >
                                            <input
                                                className="form-control rounded-3"
                                                type="time"
                                                placeholder="HH:MM"
                                                id={`${"Monday"}_end`}
                                                name={`${"Monday"}_end`}
                                                {...register(`${"Monday"}_end`)} // You may need to adjust the name attribute
                                            />
                                            <div className="invalid-feedback">{errors[`${"Monday"}_end`]?.message}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-12 col-lg-2 col-12">
                                    <p className="font_20 font_500 ">{`Tuesday`}</p>
                                    <div className="form-check form-timeset form-switch justify-content-start">
                                        <label className="form-check-label font_500" htmlFor="flexSwitchCheckDefault">
                                            Availability
                                        </label>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`${"Tuesday"}_isAvailable`}
                                            name={`${"Tuesday"}_isAvailable`}
                                            {...register(`${"Tuesday"}_isAvailable`)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5 col-12">
                                    <div className="mb-3">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label"
                                        >
                                            Select start time
                                        </label>
                                        <div
                                            className="input-group p-0 time time-input"
                                            id={`timepicker1-${2}`}
                                        >
                                            <input
                                                className="form-control rounded-3"
                                                type="time"
                                                placeholder="HH:MM"
                                                id={`${"Tuesday"}_start`}
                                                name={`${"Tuesday"}_start`}
                                                {...register(`${"Tuesday"}_start`)} // You may need to adjust the name attribute
                                            />
                                            <div className="invalid-feedback">{errors[`${"Tuesday"}_start`]?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5 col-12">
                                    <div className="mb-3">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label"
                                        >
                                            Select end time
                                        </label>
                                        <div
                                            className="input-group time p-0 time-input"
                                            id={`timepicker2-${2}`}
                                        >
                                            <input
                                                className="form-control rounded-3"
                                                type="time"
                                                placeholder="HH:MM"
                                                id={`${"Tuesday"}_end`}
                                                name={`${"Tuesday"}_end`}
                                                {...register(`${"Tuesday"}_end`)} // You may need to adjust the name attribute
                                            />
                                            <div className="invalid-feedback">{errors[`${"Tuesday"}_end`]?.message}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-12 col-lg-2 col-12">
                                    <p className="font_20 font_500 ">{`Wednesday`}</p>
                                    <div className="form-check form-timeset form-switch justify-content-start">
                                        <label className="form-check-label font_500" htmlFor="flexSwitchCheckDefault">
                                            Availability
                                        </label>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`${"Wednesday"}_isAvailable`}
                                            name={`${"Wednesday"}_isAvailable`}
                                            {...register(`${"Wednesday"}_isAvailable`)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5 col-12">
                                    <div className="mb-3">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label"
                                        >
                                            Select start time
                                        </label>
                                        <div
                                            className="input-group p-0 time time-input"
                                            id={`timepicker1-${3}`}
                                        >
                                            <input
                                                className="form-control rounded-3"
                                                type="time"
                                                placeholder="HH:MM"
                                                id={`${"Wednesday"}_start`}
                                                name={`${"Wednesday"}_start`}
                                                {...register(`${"Wednesday"}_start`)} // You may need to adjust the name attribute
                                            />
                                            <div className="invalid-feedback">{errors[`${"Wednesday"}_start`]?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5 col-12">
                                    <div className="mb-3">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label"
                                        >
                                            Select end time
                                        </label>
                                        <div
                                            className="input-group time p-0 time-input"
                                            id={`timepicker2-${3}`}
                                        >
                                            <input
                                                className="form-control rounded-3"
                                                type="time"
                                                placeholder="HH:MM"
                                                id={`${"Wednesday"}_end`}
                                                name={`${"Wednesday"}_end`}
                                                {...register(`${"Wednesday"}_end`)} // You may need to adjust the name attribute
                                            />
                                            <div className="invalid-feedback">{errors[`${"Wednesday"}_end`]?.message}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-12 col-lg-2 col-12">
                                    <p className="font_20 font_500 ">{`Thursday`}</p>
                                    <div className="form-check form-timeset form-switch justify-content-start">
                                        <label className="form-check-label font_500" htmlFor="flexSwitchCheckDefault">
                                            Availability
                                        </label>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`${"Thursday"}_isAvailable`}
                                            name={`${"Thursday"}_isAvailable`}
                                            {...register(`${"Thursday"}_isAvailable`)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5 col-12">
                                    <div className="mb-3">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label"
                                        >
                                            Select start time
                                        </label>
                                        <div
                                            className="input-group p-0 time time-input"
                                            id={`timepicker1-${4}`}
                                        >
                                            <input
                                                className="form-control rounded-3"
                                                type="time"
                                                placeholder="HH:MM"
                                                id={`${"Thursday"}_start`}
                                                name={`${"Thursday"}_start`}
                                                {...register(`${"Thursday"}_start`)} // You may need to adjust the name attribute
                                            />
                                            <div className="invalid-feedback">{errors[`${"Thursday"}_start`]?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5 col-12">
                                    <div className="mb-3">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label"
                                        >
                                            Select end time
                                        </label>
                                        <div
                                            className="input-group time p-0 time-input"
                                            id={`timepicker2-${4}`}
                                        >
                                            <input
                                                className="form-control rounded-3"
                                                type="time"
                                                placeholder="HH:MM"
                                                id={`${"Thursday"}_end`}
                                                name={`${"Thursday"}_end`}
                                                {...register(`${"Thursday"}_end`)} // You may need to adjust the name attribute
                                            />
                                            <div className="invalid-feedback">{errors[`${"Thursday"}_end`]?.message}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-12 col-lg-2 col-12">
                                    <p className="font_20 font_500 ">{`Friday`}</p>
                                    <div className="form-check form-timeset form-switch justify-content-start">
                                        <label className="form-check-label font_500" htmlFor="flexSwitchCheckDefault">
                                            Availability
                                        </label>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`${"Friday"}_isAvailable`}
                                            name={`${"Friday"}_isAvailable`}
                                            {...register(`${"Friday"}_isAvailable`)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5 col-12">
                                    <div className="mb-3">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label"
                                        >
                                            Select start time
                                        </label>
                                        <div
                                            className="input-group p-0 time time-input"
                                            id={`timepicker1-${5}`}
                                        >
                                            <input
                                                className="form-control rounded-3"
                                                type="time"
                                                placeholder="HH:MM"
                                                id={`${"Friday"}_start`}
                                                name={`${"Friday"}_start`}
                                                {...register(`${"Friday"}_start`)} // You may need to adjust the name attribute
                                            />
                                            <div className="invalid-feedback">{errors[`${"Friday"}_start`]?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5 col-12">
                                    <div className="mb-3">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label"
                                        >
                                            Select end time
                                        </label>
                                        <div
                                            className="input-group time p-0 time-input"
                                            id={`timepicker2-${5}`}
                                        >
                                            <input
                                                className="form-control rounded-3"
                                                type="time"
                                                placeholder="HH:MM"
                                                id={`${"Friday"}_end`}
                                                name={`${"Friday"}_end`}
                                                {...register(`${"Friday"}_end`)} // You may need to adjust the name attribute
                                            />
                                            <div className="invalid-feedback">{errors[`${"Friday"}_end`]?.message}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-12 col-lg-2 col-12">
                                    <p className="font_20 font_500 ">{`Saturday`}</p>
                                    <div className="form-check form-timeset form-switch justify-content-start">
                                        <label className="form-check-label font_500" htmlFor="flexSwitchCheckDefault">
                                            Availability
                                        </label>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`${"Saturday"}_isAvailable`}
                                            name={`${"Saturday"}_isAvailable`}
                                            {...register(`${"Saturday"}_isAvailable`)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5 col-12">
                                    <div className="mb-3">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label"
                                        >
                                            Select start time
                                        </label>
                                        <div
                                            className="input-group p-0 time time-input"
                                            id={`timepicker1-${6}`}
                                        >
                                            <input
                                                className="form-control rounded-3"
                                                type="time"
                                                placeholder="HH:MM"
                                                id={`${"Saturday"}_start`}
                                                name={`${"Saturday"}_start`}
                                                {...register(`${"Saturday"}_start`)} // You may need to adjust the name attribute
                                            />
                                            <div className="invalid-feedback">{errors[`${"Saturday"}_start`]?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5 col-12">
                                    <div className="mb-3">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label"
                                        >
                                            Select end time
                                        </label>
                                        <div
                                            className="input-group time p-0 time-input"
                                            id={`timepicker2-${6}`}
                                        >
                                            <input
                                                className="form-control rounded-3"
                                                type="time"
                                                placeholder="HH:MM"
                                                id={`${"Saturday"}_end`}
                                                name={`${"Saturday"}_end`}
                                                {...register(`${"Saturday"}_end`)} // You may need to adjust the name attribute
                                            />
                                            <div className="invalid-feedback">{errors[`${"Saturday"}_end`]?.message}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </>)
                    }
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6">
                            <button type="submit" className="submit-btn mt-3 w-100">Submit</button>
                        </div>
                    </div>
                </form>
                </div>
            </div>
            </div>
        </section>
        <Footer/>
    </main>
  )
}

export default SetTimeslot;