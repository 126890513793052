import React, { useEffect, useState,useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { SitePal } from "sitepal-react-v2";
import { verifyToken,storeDataSitepal,getDoctorProfile } from '../../../api/apiHandler';
import Swal from 'sweetalert2';
// import randtoken from 'rand-token'
import { v4 as uuidv4 } from 'uuid';

function App() {
  
  const navigate = useNavigate();
  const startZoneRef = useRef(null);
  var human_data = {};
  // console.log('human_data: ', human_data);
  const [humanData, setHumanData] = useState({});
  console.log('humanData: ', humanData);
  // const [questions] = useState([
  //   "What is your age?",
  //   "Are you male, female or other?",
  //   "Are you pregnant?",
  //   "Can you describe your symptoms?",
  //   "Do you have any other symptoms?",
  //   "Can you please describe your other symptoms?",
  //   "",
  // ]);
  const [questions] = useState([
    "Please tell me your name",
    "What is your age?",
    "And What is your gender?",
    "Please tell me your symptoms.",
    "Please tell me if you have any more symptoms. Otherwise, say “I have no more symptoms”",
    "",
  ]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [sayAudio, setSayAudio] = useState(questions[0]);
  const {  interimTranscript, resetTranscript,transcript, listening } = useSpeechRecognition();
  console.log("listening",listening);
  const [sayText, setSayText] = useState(null);
  const [welcomeText, setWelcomeText] = useState("Welcome to the virtual assistant!");

  const [countryId, setCountryId] = useState(0);
  const [handleIsabelApiCall, setHandleIsabelApiCall] = useState(true);
  
  // useEffect(()=>{
  //   console.log("useeffect calll!!!!");
  //   if(!listening){

  //     if (currentQuestionIndex == 1) {
  //       if(human_data.age == undefined){
  //         SpeechRecognition.startListening();
  //       }
  //     }
  
  //   }

  // },[listening])

  useEffect(()=>{
    checkActiveUser();
  },[])

  const checkActiveUser = () => {
    if(localStorage.getItem("user_token") !== undefined || localStorage.getItem("user_token") !== null){
      verifyToken({}).then((response) => {
        response = JSON.parse(response)
        console.log('response: ', response);
        if (response.code == process.env.REACT_APP_SUCCESS) {
          getDoctorData()
          console.log("success");
        } else {
          console.log("error");
        }
      })
    } else {
      console.log("without token");
      navigate('/')
    }
  }

  const getDoctorData = () => {
    getDoctorProfile({}).then((response) => {
      response = JSON.parse(response)
      console.log("response",response);
      if (response.code == process.env.REACT_APP_SUCCESS) {
          setCountryId(response.data.country_id)
      }
  });
  }

  const speakResponse = (text) => {
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(text);
    synth.speak(utterance);
  };
  const handleUserResponse = (response) => {
    if (currentQuestionIndex < questions.length) {
      console.log('response=====> ',response)
      handleQA(response)
    }
  };
  const startDemo = () => {
    checkActiveUser();
    startZoneRef.current.style.display = 'none';
    // setSayText(['Hello, good morning. Welcome to Monte Sereno Health.', 3, 1, 3]);
    setSayText(['Hello, and Welcome to Monte Sereno Health.', 3, 1, 3]);
    setTimeout(() => {
      
      setSayText([`${questions[currentQuestionIndex]}`, 3, 1, 3])
      setWelcomeText(questions[currentQuestionIndex]);
      SpeechRecognition.startListening();
      // SpeechRecognition.startListening({ continuous: true, timeout: 10000 });
      setCurrentQuestionIndex(currentQuestionIndex + 1);
  }, 5000);
  }

  const handleQA = (msg) => {
    const updatedData = { ...humanData };

    if (currentQuestionIndex === 1) {
      const name = msg || '';
      updatedData.name = name.toLowerCase();
    }
    if (currentQuestionIndex === 2) {
      const age = parseInt(msg) || parseInt(text2num(msg)) || '';
      updatedData.age = age.toString();
    }
    if (currentQuestionIndex === 3) {
      const gender = msg.toLowerCase().includes('female') ? 'female' : 'male';
      updatedData.gender = gender;
    }
  if (currentQuestionIndex === 4 || currentQuestionIndex === 5 ) {
      const symptoms = updatedData.symptoms || '';
      console.log('updatedData.symptoms: ', updatedData.symptoms);
      updatedData.symptoms = `${symptoms}, ${msg}`;
    }

    setHumanData(updatedData);

    // if (currentQuestionIndex == 1) {
    //   if (!!parseInt(msg)) human_data.age = parseInt(msg).toString();
    //   else if (!!parseInt(text2num(msg))) human_data.age = parseInt(text2num(msg)).toString();
    // }
    // if (currentQuestionIndex == 2) {
    //   if (msg.toLowerCase().includes('male')) {
    //     msg = 'male';
    //   }
    //   if (msg.toLowerCase().includes('female')) {
        
    //     msg = 'female';
    //   } else {
    //     msg = 'male';
    //   }
    //   human_data.gender = msg.toLowerCase();
    // }
    // if (currentQuestionIndex == 3) {
    //   if (msg.toLowerCase().includes('yes')) {
    //     msg = 'yes';
    //   }
    //   if (msg.toLowerCase().includes('no')) {
    //     msg = 'no';
    //   } else {
    //     msg = 'no';
    //   }
    //   human_data.pregnant = msg.toLowerCase();
    // }
    // if (currentQuestionIndex == 4) {
    //   console.log("msg-------4",msg);
    //   human_data.symptoms = msg || '';
    //   console.log('human_data.symptoms: 4', human_data.symptoms);
    // }
    // if (currentQuestionIndex == 5) {
    //   console.log("msg-------5",msg);
    //   human_data.symptoms += `. ${msg}`;
    //   console.log('human_data.symptoms: 5', human_data.symptoms);
    // }
    // if (currentQuestionIndex == 6) {
    //   console.log("msg-------6",msg);
    //   human_data.symptoms += `. ${msg}`;
    //   console.log('human_data.symptoms: 6', human_data.symptoms);
    // }
   
    setTimeout(() => {
        // SpeechRecognition.stopListening();
        setSayText([`${questions[currentQuestionIndex]}`, 3, 1, 3])
        setWelcomeText(questions[currentQuestionIndex]);
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        // SpeechRecognition.startListening();
        SpeechRecognition.startListening({ continuous: true, timeout: 5000 });
        
    }, 2000);
    console.log('human_data=>', human_data)
    
    console.log("questions.length-------->>>>>>",questions.length);
    console.log("currentQuestionIndex-------->>>>>>",currentQuestionIndex);

    if(questions.length-1==currentQuestionIndex){
        if(handleIsabelApiCall){
          setHandleIsabelApiCall(false)
          SpeechRecognition.stopListening();
          // const uuid = randtoken.generate(16, "abcdefghijklnmopqrstuvwxyz");
          const uuid = uuidv4();
          console.log("Generated uuid:", uuid);
          var newData ={
            "specialties": "28",
            "dob": "19850818",
            "sex": (updatedData.gender=='female')?"f":"m",
            "country_id":countryId,
            "querytext": (updatedData.symptoms!=undefined)?updatedData.symptoms:"hypertension",
            "uuid": uuid
          }
          console.log('newData: ', newData);
          
          Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading()
            }
          });
          storeDataSitepal(newData).then((res) => {
            var response = JSON.parse(res)
            console.log("res------->>>>>>>>>",response);
            Swal.close()
            SpeechRecognition.stopListening();
            if (response.code == process.env.REACT_APP_SUCCESS) {
              Swal.fire({
                icon: 'question',
                title: 'Are you sure you want to start the Vital Signs App ?',
                showCancelButton: true,
                confirmButtonText: 'Yes, Start',
                cancelButtonText: 'Cancel',
              }).then(async (result) => {
                if (result.isConfirmed) {
                    // window.location.href = `${process.env.REACT_APP_BINAH_WEB_URL}${uuid}_${localStorage.getItem("user_id")}`
                } else {
                    navigate(`/doctor/report-generate/${uuid}`)
                }
              });
            }
            else {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: response.message,
                toast: true,
                showConfirmButton: false,
                timer: 2000
              })
            }
          })
        }
    }
  };

  var Small = {
    zero: 0,
    one: 1,
    two: 2,
    three: 3,
    four: 4,
    five: 5,
    six: 6,
    seven: 7,
    eight: 8,
    nine: 9,
    ten: 10,
    eleven: 11,
    twelve: 12,
    thirteen: 13,
    fourteen: 14,
    fifteen: 15,
    sixteen: 16,
    seventeen: 17,
    eighteen: 18,
    nineteen: 19,
    twenty: 20,
    thirty: 30,
    forty: 40,
    fifty: 50,
    sixty: 60,
    seventy: 70,
    eighty: 80,
    ninety: 90,
  };

  var Magnitude = {
    thousand: 1000,
    million: 1000000,
    billion: 1000000000,
    trillion: 1000000000000,
    quadrillion: 1000000000000000,
    quintillion: 1000000000000000000,
    sextillion: 1000000000000000000000,
    septillion: 1000000000000000000000000,
    octillion: 1000000000000000000000000000,
    nonillion: 1000000000000000000000000000000,
    decillion: 1000000000000000000000000000000000,
  };

  const text2num = (s) => {
    let a, n, g;
    try {
      a = s.toString().split(/[\s-]+/);
      n = 0;
      g = 0;
      for (let i = 0; i < a.length; i++) {
        if (!!parseInt(a[i])) return a[i];
        var x = Small[a[i]];
        if (x != null && g == 0) {
          g = x;
        } else if (g >= 20 && x < 10) {
          g = g + x;
        } else if (a[i] == 'hundred') {
          g = g * 100;
        } else {
          x = Magnitude[a[i]];
          if (x != null) {
            n = n + g * x;
            g = 0;
          }
        }
      }
      return n + g;
    } catch (error) {
      return s;
    }
  }

  useEffect(() => {
    let timeout;
    if (transcript) {
      
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        handleUserResponse(transcript);
        resetTranscript(); 
      }, 1000); 
    } else {
      console.log("transscrpt functirn calluued");
    }
    return () => clearTimeout(timeout);
  }, [transcript, resetTranscript]);

  return (
    <> 
     <div className="container-fluid p-0 h-100">
      <div className="row align-items-center justify-content-center h-100">
        <div className="col-md-12 p-0 text-center">
          <div className="p-3">
            
            <Link to="/doctor/home"><img src={process.env.PUBLIC_URL + "/assets/images/favicon.png"} alt="" style={{ height: '40px' }} /></Link>
          </div>
          <div className="position-relative">
            <div className="age-ans">
              <p className="font_30">{welcomeText}</p>
              <button id="startZone"  ref={startZoneRef}
                onClick={() => {
                  startDemo();
                }}
                className="result-btn"
                type="button"
              >
                PRESS START
              </button>
            </div>
            <div>
              <div className="img-set mx-auto"> 
              
              <SitePal
                  embed='8777152,500,1000,"",1,1, 2760636, 0,1,1,"YsBPfbYTtqcna5YvD1IXss0WWpY5jTSR",0,1'
                  sayAudio={sayAudio}
                  sayText={sayText}
                />
               
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
export default App;