import React, { useState, useEffect } from 'react';
import { useParams,useNavigate,Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { verifyEmail } from '../../../api/apiHandler';


function EmailVerification() {
    const { token } = useParams();
    console.log('token',token)
    
    const [verificationStatus, setVerificationStatus] = useState(null);

    useEffect(() => {
        // Make an API call to your backend to verify the token
        verifyEmail({ "token": token }).then((res) => {
            var response = JSON.parse(res)
            if (response.code == process.env.REACT_APP_SUCCESS) {
                setVerificationStatus(1)
            }
            else {
                setVerificationStatus(0)
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        });
    }, [token]);
    if ( verificationStatus == null) return <> </>
    if (verificationStatus) {
        return (
            <main className="msh--main bg-img">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6 col-xl-5 col-xxl-5 col-12">
                            <div className="login-sec">
                                <div className="logo-size">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/checkmark--1.svg"} alt="logo" />
                                </div>
                                <p className="font_26 font_600 text-center mb-2 mt-md-3">Email Verified!</p>
                                <p className="text-center">Your email address has been verified.</p>
                                <Link to="/" className="login-btn mb-3">Back to Login</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    } else {
        return (
            <main className="msh--main bg-img">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-6 col-xl-5 col-xxl-5 col-12">
                        <div className="login-sec">
                            <div className="logo-size">
                                <img src={process.env.PUBLIC_URL +"/assets/images/icon/info.svg"} alt="logoss" />
                            </div>
                            <p className="text-center">Oops! something wrong.</p>
                            <Link to="/" className="login-btn mb-3">Back to Login</Link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        );
    }

}

export default EmailVerification;