import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { doctorAppointment } from '../../../api/apiHandler';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';


function DoctorAppointment() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [doctorAppointmentList, setDoctorAppointmentList] = useState(null);

  useEffect(() => {
    console.log('useEffect call the function')
    Swal.fire({
      title: 'Please wait...',
      didOpen: () => {
        Swal.showLoading()
      }
    })
    doctorAppointment({ "page": page, "record_count": perPage }).then((res) => {
      var response = JSON.parse(res)
      if (response.code == process.env.REACT_APP_SUCCESS) {
        Swal.close()
        setDoctorAppointmentList(response.data.doctors)
      }
      else {
        Swal.close()
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      }
    });
  }, []);
  if (doctorAppointmentList == null) return <> </>

  return (
    <>
    <Header />
    <main className="">
      {console.log('doctorAppointmentList call the function',doctorAppointmentList)}
      <section>
        <div className="container">
          <div className="row justify-content-center mt-4">
            <div className="col-lg-12">
              <div className="card p-2 p-sm-3 mb-3">
                <div className="appointments">
                  {
                    doctorAppointmentList && doctorAppointmentList.map((item, index) => {
                      return (
                        <div className="appointment-list align-items-start">
                          <div className="profile-info-widget d-flex gap-3">
                            <div className="icon">
                              <img src={item.profile_image} alt="image" />
                            </div>
                            <div>
                              <h5>{item.first_name+" "+item.middle_name+" "+item.last_name}</h5>
                              <div className="patient-details">
                                <p><i className="fas fa-envelope"></i> {item.email}</p>
                                <p className="mb-0"><i className="fas fa-phone"></i>  {item.country_code +" "+item.phone_no}</p>
                              </div>
                            </div>
                          </div>
                          <div className="appointment-action">
                          <Link to={`/doctor/appointment/id=${item.id}`}  className="connect-btn">Book Appointment</Link>
                          </div>
                        </div>

                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <Footer />
    </>
  );


}

export default DoctorAppointment;