import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import { changePassword } from '../../../api/apiHandler';
import Swal from 'sweetalert2'
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';

function ChangePassword() {
    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        oldPassword: Yup.string()
            .required('Please enter old password')
            .min(6, 'Old password must be at least 6 characters'),
        newPassword: Yup.string()
            .required('Please enter new password')
            .min(6, 'New password must be at least 6 characters'),
        confirmPassword: Yup.string()
            .required('Please enter confirm password')
            .oneOf([Yup.ref('newPassword'), null], 'New password and confirm password does not match'),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;
    const onSubmit = (data) => {
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        changePassword({ "old_password": data.oldPassword, "new_password": data.newPassword }).then((response) => {
            response = JSON.parse(response)
            Swal.close()
            if (response.code == process.env.REACT_APP_SUCCESS) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(() => {
                    if(localStorage.getItem("user_role") == 1){
                        navigate("/patient/home")
                    } else {
                        navigate("/doctor/home")
                    }
                    // navigate("/home")
                }, 2000)
            } else {
                
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }
    return (
        <>
            <Header />
            <div className="container h-define change-pass">
                <div className="mt-5">
                    <p className="font_22 font_600 mb-0">Change password</p>
                    <nav style={{ "--bs-breadcrumb-divider": ">" }} aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" className="font_14">Home</Link></li>
                            <li className="breadcrumb-item active font_14" aria-current="page">Change password</li>
                        </ol>
                    </nav>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <form className="form-horizontal m-t-30" onSubmit={handleSubmit(onSubmit)}>
                            <div className="card-profile feedback-sec">
                                <p className="font_22  font_600  mb-0">Change password</p>
                                <p className="text-gray font_15">New password must be different from the last used</p>
                                <hr />
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="oldPassword" className="form-label">Old password</label>
                                            <input type="password" {...register('oldPassword')} className="form-control" placeholder="Enter old password" />
                                            <div className="invalid-feedback">{errors.oldPassword?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="newPassword" className="form-label">New password</label>
                                            <input type="password"  {...register('newPassword')} className="form-control" placeholder="Enter new password" />
                                            <div className="invalid-feedback">{errors.newPassword?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="confirmPassword" className="form-label">Confirm password</label>
                                            <input type="password" {...register('confirmPassword')} className="form-control" id="confirmPassword" placeholder="Enter your confirm password" />
                                            <div className="invalid-feedback">{errors.confirmPassword?.message}</div>
                                        </div>
                                    </div>

                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-6">
                                            <button type="submit" className="submit-btn mt-3 w-100">Update</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ChangePassword;