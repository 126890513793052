import React, { useState, useEffect, useRef } from 'react';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';

import { Link } from 'react-router-dom';
import { getProfile, generateCodes, permissionCodeRequestDoctorList, sendPermissionCode} from '../../../api/apiHandler';

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import moment from 'moment-timezone';

function PermissionCode() {

    const navigate = useNavigate();
    const [shareMedicalCode, setShareMedicalCode] = useState(null);
    const [prescriptionCode, setPrescriptionCode] = useState(null);
    const [codeExpiryDateTime, setCodeExpiryDateTime] = useState(null);
    const [codeRequestDoctorsList, setCodeRequestDoctorssList] = useState([]);
    const [totalRecordCount, setTotalRecordCount] = useState(0)
    const [pageNo, setPageNo] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [userTimezone, setUserTimezone] = useState('');

    useEffect(() => {
        // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const timezone = moment.tz.guess();
        setUserTimezone(timezone);
        if(searchQuery == ''){
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading();
                }
            });
        }

        getProfile({}).then((response) => {
            response = JSON.parse(response)
            if (response.code == process.env.REACT_APP_SUCCESS) {
                if(response.data.share_medical_download_code == null && response.data.prescription_code == null && response.data.code_expiration_date == null){
                    generateCode();
                } else {
                    setShareMedicalCode(response.data.share_medical_download_code)
                    setPrescriptionCode(response.data.prescription_code)
                    setCodeExpiryDateTime(moment.utc(response.data.code_expiration_date).tz(timezone).format('DD-MMM-YYYY hh:mm A'))
                }
            }
            Swal.close()
        });

        permissionCodeRequestList()
        // Swal.close();
    }, [pageNo,searchQuery]);

    const permissionCodeRequestList = () => {
        permissionCodeRequestDoctorList({ "page": pageNo, "record_count": perPage, "search_text": searchQuery }).then((response) => {
            Swal.close();
            response = JSON.parse(response)

            console.log("respose",response);
            if (response.code == process.env.REACT_APP_SUCCESS) {
                setCodeRequestDoctorssList(response.data.doctor_data)
                setTotalRecordCount(Math.ceil(response.data.total_record_count / perPage))
            } 
            // else if(response.code == process.env.REACT_APP_NOT_FOUND){
            //     setCodeRequestDoctorssList([]);
            //     Swal.fire({
            //         position: 'top-end',
            //         icon: 'info',
            //         title: response.message,
            //         toast: true,
            //         showConfirmButton: false,
            //         timer: 2000
            //     })
            // } 
            else {
                setCodeRequestDoctorssList([]);
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        }).catch((error) => {
            console.log('error: ', error.response.status);
            if(error.response.status == process.env.REACT_APP_NOT_FOUND){
                setCodeRequestDoctorssList([]);
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: 'No data found!',
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            } else {
                setCodeRequestDoctorssList([]);
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Something went wrong',
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }

        })
    }
    
    const handlePageClick = (event) => {
        setPageNo(event.selected + 1)
    };

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
    };

    const generateCode = () => {
        generateCodes({}).then((response) => {
            response = JSON.parse(response)
            if (response.code == process.env.REACT_APP_SUCCESS) {
                setShareMedicalCode(response.data.share_medical_download_code)
                setPrescriptionCode(response.data.prescription_code)
                setCodeExpiryDateTime(moment.utc(response.data.expiration_date).tz(userTimezone).format('DD-MMM-YYYY hh:mm A'))
            }
        });
    }

    const handleSendCode = (doctor_data,type) => {        
        const sendCodeStatusObj = {
            "doctor_email": doctor_data.doctor_permission_codes_request_information.doctor_email,
            "request_id": doctor_data.request_id,
            "type": parseInt(type)
        }
        
        sendPermissionCode(sendCodeStatusObj).then((response) => {
            response = JSON.parse(response)
            if (response.code == process.env.REACT_APP_SUCCESS) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                permissionCodeRequestList()

            } else {
              console.log("errors--------",response);
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: response.message,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            })
            }
        })
    }

  return (
    <main className="bg-color">
        <Header/>
            <section className="share-medical">
                <div className="container ">
                    <div>
                    <p className="font_22 font_600 mb-0">Permission Code</p>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/" className="font_14">
                            Home
                            </Link>
                        </li>
                        <li
                            className="breadcrumb-item active font_14"
                            aria-current="page"
                        >
                            Permission Code
                        </li>
                        </ol>
                    </nav>
                    </div>
                    <div className="row">
                    <div className="col-lg-12">
                        <form>
                        <div className="card-profile feedback-sec">
                            <p className="font_22  font_600  mb-0">Permission Code</p>
                            <p className="text-gray font_15">Generate permission code</p>
                            <hr />
                            <div className="row">
                            <div className="col-lg-4">
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Share Medical
                                        Code</label>
                                    <p className="font_22 font_500">{shareMedicalCode}</p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Prescription
                                        Code</label>
                                    <p className="font_22 font_500">{prescriptionCode}</p>
                                </div>


                            </div>
                            <div className="col-lg-4">
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Expiry
                                        Date & Time</label>
                                    <p className="font_22 font_500">{codeExpiryDateTime}</p>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-6 col-lg-4">
                                <button type="button" className="submit-btn mt-3 w-100" onClick={generateCode}>
                                    Regenerate code
                                </button>
                                </div>
                            </div>
                            </div>
                        </div>
                        </form>
                    </div>
                    </div>
                    <div className="card">
                        <div className="card-body doctor-table-card">
                        <div className="table-responsive">
                        <div className="row justify-content-end">
                        <div className="search-bar col-lg-3 col-md-3">
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Search..."
                                value={searchQuery}
                                onChange={handleSearch}
                            />
                        </div>
                            
                        </div>
                            
                            <table id="mangeUser" className="table table-hover dataTable js-exportable">
                            <thead>
                                <tr>
                                <th >ID</th>
                                <th >Doctor Name</th>
                                <th >Phone Number</th>
                                <th >Email Address</th>
                                <th >Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                
                                {
                                    codeRequestDoctorsList && codeRequestDoctorsList.map((item, index) => {
                                    return (
                                    <tr id={item.request_id} key={index}>
                                        <td >
                                        {item.request_id}
                                        </td>

                                        <td>
                                        <h3 className="doc-details">
                                            <div className="doc-profile">
                                            <img src={item.doctor_permission_codes_request_information.profile_image} alt="" />
                                            </div>
                                            {/* {item.first_name} {item.middle_name} {item.last_name} */}
                                            {item.doctor_permission_codes_request_information.doctor_full_name}
                                        </h3>
                                        </td>
                                        <td >
                                        {item.doctor_permission_codes_request_information.country_code + " " + item.doctor_permission_codes_request_information.doctor_phone_no}
                                        </td>
                                        <td >
                                        {item.doctor_permission_codes_request_information.doctor_email}
                                        </td>
                                        <td >
                                            <div className="d-flex gap-2">
                                                <button className="btn btn-sm bg-success-light font_16" onClick={()=>handleSendCode(item,process.env.REACT_APP_PERMISSION_CODE_REQUEST_ACCEPTED)}>
                                                    <i className="fas fa-check"></i> Accept
                                                </button>
                                                <button className="btn btn-sm bg-danger-light font_16" onClick={()=>handleSendCode(item,process.env.REACT_APP_PERMISSION_CODE_REQUEST_REJECTED)}>
                                                    <i className="fas fa-times"></i> Reject
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    )
                                })
                                }
                            </tbody>
                            </table>
                        
                            <ReactPaginate
                            breakLabel="..."
                            nextLabel=" > "
                            onPageChange={handlePageClick}
                            pageCount={totalRecordCount}
                            previousLabel=" < "
                            renderOnZeroPageCount={null}
                            containerClassName="pagination"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            disabledClassName="disabled"
                            activeClassName="active"
                            activeLinkClassName="active-link"
                            />
                        
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        <Footer/>
    </main>
  )
}

export default PermissionCode;