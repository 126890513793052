import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import Swal from 'sweetalert2';
// import randtoken from 'rand-token';
import { v4 as uuidv4 } from 'uuid';

export default function AiDoctor2() {
    
    const navigate = useNavigate();
    
    const handleStartBinah = () => {
        Swal.fire({
            icon: 'question',
            title: 'Are you sure you want to start the Vital Signs App ?',
            showCancelButton: true,
            confirmButtonText: 'Yes, Start',
            cancelButtonText: 'Cancel',
          }).then(async (result) => {
            if (result.isConfirmed) {
                // const uuid = randtoken.generate(16, "abcdefghijklnmopqrstuvwxyz");
                const uuid = uuidv4();
                console.log("Generated uuid:", uuid);
                window.location.href = `${process.env.REACT_APP_BINAH_WEB_URL}${uuid}_${localStorage.getItem("user_id")}`
            } else {
                navigate('/patient/share-medical')
            }
          });
    }

    return (
      <>
        <Header />
            <section className="poc-sec">
                <div className="container">
                    <div className="d-flex justify-content-between">
                        <div className="mb-4">
                        <p className="font_22 font_600 mb-0">AI Doctor 3</p>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/patient/home" className="font_14">
                                Home
                                </Link>
                            </li>
                            <li className="breadcrumb-item active font_14" aria-current="page">
                                Ai doctor 3
                            </li>
                            </ol>
                        </nav>
                        </div>
                        <div>
                            <button className="submit-btn" onClick={handleStartBinah}>Vital Signs</button>
                        </div>
                    </div>
                </div>
            </section>
        <Footer />
    </>
    )
}