import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
function App() {
  const navigate = useNavigate();

  const [highestBoxHeight, setHighestBoxHeight] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showModalPharmacy, setShowModalPharmacy] = useState(false);
  const [showModalTelemedicine, setShowModalTelemedicine] = useState(false);
  const [showModalDownload, setShowModalDownload] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const openModalPharmacy = () => {
    setShowModalPharmacy(true);
  };
  const closeModalPharmacy = () => {
    setShowModalPharmacy(false);
  };

  const openModalTelemedicine = () => {
    setShowModalTelemedicine(true);
  };
  const closeModalTelemedicine = () => {
    setShowModalTelemedicine(false);
  };

  const openModalDownload = () => {
    setShowModalDownload(true);
  };
  const closeModalDownload = () => {
    setShowModalDownload(false);
  };


  useEffect(() => {
    if (!localStorage.getItem("user_login_type_role", false)) {
      navigate('/');
    }
    const calculateHighestBoxHeight = () => {
      let highestBox = 0;
      document.querySelectorAll('.card-select--1 .text').forEach((element) => {
        if (element.clientHeight > highestBox) {
          highestBox = element.clientHeight;
        }
      });
      setHighestBoxHeight(highestBox);
    };

    calculateHighestBoxHeight();

    // Re-calculate on window resize, if needed
    window.addEventListener('resize', calculateHighestBoxHeight);

    return () => {
      // Remove event listener when component unmounts
      window.removeEventListener('resize', calculateHighestBoxHeight);
    };
  }, []);

  return (
    <>
    
    <Header />
    <main className='flex-column bg-white'>
    <section >
      
      {showModal && (
        <Modal show={openModal} onHide={closeModal} centered id="modal-emergency" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content modal-custom">
              <div className="modal-body pb-0">
                <p className="modal-msg text-blue">You have successfully dialed Emergency 112!</p>
              </div>
              <div className="modal-footer border-0">
                <button type="button" className="modal-button" onClick={closeModal}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {showModalPharmacy && (
        <Modal show={openModalPharmacy} onHide={closeModalPharmacy} centered id="modal-emergency" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content modal-custom">
              <div className="modal-body pb-0">
                <p className="text-blue font_20 font_500 text-center">Go to pharmacy</p>
                <a href="https://medplusnig.com/" className="link_join text-blue"><span
                  className="text-blue pe-2">Medplus-</span>https://medplusnig.com/</a>
                <a href="https://bydowpharmacy.com/" className="link_join text-blue"><span
                  className="text-blue pe-2">bydowpharmacy-</span>https://bydowpharmacy.com/</a>
              </div>
              <div className="modal-footer border-0">
                <button type="button" className="modal-button" onClick={closeModalPharmacy}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {showModalTelemedicine && (
        <Modal show={openModalTelemedicine} onHide={closeModalTelemedicine} centered id="modal-emergency" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content modal-custom">
              <div className="modal-body pb-0">

              </div>
              <div className="modal-footer border-0">
                <button type="button" className="modal-button" onClick={closeModalTelemedicine}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {showModalDownload && (
        <Modal show={openModalDownload} onHide={closeModalDownload} centered id="modal-emergency" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content modal-custom">
              <div className="modal-body pb-0">
                <p className="modal-msg text-blue">You don't have any details to download!</p>
              </div>
              <div className="modal-footer border-0">
                <button type="button" className="modal-button" onClick={closeModalDownload}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      <div className="home-sec pb-0 pt-0 bg-transparent">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-7 col-xl-6 col-md-12 col-12">
              <div className="banner-slider">
                <div className="card-draiva p-0">
                  <p className="mb-0 font_40 font_500 title text-blue text-end"> Doc. Aiva</p>
                  <div className="ai-img"> <img src={process.env.PUBLIC_URL + "/assets/images/ai.png"} className="mb-2" alt="" /></div>
                  <Link to="/patient/choose-options" className="start-btn d-flex justify-content-center text-uppercase">
                    Start
                  </Link>
                  <p className="text mb-0" data-bs-toggle="collapse">SILVA ● HEALTH ● MKT</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="subsec ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-xl-6 col-12">
              <div className="row g-2">
                <div className="col-lg-6 col-md-6 col-6">
                  <div className="card-select--2" data-bs-toggle="modal" onClick={openModal}>
                    <div className="icon-design">
                      <img src={process.env.PUBLIC_URL + "/assets/images/icon/Emergency-call-new.png"} alt="image" />
                    </div>
                    <p className="mb-0 font_22 text" style={{ height: highestBoxHeight }}> Dial 112 Emergency</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-6">
                  <div className="card-select--2" data-bs-toggle="modal" onClick={openModalPharmacy}>
                    <div className="icon-design ">
                      <img src={process.env.PUBLIC_URL + "/assets/images/icon/pharmacy-medicine.png"} alt="image" />
                    </div>
                    <p className="mb-0 font_22 text" style={{ height: highestBoxHeight }}>Pharmacy</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-6">
                  <Link to="/patient/doctor-list" className="card-select--2">
                    <div className="icon-design ">
                      <img src={process.env.PUBLIC_URL + "/assets/images/icon/online-telimedicine.png"} alt="image" />
                    </div>
                    <p className="mb-0 font_22 text" style={{ height: highestBoxHeight }}>Online telemedicine</p>
                  </Link>
                </div>
                <div className="col-lg-6 col-md-6 col-6">
                  <Link to="/patient/share-medical" className="card-select--2">
                    <div className="icon-design ">
                      <img src={process.env.PUBLIC_URL + "/assets/images/icon/share-medical-icon.png"} alt="image" />
                    </div>
                    <p className=" mb-0 font_22 text" style={{ height: highestBoxHeight }}>Share Medical Record</p>
                  </Link>
                </div>
                <div className="col-lg-12 col-md-12 col-12">
                  <Link to="/patient/doctor-appointment" className="card-select--2 d-flex justify-content-center">
                    <p className=" mb-0 font_22 text text-center">Make Appointment</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </main>
    <Footer />
    </>
    
  );
}

export default App;