import React from 'react'
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className=''>
    <footer className="bg-white bottom-set">
          
        <a id="button"></a>
        <div className="container">
          <div className="footer-sec d-flex justify-content-between">
            <p className="mb-0 text-center font_14">
              Copyright © {new Date().getFullYear()} {process.env.REACT_APP_NAME}. All rights reserved
            </p>
            <div className="links">
              <Link className="footer-link" target="_blank" to={localStorage.getItem("user_login_type_role") == 2 ? "/doctor/policy" : "/patient/policy"}>
                Privacy Policy
              </Link>
              <Link className="footer-link" target="_blank" to={localStorage.getItem("user_login_type_role") == 2 ? "/doctor/terms" : "/patient/terms"}> 
                Terms of Use
              </Link>
              <Link className="footer-link" target="_blank" to={localStorage.getItem("user_login_type_role") == 2 ? "/doctor/about-us" : "/patient/about-us"}>
                About Us
              </Link>
            </div>
          </div>
        </div>

    </footer>
    </div>
  )
}

export default Footer;