import React,{useState,useEffect,useRef} from 'react';
import { Link } from 'react-router-dom';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';

function CancerCommunity() {

    const [isChatVisible, setChatVisible] = useState(true);
    const chatElementRef = useRef(null);

    const handleAvatarGroupClick = () => {
        setChatVisible(true);
    };

    const handleUserChatRemoveClick = () => {
        setChatVisible(false);
    };

    useEffect(() => {
        // Scroll chat element to the bottom when chat becomes visible
        if (chatElementRef.current) {
            chatElementRef.current.scrollTop = chatElementRef.current.scrollHeight;
        }
    }, [isChatVisible]);

    return (
        <>
            <main className="bg-color">
                <Header/>
                <div className="chatbody">
                    <div className="chat-main">
                    <div className="sideNav1">
                        <li className="active">
                        <i className="fa-regular fa-comment-dots" />
                        </li>
                        <li className="d-none">
                        <i className="fa-solid fa-phone" />
                        </li>
                        <li>
                        <i className="fa-solid fa-gear" />
                        </li>
                        <li>
                        <i className="fa-solid fa-trash-can" />
                        </li>
                        <li>
                        <i className="fa-regular fa-star" />
                        </li>
                        <li className="d-none">
                        <i className="fa-solid fa-address-book" />
                        </li>
                    </div>
                    <div className="sideNav2">
                        <div className="SideNavhead">
                        <h2 className="community-title">
                            Welcome to the Cancer Support Room
                            <img
                            className="wellness-icon"
                            src={process.env.PUBLIC_URL + "/assets/images/cancer-icon.png"}
                            alt="icon"
                            />
                        </h2>
                        </div>
                        <div className="SearchInputHolder">
                        <i className="fa-solid fa-magnifying-glass" />
                        <input className="searchInput" placeholder="Search For Chat.." />
                        <hr />
                        </div>
                        <div className="avatar-group" onClick={handleAvatarGroupClick}>
                        <div className="user-avatar">
                            <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="image" />
                        </div>
                        <div className="flex-1">
                            <p className="avatar-name">David Johnson</p>
                            <p className="avatar-msg">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Earujdsajf djf df dfjdkj dlkjfl.kjl dlkjf lkjlkdjfm, sequi.
                            </p>
                        </div>
                        </div>
                        <div className="avatar-group" onClick={handleAvatarGroupClick}>
                        <div className="user-avatar">
                            <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="image" />
                        </div>
                        <div className="flex-1">
                            <p className="avatar-name">David Johnson</p>
                            <p className="avatar-msg">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Earujdsajf djf df dfjdkj dlkjfl.kjl dlkjf lkjlkdjfm, sequi.
                            </p>
                        </div>
                        </div>
                        <div className="avatar-group" onClick={handleAvatarGroupClick}>
                        <div className="user-avatar">
                            <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="image" />
                        </div>
                        <div className="flex-1">
                            <p className="avatar-name">David Johnson</p>
                            <p className="avatar-msg">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Earujdsajf djf df dfjdkj dlkjfl.kjl dlkjf lkjlkdjfm, sequi.
                            </p>
                        </div>
                        </div>
                        <div className="avatar-group" onClick={handleAvatarGroupClick}>
                        <div className="user-avatar">
                            <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="image" />
                        </div>
                        <div className="flex-1">
                            <p className="avatar-name">David Johnson</p>
                            <p className="avatar-msg">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Earujdsajf djf df dfjdkj dlkjfl.kjl dlkjf lkjlkdjfm, sequi.
                            </p>
                        </div>
                        </div>
                        <div className="avatar-group" onClick={handleAvatarGroupClick}>
                        <div className="user-avatar">
                            <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="image" />
                        </div>
                        <div className="flex-1">
                            <p className="avatar-name">David Johnson</p>
                            <p className="avatar-msg">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Earujdsajf djf df dfjdkj dlkjfl.kjl dlkjf lkjlkdjfm, sequi.
                            </p>
                        </div>
                        </div>
                        <div className="avatar-group" onClick={handleAvatarGroupClick}>
                        <div className="user-avatar">
                            <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="image" />
                        </div>
                        <div className="flex-1">
                            <p className="avatar-name">David Johnson</p>
                            <p className="avatar-msg">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Earujdsajf djf df dfjdkj dlkjfl.kjl dlkjf lkjlkdjfm, sequi.
                            </p>
                        </div>
                        </div>
                        <div className="avatar-group" onClick={handleAvatarGroupClick}>
                        <div className="user-avatar">
                            <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="image" />
                        </div>
                        <div className="flex-1">
                            <p className="avatar-name">David Johnson</p>
                            <p className="avatar-msg">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Earujdsajf djf df dfjdkj dlkjfl.kjl dlkjf lkjlkdjfm, sequi.
                            </p>
                        </div>
                        </div>
                        <div className="avatar-group" onClick={handleAvatarGroupClick}>
                        <div className="user-avatar">
                            <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="image" />
                        </div>
                        <div className="flex-1">
                            <p className="avatar-name">David Johnson</p>
                            <p className="avatar-msg">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Earujdsajf djf df dfjdkj dlkjfl.kjl dlkjf lkjlkdjfm, sequi.
                            </p>
                        </div>
                        </div>
                        <div className="avatar-group" onClick={handleAvatarGroupClick}>
                        <div className="user-avatar">
                            <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="image" />
                        </div>
                        <div className="flex-1">
                            <p className="avatar-name">David Johnson</p>
                            <p className="avatar-msg">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Earujdsajf djf df dfjdkj dlkjfl.kjl dlkjf lkjlkdjfm, sequi.
                            </p>
                        </div>
                        </div>
                    </div>
                    <section className={`Chat ${isChatVisible ? 'user-chat-show' : ''}`} ref={chatElementRef}>
                        <div className="ChatHead">
                        <div className="d-block d-lg-none">
                            <Link to="#" className=" user-chat-remove" onClick={handleUserChatRemoveClick}>
                            <i className="fa-solid fa-angle-left" />
                            </Link>
                        </div>
                        <li className="group w-100">
                            <p className="GroupName">MSH Cancer Support Community</p>
                            <Link to="#" className="avatar user-chat-remove" onClick={handleUserChatRemoveClick}>
                            <img src={process.env.PUBLIC_URL + "/assets/images/cancer-icon.png"} alt="image" />
                            </Link>
                        </li>
                        {/* <div className="callGroup">
                            <i className="fa-solid fa-phone"></i>
                            <i className="fa-solid fa-video"></i>

                        </div> */}
                        </div>
                        <div className="MessageContainer" id="chatElement">
                        <span />
                        {/* <div className="messageSeperator">Yesterday</div> */}
                        <div className="message me">
                            <p className="messageContent">Hello!</p>
                            <div className="messageDetails">
                            <div className="messageTime">3:21 PM</div>
                            <i className="fa-solid fa-check" />
                            </div>
                        </div>
                        <div className="message me">
                            <p className="messageContent">How are You!</p>
                            <div className="messageDetails">
                            <div className="messageTime">3:22 PM</div>
                            <i className="fa-solid fa-check" />
                            </div>
                        </div>
                        <div className="message you">
                            <p className="messageContent">I'm Fine!</p>
                            <div className="messageDetails">
                            <div className="messageTime">3:30 PM</div>
                            <i className="fa-solid fa-check" />
                            </div>
                        </div>
                        <div className="message you">
                            <p className="messageContent">How are You!</p>
                            <div className="messageDetails">
                            <div className="messageTime">3:32 PM</div>
                            <i className="fa-solid fa-check" />
                            </div>
                        </div>
                        <div className="message me">
                            <p className="messageContent">I'm also Fine!</p>
                            <div className="messageDetails">
                            <div className="messageTime">3:36 PM</div>
                            <i className="fa-solid fa-check" />
                            </div>
                        </div>
                        <div className="message me">
                            <p className="messageContent">Send Me the Pics!</p>
                            <div className="messageDetails">
                            <div className="messageTime">3:21 PM</div>
                            <i className="fa-solid fa-check" />
                            </div>
                        </div>
                        {/* <div className="messageSeperator">Today</div> */}
                        <div className="message you">
                            <p className="messageContent">Sorry for the Delay!</p>
                            <div className="messageDetails">
                            <div className="messageTime">8:09 AM</div>
                            <i className="fa-solid fa-check" />
                            </div>
                        </div>
                        <div className="message you">
                            <p className="messageContent">Here are Pics!</p>
                            <div className="messageDetails">
                            <div className="messageTime">3:21 AM</div>
                            <i className="fa-solid fa-check" />
                            </div>
                        </div>
                        </div>
                        <form id="MessageForm">
                        <div className="chat-input-group">
                            <input
                            type="text"
                            placeholder="Enter Message..."
                            id="MessageInput"
                            />
                            <span className="input-group-text p-0" id="basic-addon2">
                            <label htmlFor="formFile" className="form-label mb-0">
                                {" "}
                                <i className="fa-solid fa-paperclip font_18" />
                            </label>
                            <input
                                className="form-control d-none"
                                type="file"
                                id="formFile"
                            />
                            </span>
                        </div>
                        <button className="Send">
                            <i className="fa-solid fa-paper-plane" />
                        </button>
                        </form>
                    </section>
                    </div>
                </div>
                <Footer/>
            </main>
        </>
    )
}

export default CancerCommunity;
