import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';

export default function PointOfCare() {
    useEffect(() => {
        var highestBox = 0;
        document.querySelectorAll('.poc-card .title').forEach((title) => {
          if (title.clientHeight > highestBox) {
            highestBox = title.clientHeight;
          }
        });
        document.querySelectorAll('.poc-card .title').forEach((title) => {
          title.style.height = highestBox + 'px';
        });
      }, []);

    return (
      <>
            <Header />
        <section className="poc-sec">
      <div className="container">
        <div className="mb-4">
          <p className="font_22 font_600 mb-0">Point of care</p>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/patient/home" className="font_14">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active font_14" aria-current="page">
                Point of care
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-lg-3 col-6">
            <Link to="/patient/search-chat-gpt/13" className="poc-card d-block">
                <div className="poc-img carepoint-icon">
                <img src={process.env.PUBLIC_URL + "/assets/images/icon/starpilot.png"} alt="" />
                </div>
                <p className="title">Carepoint</p>
                <p className="text">
                    Click and enter prompt e.g. “ Please listen to my symptoms and provide a differential diagnosis to your best ability. 
                </p>
            </Link>
          </div>
          <div className="col-lg-3 col-6">
          <Link to="/patient/search-chat-gpt/1" className="font_14">
            <div className="poc-card">
              <div className="poc-img">
                <img src={process.env.PUBLIC_URL + "/assets/images/icon/indication-check.png"} alt="" />
              </div>
              <p className="title">Indications Checker</p>
              <p className="text">
                Click and enter prompt e.g. “Please check these drugs for indications”
              </p>
            </div>
            </Link>
          </div>
          <div className="col-lg-3 col-6">
          <Link to="/patient/search-chat-gpt/2" className="font_14">
            <div className="poc-card">
              <div className="poc-img">
                <img src={process.env.PUBLIC_URL + "/assets/images/icon/drug-interactions.png"} alt="" />
              </div>
              <p className="title">Drug Interactions & Revisions</p>
              <p className="text">
                Click and enter prompt e.g. “Please check these drugs for drug interactions and revise prescription to eliminate interactions”
              </p>
            </div>
            </Link>
          </div>
          <div className="col-lg-3 col-6">
          <Link to="/patient/search-chat-gpt/3" className="font_14">
            <div className="poc-card">
              <div className="poc-img">
                <img src={process.env.PUBLIC_URL + "/assets/images/icon/drug-allergy.png"} alt="" />
              </div>
              <p className="title">Drug Allergy</p>
              <p className="text">
                Click and enter prompt e.g. “Please check these drugs for drug-allergy interactions”
              </p>
            </div>
            </Link>
          </div>
          <div className="col-lg-3 col-6">
          <Link to="/patient/search-chat-gpt/4" className="font_14">
            <div className="poc-card">
              <div className="poc-img">
                <img src={process.env.PUBLIC_URL + "/assets/images/icon/dosing-medical.png"} alt="" />
              </div>
              <p className="title">Dosing & Medical Calculations</p>
              <p className="text">
                Click and enter prompt e.g. “Please check these drugs and calculate dosing or confirm dosing calculations”
              </p>
            </div>
            </Link>
          </div>
          <div className="col-lg-3 col-6">
          <Link to="/patient/search-chat-gpt/5" className="font_14">
            <div className="poc-card">
              <div className="poc-img">
                <img src={process.env.PUBLIC_URL + "/assets/images/icon/pill-identification.png"} alt="" />
              </div>
              <p className="title">Pill Identification Tool</p>
              <p className="text">
                Click and enter prompt e.g. “Please indicate how to identify the pills for the following drugs”
              </p>
            </div>
            </Link>
          </div>
          <div className="col-lg-3 col-6">
          <Link to="/patient/search-chat-gpt/6" className="font_14">
            <div className="poc-card">
              <div className="poc-img">
                <img src={process.env.PUBLIC_URL + "/assets/images/icon/video-call.svg"} alt="" />
              </div>
              <p className="title">Medical Videos & Articles</p>
              <p className="text">
                Click and enter prompt e.g. “Please assist me as requested for the following medical videos and articles”
              </p>
            </div>
            </Link>
          </div>
          <div className="col-lg-3 col-6">
          <Link to="/patient/search-chat-gpt/7" className="font_14">
            <div className="poc-card">
              <div className="poc-img">
                <img src={process.env.PUBLIC_URL + "/assets/images/icon/magnify.svg"} alt="" />
              </div>
              <p className="title">Differential Diagnosis Generator</p>
              <p className="text">
                Click and enter prompt e.g. “ Please listen to my symptoms and provide a differential diagnosis to your best ability.”
              </p>
            </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
    <Footer />
    </>
    )
}