import React,{ useState, useEffect } from 'react'
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import { prescription, checkPermissionCodeAccessbility, patientInformation, addPrescriptionMedicine, getMedicineList, getMedicineDetailsById, updatePrescriptionMedicine, removePrescriptionMedicine, allChemistList, updateAppoinmentStatus, prescriptionSendRx } from '../../../api/apiHandler';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from 'moment';
import Swal from 'sweetalert2';


function Prescription() {

    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showSummaryModal, setShowSummaryModal] = useState(false);
    const [showSendRxModal, setSendRxModal] = useState(false);
    const [gender, setGender] = useState(1);
    const [permissionCode,setPermissionCode] = useState("");
    const [handleCode,setHandleCode] = useState(false);
    const [patientData,setPatientData] = useState(null);
    const [prescriptionData,setPrescriptionData] = useState(null);
    // console.log("prescriptionData",prescriptionData);
    const [medicineData,setMedicineData] = useState([]);
    const [eachMedicineData,setEachMedicineData] = useState(null);
    console.log('eachMedicineData: ', eachMedicineData);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchDate, setSearchDate] = useState(null); 
    const location = useLocation();
    // console.log("location",location);
    const prescription_data = location.state;
    console.log('prescription_data: ', prescription_data);
    // console.log("prescription_data",prescription_data);
    const [prescriptionPatientData,setPrescriptionPatientData] = useState(prescription_data);
    // console.log("prescriptionPatientData",prescriptionPatientData);
    // console.log("prescription_id",prescriptionPatientData.prescription_id);
    const [chemistList, setChemistList] = useState([]);
    // console.log('chemistList: ', chemistList);
    const [chemistId, setChemistId] = useState("");
    


    function getYesterday() {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        return yesterday;
    }
    
    let yesterdayDate = getYesterday();
      
    useEffect(()=>{
          if(prescription_data.patient_id != 0){
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            patientInformation({"patient_id" : prescription_data != null ? prescription_data.patient_id : prescriptionPatientData.patient_id}).then((response) => {
                response = JSON.parse(response)
                console.log("response",response);
                if (response.code == process.env.REACT_APP_SUCCESS) {
                    setPatientData(response.data)
                } else {
                    setPatientData(null)
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                        })
                }
                Swal.close()
            })
            getChemistList()
        }
    },[])

    const getChemistList = () => {
        allChemistList().then((response) => {
            response = JSON.parse(response)
            console.log("response",response);
            if (response.code == process.env.REACT_APP_SUCCESS) {
                setChemistList(response.data)
            } else {
                setChemistList([]);
            }
        })
    }

    const handleChange = (event) => {
        if(event.target.name === "gender"){
            setGender(event.target.value)
        }
    }

    const validationSchema = Yup.object().shape({
        first_name: Yup.string()
            .required("Please enter first name")
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field')
            .max(128, "first name must be at most 128 character"),
        last_name: Yup.string()
            .required("Please enter last name")
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field')
            .max(128, "last name must be at most 128 character"),
        age: Yup.string()
            .required("Please enter age")
            .typeError("Please enter valid age")
            .matches(/^\d+$/, 'Age must contain only digits'),
        dob: Yup.date()
            .typeError('Please select Date of birth')
            .required('Please select Date of birth')
            .max(new Date(), 'Date of birth must be in the past'),
        gender: Yup.string()
            .typeError('Please select gender')
            .required('Please select gender'),
        prescription_date: Yup.date()
            .typeError('Please select prescription date')
            .required('Please select prescription date')
            .min(yesterdayDate, 'Prescription date not in the past'),
        permission_code: Yup.string()
            .required('Please enter permission code'),
        country_name: Yup.string()
            .required("Please enter country name")
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field'),
        state_name: Yup.string()
            .required("Please enter state name")
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field'),
        city_name: Yup.string()
            .required("Please enter city name")
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field'),
        address: Yup.string()
            .required("Please enter address"),
        zipCode: Yup.string()
            .required('Please enter zip code')
            .matches(/^\d{5}(-\d{4})?$/, 'Please enter valid zip code'),
        physician_first_name: Yup.string()
            .required("Please enter physician first name")
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field')
            .max(128, "Physician first name must be at most 128 character"),
        physician_last_name: Yup.string()
            .required("Please enter physician last name")
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field')
            .max(128, "Physician last name must be at most 128 character"),
        additional_direction: Yup.string()
            .required('Please enter additional direction'),
        // id_or_qr_code: Yup.string()
        //     .required('Please enter id'),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit1 = async (data) => {
        if(handleCode){
            var params = {
                "patient_id": prescription_data != null ? prescription_data.patient_id : prescriptionPatientData.patient_id,
                "meeting_id": prescription_data != null ? prescription_data.meeting_id : prescriptionPatientData.meeting_id,
                "appointment_id": prescription_data != null ? prescription_data.appointment_id : prescriptionPatientData.appointment_id,
                "prescription_date": moment(data.prescription_date).format('YYYY-MM-DD'),
                "first_name": data.first_name,
                "last_name": data.last_name,
                "age": data.age,
                "date_of_birth": moment(data.dob).format('YYYY-MM-DD'),
                "gender": data.gender,
                "county": data.country_name,
                "state": data.state_name,
                "city": data.city_name,
                "permission_code": data.permission_code,
                "address": data.address,
                "zipcode": data.zipCode,
                "physician_first_name": data.physician_first_name,
                "physician_last_name": data.physician_last_name,
                "additional_direction": data.additional_direction,
                // "id_qr_code": data.id_or_qr_code
            }
            console.log('params', params)
            prescription(params).then((response) => {
                response = JSON.parse(response)
                console.log("response",response);
                if (response.code == process.env.REACT_APP_SUCCESS) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setPrescriptionData(response.data)
                    // setTimeout(() => {
                    //     navigate("/")
                    // }, 2000)
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })
        } else {
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Please verify your permission code',
                toast: true,
                showConfirmButton: false,
                timer: 2000
            })
        }
    }

    const getPermissionCode = (e) => {
        setPermissionCode(e.target.value);
    }

    const handleCheck = () => {
        if(permissionCode != ""){
            const checkCodeObj = {
                "code" : permissionCode,
                "patient_id": prescription_data != null ? prescription_data.patient_id : prescriptionPatientData.patient_id,
                "type": 2
            }
            console.log("checkCodeObj",checkCodeObj);
            checkPermissionCodeAccessbility(checkCodeObj).then((response)=>{
                response = JSON.parse(response);
                if(response.code == process.env.REACT_APP_SUCCESS){
                    setHandleCode(true);
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                }       
            })
        }
    }

    const validationSchema2 = Yup.object().shape({
        medication_name: Yup.string()
            .required('Please enter medication name'),
        form: Yup.string()
            .required('Please enter form'), 
        strength: Yup.string()
            .required("Please enter strength")
            .typeError("Please enter valid strength")
            .matches(/^\d+$/, 'Strength must contain only digits'),
        units: Yup.string()
            .required('Please enter units')
            .matches(/^[a-zA-Z\s]*$/, 'Please enter valid units'),
        dose: Yup.string()
            .required("Please enter dose")
            .typeError("Please enter valid dose")
            .matches(/^\d+$/, 'Dose must contain only digits'),
        tabs_or_caps_or_puffs: Yup.string()
            .required("Please select tabs or caps or puffs"), 
        intakesorapplications: Yup.string()
            .required("Please enter intakes or applications")
            .typeError("Please enter valid intakes or applications")
            .matches(/^\d+$/, 'Intakes or applications must contain only digits'),
        route: Yup.string()
            .required('Please select route'),
        frequency: Yup.string()
            .required('Please select frequency'),
        refills: Yup.string()
            .required('Please select refills'),
        quantity: Yup.string()
            .required("Please enter quantity")
            .typeError("Please enter valid quantity")
            .matches(/^\d+$/, 'Quantity must contain only digits'), 
        comments: Yup.string()
            .required('Please enter comments'),
    });

    const formOptions2 = { resolver: yupResolver(validationSchema2) };
    const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 } } = useForm(formOptions2);

    const onSubmit2 = async (data) => {
        console.log("data",data);
        if(prescriptionData != null){
            var params = {
                "prescription_id": prescriptionData.id,
                "patient_id": prescription_data != null ? prescription_data.patient_id : prescriptionPatientData.patient_id,               
                "medicines_data": [
                    {
                        "medication_name": data.medication_name,
                        "form": data.form,
                        "strength": data.strength,
                        "strength_type": data.units,
                        "dose": data.dose,
                        "dose_type": data.tabs_or_caps_or_puffs,
                        "intake": data.intakesorapplications,
                        "route": data.route,
                        "frequency": data.frequency,
                        "refills": data.refills,
                        "quantity" : data.quantity,
                        "comments": data.comments
                    } 
                ]
            }
            console.log('params', params)
            addPrescriptionMedicine(params).then((response) => {
                response = JSON.parse(response)
                console.log("response",response);
                if (response.code == process.env.REACT_APP_SUCCESS) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })
        } else {
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Please add a prescription before submitting',
                toast: true,
                showConfirmButton: false,
                timer: 2000
            })
        }
    }

    const validationSchema3 = Yup.object().shape({
        medication_name: Yup.string()
            .required('Please enter medication name'),
        form: Yup.string()
            .required('Please enter form'), 
        strength: Yup.string()
            .required("Please enter strength")
            .typeError("Please enter valid strength")
            .matches(/^\d+$/, 'Strength must contain only digits'), 
        units: Yup.string()
            .required('Please enter units')
            .matches(/^[a-zA-Z\s]*$/, 'Please enter valid units'),
        dose: Yup.string()
            .required("Please enter dose")
            .typeError("Please enter valid dose")
            .matches(/^\d+$/, 'Dose must contain only digits'),
        tabs_or_caps_or_puffs: Yup.string()
            .required("Please select tabs or caps or puffs"),
        intakesorapplications: Yup.string()
            .required("Please enter intakes or applications")
            .typeError("Please enter valid intakes or applications")
            .matches(/^\d+$/, 'Intakes or applications must contain only digits'),
        route: Yup.string()
            .required('Please select route'),
        frequency: Yup.string()
            .required('Please select frequency'),
        refills: Yup.string()
            .required('Please select refills'),
        quantity: Yup.string()
            .required("Please enter quantity")
            .typeError("Please enter valid quantity")
            .matches(/^\d+$/, 'Quantity must contain only digits'), 
        comments: Yup.string()
            .required('Please enter comments'),
    });

    const formOptions3 = { resolver: yupResolver(validationSchema3) };
    const { register: register3, handleSubmit: handleSubmit3, formState: { errors: errors3 } } = useForm(formOptions3);

    const onSubmit3 = async (data) => {
        console.log("data",data);
        if(prescriptionData != null){
            var params = {
                "prescription_id": prescriptionData.id,
                "patient_id": prescription_data != null ? prescription_data.patient_id : prescriptionData.patient_id,
                "medicines_data": [
                    {
                        "medication_name": data.medication_name,
                        "form": data.form,
                        "strength": data.strength,
                        "strength_type": data.units,
                        "dose": data.dose,
                        "dose_type": data.tabs_or_caps_or_puffs,
                        "intake": data.intakesorapplications,
                        "route": data.route,
                        "frequency": data.frequency,
                        "refills": data.refills,
                        "quantity" : data.quantity,
                        "comments": data.comments,
                    } 
                ]
            }
            console.log('params', params)
            addPrescriptionMedicine(params).then((response) => {
                response = JSON.parse(response)
                console.log("response",response);
                if (response.code == process.env.REACT_APP_SUCCESS) {
                    closeModal();
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })
        } else {
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Please add a prescription before submitting',
                toast: true,
                showConfirmButton: false,
                timer: 2000
            })
        }
    }

    const openModal = () => {
        setShowModal(true)
    }

    const closeModal = () => {
        setShowModal(false)
    }

    const openSummaryModal = () => {
        setShowSummaryModal(true)
    }

    const closeSummaryModal = () => {
        setShowSummaryModal(false)
    }

    const getMedicineListing = () => {
        if(prescriptionData != null){
            getMedicineList({"prescription_id" : prescriptionData.id, "search_text": searchQuery, "search_date":searchDate}).then((response) => {
                response = JSON.parse(response)
                console.log("response",response);
                setSearchDate(null)
                openSummaryModal()
                if (response.code == process.env.REACT_APP_SUCCESS) {
                    setMedicineData(response.data)
                } else {
                    setMedicineData([])
                    Swal.fire({
                        position: 'top-end',
                        icon: 'info',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })
        } else if (prescriptionPatientData.prescription_id != undefined){
            console.log("else if part");
            getMedicineList({"prescription_id" : prescriptionPatientData.prescription_id, "search_text": searchQuery, "search_date":searchDate}).then((response) => {
                response = JSON.parse(response)
                console.log("response",response);
                setSearchDate(null)
                openSummaryModal()
                if (response.code == process.env.REACT_APP_SUCCESS) {
                    setMedicineData(response.data)
                } else {
                    setMedicineData([])
                    Swal.fire({
                        position: 'top-end',
                        icon: 'info',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })
        } else {
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Please add a prescription before you check summary',
                toast: true,
                showConfirmButton: false,
                timer: 2000
            })
        }
    }

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
    };

    const handleDate = (event) => {
        const selectedDate = event.target.value;
        setSearchDate(selectedDate)
    }

    useEffect(()=>{
        if(prescriptionData != null){
            getMedicineListing();
        } else if (prescriptionPatientData.prescription_id != undefined){
            getMedicineListing();
        }
    },[searchQuery,searchDate])

    const openEditModal = () => {
        setShowEditModal(true)
    }

    const closeEditModal = () => {
        setEachMedicineData(null)   
        setShowEditModal(false)
        resetForm()
    }

    const handleEditMedicine = (medicine_id) => {
        closeSummaryModal()
        getMedicineDetailsById({"medicine_id" : medicine_id}).then((response) => {
            response = JSON.parse(response)
            console.log("response",response);
            if (response.code == process.env.REACT_APP_SUCCESS) {
                setEachMedicineData(response.data)
                openEditModal()
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    const validationSchema4 = Yup.object().shape({
        medication_name: Yup.string()
            .required('Please enter medication name'),
        form: Yup.string()
            .required('Please enter form'), 
        strength: Yup.string()
            .required("Please enter strength")
            .typeError("Please enter valid strength")
            .matches(/^\d+$/, 'Strength must contain only digits'), 
        units: Yup.string()
            .required('Please enter units')
            .matches(/^[a-zA-Z\s]*$/, 'Please enter valid units'),
        dose: Yup.string()
            .required("Please enter dose")
            .typeError("Please enter valid dose")
            .matches(/^\d+$/, 'Dose must contain only digits'),
        tabs_or_caps_or_puffs: Yup.string()
            .required("Please select tabs or caps or puffs"),
        intakesorapplications: Yup.string()
            .required("Please enter intakes or applications")
            .typeError("Please enter valid intakes or applications")
            .matches(/^\d+$/, 'Intakes or applications must contain only digits'),
        route: Yup.string()
            .required('Please select route'),
        frequency: Yup.string()
            .required('Please select frequency'),
        refills: Yup.string()
            .required('Please select refills'),
        quantity: Yup.string()
            .required("Please enter quantity")
            .typeError("Please enter valid quantity")
            .matches(/^\d+$/, 'Quantity must contain only digits'), 
        comments: Yup.string()
            .required('Please enter comments'),
    });

    const formOptions4 = { resolver: yupResolver(validationSchema4) };
    const { register: register4, handleSubmit: handleSubmit4, reset: resetForm, formState: { errors: errors4 } } = useForm(formOptions4);

    const onSubmit4 = async (data) => {
        console.log("data",data);
        var params = {
            "medicine_id": eachMedicineData.id,
            "medication_name": data.medication_name,
            "form": data.form,
            "strength": data.strength,
            "strength_type": data.units,
            "dose": data.dose,
            "dose_type": data.tabs_or_caps_or_puffs,
            "intake": data.intakesorapplications,
            "route": data.route,
            "frequency": data.frequency,
            "refills": data.refills,
            "quantity" : data.quantity,
            "comments": data.comments,
        } 
        console.log('params', params)
        updatePrescriptionMedicine(params).then((response) => {
            response = JSON.parse(response)
            console.log("response",response);
            if (response.code == process.env.REACT_APP_SUCCESS) {
                closeEditModal();
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    const handleUpdateSafetyQcChecked = () => {
        if(prescriptionData != null){
        const prescriptionObj = {
            "prescription_id" : prescriptionData.id,
            "patient_id" : prescription_data != null ? prescription_data.patient_id : prescriptionData.patient_id,
            "appointment_id" : prescription_data != null ? prescription_data.appointment_id : prescriptionData.appointment_id,
        }
        navigate('/doctor/point-of-care',{ state : prescriptionObj })
        } else {
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Please add a prescription before you check safety qc check',
                toast: true,
                showConfirmButton: false,
                timer: 2000
            })
        }
    }

    const openSendRxModal = () => {
        setSendRxModal(true)
    }

    const closeSendRxModal = () => {
        setSendRxModal(false)
    }

    const handleChemistList = (e) => {

        if(e.target.checked){
            setChemistId(parseInt(e.target.value))
        } else {
            setChemistId(parseInt(""))
        }

    }

    const handleSendRx = () => {
        console.log("enter",chemistId);
        if(prescriptionData != null){

            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading();
                }
            });
            const sendRxObj = {
                "prescription_id":prescriptionData.id,
                "patient_id":prescriptionData.patient_id,
            }
            
            if(chemistId != ""){
                sendRxObj.chemist_id = chemistId
            }
            
            console.log('sendRxObj: ', sendRxObj);
            prescriptionSendRx(sendRxObj).then((response) => {
                response = JSON.parse(response)
                Swal.close();
                if (response.code == process.env.REACT_APP_SUCCESS) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                    handleMeetingStatus()
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })

            // const sendRxObj = {
            //     "prescription_id" : prescriptionData.id,
            //     "patient_id" : prescriptionData.patient_id,
            //     "appointment_id" : prescriptionData.appointment_id
            // }
            // console.log("sendRxObj",sendRxObj);
            // navigate('/doctor/chemist-list',{ state : sendRxObj })
        } else {
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Please add a prescription before send rx',
                toast: true,
                showConfirmButton: false,
                timer: 2000
            })
        }
    }

    const handleMeetingStatus = () => {

        const updateStatusObj = {
            "appointment_id" : prescriptionData.appointment_id,
            "appointment_status" : process.env.REACT_APP_MEETING_DONE
        }
    
        updateAppoinmentStatus(updateStatusObj).then(async(response) => {
            response = JSON.parse(response)
            if (response.code == process.env.REACT_APP_SUCCESS) {
              setTimeout(()=>{
                closeSendRxModal()
                navigate('/doctor/home');
              },500)
            } else {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: response.message,
                toast: true,
                showConfirmButton: false,
                timer: 2000
              })
            }
        })
    }

    const handleRemoveMedicine = (medicine_id) => {
        closeSummaryModal()
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        removePrescriptionMedicine({"medicine_id" : medicine_id}).then((response) => {
            response = JSON.parse(response)
            console.log("response",response);
            Swal.close()
            if (response.code == process.env.REACT_APP_SUCCESS) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                getMedicineListing()
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    if (patientData == null && prescription_data.patient_id != 0) return <div className="container"><div className="mt-5"></div></div>

  return (
    <>
    <main className="bg-color">
        <Header/>
        <section className="share-medical">
        <div className="container">
            <div>
            <p className="font_22 font_600 mb-0">Prescription</p>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <Link to="/" className="font_14">
                    Home
                    </Link>
                </li>
                <li
                    className="breadcrumb-item active font_14"
                    aria-current="page"
                >
                    Prescription
                </li>
                </ol>
            </nav>
            </div>
            <div className="row">
            <div className="col-lg-12">
                <div className="card-profile feedback-sec">
                <p className="font_22  font_600  mb-0">Prescription details</p>
                <hr />
                <form onSubmit={handleSubmit(onSubmit1)}>
                <div className="row">
                    <div className="col-lg-6">
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Prescription Date
                        </label>
                        <input
                        type="date"
                        {...register('prescription_date')}
                        className="form-control"
                        id="exampleFormControlInput1"
                        />
                         <div className="invalid-feedback">{errors.prescription_date?.message}</div>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Permission Code
                        </label>
                        <div className="input-group p-0">
                        <input
                            type="text"
                            {...register('permission_code')}
                            className="form-control"
                            placeholder="Enter permission code"
                            onChange={getPermissionCode}
                        />
                        <button
                            className="btn check-btn btn-outline-secondary font_500"
                            type="button"
                            id="button-addon2"
                            onClick={handleCheck}
                        >
                            Check
                        </button>
                        </div>
                        <div className="invalid-feedback">{errors.permission_code?.message}</div>
                    </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="mb-3">
                            <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                            >
                            Additional Directions
                            </label>
                            <input
                            type="text"
                            {...register('additional_direction')}
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Additional Directions"
                            />
                            <div className="invalid-feedback">{errors.additional_direction?.message}</div>
                        </div>
                    </div>
                    {/* <div className="col-lg-6">
                    <div className="mb-3 ">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        ID/QR Code:
                        </label>
                        <div className="input-group flex-1">
                        <input
                            type="text"
                            {...register('id_or_qr_code')}
                            className="form-control"
                            placeholder="Enter ID"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                        />
                        <span
                            className="input-group-text p-1 bg-lightblue"
                            id="basic-addon2"
                        >
                            <img
                            className="scan-icon"
                            src={process.env.PUBLIC_URL + "/assets/images/icon/scan-icon.png"}  
                            alt="image"
                            />
                        </span>
                        <div className="invalid-feedback">{errors.id_or_qr_code?.message}</div>
                        </div>
                    </div>
                    </div> */}
                </div>
                    <p className="font_22  font_600  mb-0">Patient Information</p>
                    <hr />
                    <div className="row">
                    <div className="col-lg-6 ">
                        <div className="mb-3">
                        <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                        >
                            First Name
                        </label>
                        <input
                            type="text"
                            {...register('first_name')}
                            className="form-control"
                            id="exampleFormControlInput1"
                            defaultValue={patientData && patientData.first_name}
                            placeholder="Enter First Name"
                        />
                        <div className="invalid-feedback">{errors.first_name?.message}</div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="mb-3">
                        <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                        >
                            Last Name
                        </label>
                        <input
                            type="text"
                            {...register('last_name')}
                            className="form-control"
                            id="exampleFormControlInput1"
                            defaultValue={patientData && patientData.last_name}
                            placeholder="Enter Last Name"
                        />
                        <div className="invalid-feedback">{errors.last_name?.message}</div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-6 col-12">
                        <div className="mb-3">
                        <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                        >
                            Age
                        </label>
                        <input
                            type="text"
                            {...register('age')}
                            className="form-control"
                            id="exampleFormControlInput1"
                            defaultValue={patientData && patientData.age}
                            placeholder="Enter Age"
                        />
                        <div className="invalid-feedback">{errors.age?.message}</div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-6 col-12">
                        <div className="mb-3">
                        <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                        >
                            Date of birth
                        </label>
                        <input
                            type="date"
                            {...register('dob')}
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="+1 923 782 4575"
                        />
                        <div className="invalid-feedback">{errors.dob?.message}</div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-6 col-12">
                        <div className="mb-3">
                        <label className="form-label">Gender</label>
                        <div className="d-flex gap-3 align-items-center">
                            <div className="form-check form-custom">
                                <input
                                className="form-check-input radio"
                                type="radio"
                                name="gender"
                                value="1"
                                id="gendermale"
                                {...register('gender')}
                                checked={gender == 1}
                                onChange={handleChange}
                                />
                            <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault1"
                            >
                                Male
                            </label>
                            </div>
                            <div className="form-check form-custom">
                            <input
                            className="form-check-input radio"
                            type="radio"
                            name="gender"
                            value="2"
                            id="genderfemale"
                            {...register('gender')}
                            checked={gender == 2}
                            onChange={handleChange}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault2"
                            >
                                Female
                            </label>
                            </div>
                        </div>
                        <div className="invalid-feedback">{errors.gender?.message}</div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-6 col-12">
                        <div className="mb-3">
                        <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                        >
                            Address
                        </label>
                        <input
                            type="text"
                            {...register('address')}
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Address"
                            defaultValue={patientData && patientData.address}
                        />
                        <div className="invalid-feedback">{errors.address?.message}</div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-xl-3 col-12">
                        <div className="mb-3">
                        <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                        >
                            City
                        </label>
                        <input
                            type="text"
                            {...register('city_name')}
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="City"
                            defaultValue={patientData && patientData.city}
                        />
                        <div className="invalid-feedback">{errors.city_name?.message}</div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-xl-3 col-12">
                        <div className="mb-3">
                        <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                        >
                            Zip Code
                        </label>
                        <input
                            type="text"
                            {...register('zipCode')}
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Zip Code"
                        />
                        <div className="invalid-feedback">{errors.zipCode?.message}</div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-xl-3 col-12">
                        <div className="mb-3">
                        <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                        >
                            State
                        </label>
                        <input
                            type="text"
                            {...register('state_name')}
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="State"
                            defaultValue={patientData && patientData.state}
                        />
                        <div className="invalid-feedback">{errors.state_name?.message}</div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-xl-3 col-12">
                        <div className="mb-3">
                        <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                        >
                            Country
                        </label>
                        <input
                            type="text"
                            {...register('country_name')}
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Country"
                            defaultValue={patientData && patientData.country}
                        />
                        <div className="invalid-feedback">{errors.country_name?.message}</div>
                        </div>
                    </div>
                    <div className="col-lg-6 ">
                        <div className="mb-3">
                        <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                        >
                            Physician first name
                        </label>
                        <input
                            type="text"
                            {...register('physician_first_name')}
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Physician First Name"
                        />
                        <div className="invalid-feedback">{errors.physician_first_name?.message}</div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="mb-3">
                        <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                        >
                            Physician last name
                        </label>
                        <input
                            type="text"
                            {...register('physician_last_name')}
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Physician Last Name"
                        />
                        <div className="invalid-feedback">{errors.physician_last_name?.message}</div>
                        </div>
                    </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6">
                        {/* <a href="" className="submit-btn mt-3">Update</a> */}
                        <button type="submit" className="submit-btn mt-3 w-100">Update</button>
                        </div>
                    </div>
                </form>
                </div>
            </div>
            </div>
            <div className="row">
            <div className="col-lg-12">
                    <div className="card-profile feedback-sec">
                    <p className="font_22 font_600 mb-0 text-blue">
                        Enter Prescription Here
                    </p>
                    <hr />
                    <p className="font_20 font_600 mb-3 text-blue">
                        Rx: Medication/Strength/Frequency
                    </p>
                    <form onSubmit={handleSubmit2(onSubmit2)}>
                    <div className="row">
                        <div className="col-lg-6 ">
                        <div className="mb-3">
                            <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                            >
                            Medication Name
                            </label>
                            <input
                            type="text"
                            {...register2('medication_name')}
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter e.g., Aspirin"
                            />
                            <div className="invalid-feedback">{errors2.medication_name?.message}</div>
                        </div>
                        </div>
                        <div className="col-lg-6 ">
                        <div className="mb-3">
                            <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                            >
                            Form
                            </label>
                            <input
                            type="text"
                            {...register2('form')}
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter e.g., Tablet"
                            />
                            <div className="invalid-feedback">{errors2.form?.message}</div>
                        </div>
                        </div>
                        <div className="col-lg-6 ">
                        <div className="mb-3">
                            <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                            >
                            Strength
                            </label>
                            {/* <div className="input-group">
                            <div> */}
                            <input
                                type="text"
                                {...register2('strength')}
                                className="form-control"
                                placeholder="Enter Strength e.g.,81"
                            />
                            <div className="invalid-feedback">{errors2.strength?.message}</div>
                            {/* </div>
                            <div className="input-group-append flex-1">
                                <select
                                className="form-select font_500"
                                id="inputGroupSelect04"
                                aria-label="Example select with button addon"
                                {...register2('milligrams')}
                                >
                                <option value="">Select Strength</option>
                                <option value="milligrams">Milligrams</option>
                                <option value="micrograms">Micrograms</option>
                                <option value="percent">Percent</option>
                                </select>
                            <div className="invalid-feedback">{errors2.milligrams?.message}</div>
                            </div>
                            </div> */}
                        </div>
                        </div>
                        <div className="col-lg-6 ">
                        <div className="mb-3">
                            <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                            >
                            Units
                            </label>
                            <input
                            type="text"
                            {...register2('units')}
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Units e.g., Milligrams"
                            />
                            <div className="invalid-feedback">{errors2.units?.message}</div>
                        </div>
                        </div>
                        <div className="col-lg-6 ">
                        <div className="mb-3">
                            <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                            >
                            Dose
                            </label>
                            <div className="input-group">
                            <div>
                            <input
                                type="text"
                                {...register2('dose')}
                                className="form-control"
                                placeholder="Enter Dose e.g.,2"
                            />
                            <div className="invalid-feedback">{errors2.dose?.message}</div>
                            </div>
                            <div className="input-group-append flex-1">
                                <select
                                className="form-select font_500"
                                id="inputGroupSelect04"
                                aria-label="Example select with button addon"
                                {...register2('tabs_or_caps_or_puffs')}
                                >
                                <option value="">Tabs or Caps or Puffs</option>
                                <option value="tabs">Tabs</option>
                                <option value="caps">Caps</option>
                                <option value="puffs">Puffs</option>
                                </select>
                            <div className="invalid-feedback">{errors2.tabs_or_caps_or_puffs?.message}</div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-6 ">
                        <div className="mb-3">
                            <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                            >
                            Intakes or Applications
                            </label>
                            <input
                            type="text"
                            {...register2('intakesorapplications')}
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Number e.g.,2"
                            />
                            <div className="invalid-feedback">{errors2.intakesorapplications?.message}</div>
                        </div>
                        </div>
                        <div className="col-lg-6 ">
                        <div className="mb-3">
                            <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                            >
                            Route
                            </label>
                            <select
                            className="form-select"
                            aria-label="Default select example"
                            name="route"
                            {...register2('route')}
                            >
                            <option value="">Select Route</option>
                            <option value="oral">Oral</option>
                            <option value="topical">Topical</option>
                            <option value="rectal">Rectal</option>
                            <option value="Inhalation">Inhalation</option>
                            <option value="sublingual_and_buccal">Sublingual And Buccal</option>
                            <option value="vaginal">Vaginal</option>
                            <option value="ocular">Ocular</option>
                            <option value="otic">Otic</option>
                            <option value="nasal">Nasal</option>
                            <option value="nebulization">Nebulization</option>
                            <option value="cutaneous">Cutaneous</option>
                            <option value="transdermal">Transdermal</option>
                            </select>
                            <div className="invalid-feedback">{errors2.route?.message}</div>
                        </div>
                        </div>
                        <div className="col-lg-6 ">
                        <div className="mb-3">
                            <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                            >
                            Frequency
                            </label>
                            <select
                            className="form-select"
                            aria-label="Default select example"
                            name="frequency"
                            {...register2('frequency')}
                            >
                            <option value="">Select Frequency</option>
                            <option value="Daily">Daily</option>
                            <option value="BID">BID</option>
                            <option value="TID">TID</option>
                            <option value="QID">QID</option>
                            </select>
                            <div className="invalid-feedback">{errors2.frequency?.message}</div>
                        </div>
                        </div>
                        <div className="col-lg-6">
                        <div className="mb-3">
                            <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                            >
                            Refills
                            </label>
                            <select
                            className="form-select"
                            aria-label="Default select example"
                            name="refills"
                            {...register2('refills')}
                            >
                            <option value="">Select Refills</option>
                            <option value="none">None</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            </select>
                            <div className="invalid-feedback">{errors2.refills?.message}</div>
                        </div>
                        </div>
                        <div className="col-lg-6">
                        <div className="mb-3">
                            <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                            >
                            Quantity
                            </label>
                            <input
                            type="text"
                            {...register2('quantity')}
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Quantity e.g., 36"
                            />
                            <div className="invalid-feedback">{errors2.quantity?.message}</div>
                        </div>
                        </div>
                        <div className="col-lg-6">
                        <div className="mb-3">
                            <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                            >
                            Comments
                            </label>
                            <input
                            type="text"
                            {...register2('comments')}
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Comments"
                            />
                            <div className="invalid-feedback">{errors2.comments?.message}</div>
                        </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <button type="submit" className="submit-btn mt-3 w-100">Submit</button>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                        <Link
                            to="#"
                            className="connect-btn mt-3 w-100"
                            // data-bs-toggle="modal"
                            // data-bs-target="#staticBackdrop1"
                            onClick={openModal}
                        >
                            Rx: Add More Medicines
                        </Link>
                        </div>
                        {/* <div className="col-12">
                        <button
                            data-bs-toggle="collapse"
                            href="#collapseExample"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                            className="connect-btn  mt-3 w-100"
                        >
                            {" "}
                            <i className="fa-solid fa-pen-to-square" /> Edit Rx Page
                            <Link></Link>
                        </button>
                        </div> */}
                        {/* <Link></Link>
                        <div className="col-12">
                        <Link></Link>
                        <div className="collapse" id="collapseExample">
                            <Link></Link>
                            <nav aria-label="Page navigation example">
                            <Link></Link>
                            <ul className="pagination rx-pagination justify-content-center pt-3">
                                <Link></Link>
                                <li className="page-item">
                                <Link></Link>
                                <Link
                                    className="page-link"
                                    to="#"
                                    aria-label="Previous"
                                >
                                    <span aria-hidden="true">«</span>
                                </Link>
                                </li>
                                <li
                                className="page-item"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Aspirin"
                                >
                                <Link
                                    className="page-link"
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdrop1"
                                    to="#"
                                >
                                    1
                                </Link>
                                </li>
                                <li
                                className="page-item"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Paracetamol"
                                >
                                <Link
                                    className="page-link"
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdrop2"
                                    to="#"
                                >
                                    2
                                </Link>
                                </li>
                                <li
                                className="page-item"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Ventolyn"
                                >
                                <Link
                                    className="page-link"
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdrop3"
                                    to="#"
                                >
                                    3
                                </Link>
                                </li>
                                <li className="page-item">
                                <Link
                                    className="page-link"
                                    to="#"
                                    aria-label="Next"
                                >
                                    <span aria-hidden="true">»</span>
                                </Link>
                                </li>
                            </ul>
                            </nav>
                        </div>
                        </div> */}
                        <div className="col-12 col-md-12 col-lg-12">
                        <Link
                            to="#"
                            // data-bs-toggle="modal"
                            // data-bs-target="#staticBackdrop4"
                            className="submit-btn mt-3 w-100"
                            onClick={getMedicineListing}
                        >
                            Rx Summary
                        </Link>
                        </div>
                    </div>
                    </form>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 col-lg-4">
                        <button
                            className="submit-btn secondary-btn mt-3 w-100"
                            onClick={handleUpdateSafetyQcChecked}
                        >
                            Safety/QC Check
                        </button>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                        <button
                            className="submit-btn mt-3"
                            onClick={openSendRxModal}
                        >
                            Send Rx
                        </button>
                        </div>
                    </div>
                    </div>
            </div>
            </div>
        </div>
        </section>
        <Footer />

        {showModal &&
        (<>
        <div
            className={`modal fade ${showModal ? 'show' : ''}`}
            id="staticBackdrop1"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
            tabIndex="-1"
            style={{ display: showModal ? 'block' : 'none' }}
        >
            <div className="modal-dialog prescription-modal modal-dialog-centered modal-xl">
            <form onSubmit={handleSubmit3(onSubmit3)}>
                <div className="modal-content">
                    <div className="modal-header border-0">
                    {/* <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5> */}
                    <p className="rxpageno">Rx Page 1</p>
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={closeModal}
                    />
                    </div>
                    <div className="modal-body">
                    <div className="row">
                    <div className="col-lg-6 ">
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Medication Name
                        </label>
                        <input
                        type="text"
                        {...register3('medication_name')}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter e.g., Aspirin"
                        />
                        <div className="invalid-feedback">{errors3.medication_name?.message}</div>
                    </div>
                    </div>
                    <div className="col-lg-6 ">
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Form
                        </label>
                        <input
                        type="text"
                        {...register3('form')}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter e.g., Tablet"
                        />
                        <div className="invalid-feedback">{errors3.form?.message}</div>
                    </div>
                    </div>
                    <div className="col-lg-6 ">
                        <div className="mb-3">
                            <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                            >
                            Strength
                            </label>
                            {/* <div className="input-group">
                            <div> */}
                            <input
                                type="text"
                                {...register3('strength')}
                                className="form-control"
                                placeholder="Enter Strength e.g.,81"
                            />
                            <div className="invalid-feedback">{errors3.strength?.message}</div>
                            {/* </div>
                            <div className="input-group-append flex-1">
                                <select
                                className="form-select font_500"
                                id="inputGroupSelect04"
                                aria-label="Example select with button addon"
                                {...register2('milligrams')}
                                >
                                <option value="">Select Strength</option>
                                <option value="milligrams">Milligrams</option>
                                <option value="micrograms">Micrograms</option>
                                <option value="percent">Percent</option>
                                </select>
                            <div className="invalid-feedback">{errors2.milligrams?.message}</div>
                            </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-6 ">
                        <div className="mb-3">
                            <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                            >
                            Units
                            </label>
                            <input
                            type="text"
                            {...register3('units')}
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Units e.g., Milligrams"
                            />
                            <div className="invalid-feedback">{errors3.units?.message}</div>
                        </div>
                    </div>
                    <div className="col-lg-6 ">
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Dose
                        </label>
                        <div className="input-group">
                        <div>

                        <input
                            type="text"
                            {...register3('dose')}
                            className="form-control"
                            placeholder="Enter Dose e.g.,2"
                        />
                        <div className="invalid-feedback">{errors3.dose?.message}</div>
                        </div>
                        <div className="input-group-append flex-1">
                            <select
                            className="form-select font_500"
                            id="inputGroupSelect04"
                            aria-label="Example select with button addon"
                            {...register3('tabs_or_caps_or_puffs')}
                            >
                            <option value="">Tabs or Caps or Puffs</option>
                            <option value="tabs">Tabs</option>
                            <option value="caps">Caps</option>
                            <option value="puffs">Puffs</option>
                            </select>
                        <div className="invalid-feedback">{errors3.tabs_or_caps_or_puffs?.message}</div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 ">
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Intakes or Applications
                        </label>
                        <input
                        type="text"
                        {...register3('intakesorapplications')}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter Number e.g.,2"
                        />
                        <div className="invalid-feedback">{errors3.intakesorapplications?.message}</div>
                    </div>
                    </div>
                    <div className="col-lg-6 ">
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Route
                        </label>
                        <select
                        className="form-select"
                        aria-label="Default select example"
                        name="route"
                        {...register3('route')}
                        >
                        <option value="">Select Route</option>
                        <option value="oral">Oral</option>
                        <option value="topical">Topical</option>
                        <option value="rectal">Rectal</option>
                        <option value="Inhalation">Inhalation</option>
                        <option value="sublingual_and_buccal">Sublingual And Buccal</option>
                        <option value="vaginal">Vaginal</option>
                        <option value="ocular">Ocular</option>
                        <option value="otic">Otic</option>
                        <option value="nasal">Nasal</option>
                        <option value="nebulization">Nebulization</option>
                        <option value="cutaneous">Cutaneous</option>
                        <option value="transdermal">Transdermal</option>
                        </select>
                        <div className="invalid-feedback">{errors3.route?.message}</div>
                    </div>
                    </div>
                    <div className="col-lg-6 ">
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Frequency
                        </label>
                        <select
                        className="form-select"
                        aria-label="Default select example"
                        name="frequency"
                        {...register3('frequency')}
                        >
                        <option value="">Select Frequency</option>
                        <option value="Daily">Daily</option>
                        <option value="BID">BID</option>
                        <option value="TID">TID</option>
                        <option value="QID">QID</option>
                        </select>
                        <div className="invalid-feedback">{errors3.frequency?.message}</div>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Refills
                        </label>
                        <select
                        className="form-select"
                        aria-label="Default select example"
                        name="refills"
                        {...register3('refills')}
                        >
                        <option value="">Select Refills</option>
                        <option value="none">None</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        </select>
                        <div className="invalid-feedback">{errors3.refills?.message}</div>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Quantity
                        </label>
                        <input
                        type="text"
                        {...register3('quantity')}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter Quantity e.g., 36"
                        />
                        <div className="invalid-feedback">{errors3.quantity?.message}</div>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Comments
                        </label>
                        <input
                        type="text"
                        {...register3('comments')}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter Comments"
                        />
                        <div className="invalid-feedback">{errors3.comments?.message}</div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div className="modal-footer">
                    {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                    <button
                        type="submit"
                        id="buttonclose"
                        className="btn submit-btn font_18"
                    >
                        Submit
                    </button>
                    </div>
                </div>
            </form>
            </div>           
        </div>
        <div className="modal-backdrop fade show"></div>
        </>)
        }

        {showSummaryModal &&
        (<>
        <div
            className={`modal fade ${showSummaryModal ? 'show' : ''}`}
            id="staticBackdrop4"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
            style={{ display: showSummaryModal ? 'block' : 'none' }}
        >
            <div className="modal-dialog prescription-modal table-modal modal-dialog-centered modal-xl">
            <div className="modal-content">
                <div className="modal-header border-0">
                <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closeSummaryModal}
                />
                </div>
                <div className="modal-body">
                <div className="row justify-content-end">
                    <div className="col-xl-3 col-lg-4 col-6 mb-3">
                    <input
                        className="form-control"
                        placeholder="Search medicine"
                        type="text"
                        name="date"
                        value={searchQuery}
                        onChange={handleSearch}
                    />
                    </div>
                    <div className="col-xl-3 col-lg-4 col-6 mb-3">
                    <div className="input-group" id="datepicker">
                        <input
                        type="date"
                        className="form-control border-0"
                        placeholder="29-01-2024"
                        onChange={handleDate}
                        />
                    </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-bordered prescribe-table">
                    <thead>
                        <tr>
                        <th className="heading" scope="col" colSpan={6}>
                            TABLE OF PRESCRIPTION FORM INFORMATION
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <th scope="row">Page</th>
                        <th>Name of Drug</th>
                        <th>Strength</th>
                        <th>Unit</th>
                        <th>Form</th>
                        <th>Dose</th>
                        <th>Frequency</th>
                        <th>Intakes or Applications</th>
                        <th>Route</th>
                        <th>Refills</th>
                        <th>Quantity</th>
                        <th>Comments</th>
                        <th>Action</th>
                        </tr>
                        {medicineData && medicineData.map((item)=>{
                            return(
                                <tr key={item.id}>
                                    <td scope="row">{item.id}</td>
                                    <td>{item.medication_name}</td>
                                    <td>{item.strength}</td>
                                    <td>{item.strength_type}</td>
                                    <td>{item.form}</td>
                                    <td>{item.dose}</td>
                                    <td>{item.frequency}</td>
                                    <td>{item.intake}</td>
                                    <td>{item.route}</td>
                                    <td>{item.refills}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.comments}</td>
                                    <td>
                                        <div className="d-flex gap-2">
                                            <button>
                                                <p className="meeting-status status-ongoing font_600" onClick={()=>handleEditMedicine(item.id)}>Edit</p>
                                            </button>
                                            <button>
                                                <p className="meeting-status status-reject font_600" onClick={()=>handleRemoveMedicine(item.id)}>Remove</p>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                    </table>
                </div>
                <div className="d-flex flex-wrap justify-content-between">
                    <p className="drname">Dr. Richard Wilson</p>
                    <a href="https://healthplusnigeria.com/" className="chemistlink" target="_blank">
                    HealthPlus Pharmacy
                    </a>
                </div>
                </div>
            </div>
            </div>
        </div>
        <div className="modal-backdrop fade show"></div>
        </>)
        }

        {showEditModal &&
        (<>
        <div
            className={`modal fade ${showEditModal ? 'show' : ''}`}
            id="staticBackdrop3"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
            style={{ display: showEditModal ? 'block' : 'none' }}
        >
            <div className="modal-dialog prescription-modal modal-dialog-centered modal-xl">
            <form onSubmit={handleSubmit4(onSubmit4)}>
            <div className="modal-content">
                <div className="modal-header border-0">
                <p className="rxpageno">Rx Page 1</p>
                <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closeEditModal}
                />
                </div>
                <div className="modal-body">
                <div className="row">
                    <div className="col-lg-6 ">
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Medication Name
                        </label>
                        <input
                        type="text"
                        defaultValue={eachMedicineData && eachMedicineData.medication_name}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter e.g., Aspirin"
                        {...register4('medication_name')}
                        />
                        <div className="invalid-feedback">{errors4.medication_name?.message}</div>
                    </div>
                    </div>
                    <div className="col-lg-6 ">
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Form
                        </label>
                        <input
                        type="text"
                        {...register4('form')}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter e.g., Tablet"
                        defaultValue={eachMedicineData && eachMedicineData.form}
                        />
                        <div className="invalid-feedback">{errors4.form?.message}</div>
                    </div>
                    </div>
                    <div className="col-lg-6 ">
                        <div className="mb-3">
                            <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                            >
                            Strength
                            </label>
                            {/* <div className="input-group">
                            <div> */}
                            <input
                                type="text"
                                {...register4('strength')}
                                className="form-control"
                                placeholder="Enter Strength e.g.,81"
                                defaultValue={eachMedicineData && eachMedicineData.strength}
                            />
                            <div className="invalid-feedback">{errors4.strength?.message}</div>
                            {/* </div>
                            <div className="input-group-append flex-1">
                                <select
                                className="form-select font_500"
                                id="inputGroupSelect04"
                                aria-label="Example select with button addon"
                                {...register2('milligrams')}
                                >
                                <option value="">Select Strength</option>
                                <option value="milligrams">Milligrams</option>
                                <option value="micrograms">Micrograms</option>
                                <option value="percent">Percent</option>
                                </select>
                            <div className="invalid-feedback">{errors2.milligrams?.message}</div>
                            </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-6 ">
                        <div className="mb-3">
                            <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                            >
                            Units
                            </label>
                            <input
                            type="text"
                            {...register4('units')}
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Units e.g., Milligrams"
                            defaultValue={eachMedicineData && eachMedicineData.strength_type}
                            />
                            <div className="invalid-feedback">{errors4.strength_type?.message}</div>
                        </div>
                    </div>
                    <div className="col-lg-6 ">
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Dose
                        </label>
                        <div className="input-group">
                        <div>

                        <input
                            type="text"
                            {...register4('dose')}
                            className="form-control"
                            placeholder="Enter Dose e.g.,2"
                            defaultValue={eachMedicineData && eachMedicineData.dose}
                        />
                        <div className="invalid-feedback">{errors4.dose?.message}</div>
                        </div>
                        <div className="input-group-append flex-1">
                            <select
                            className="form-select font_500"
                            id="inputGroupSelect04"
                            aria-label="Example select with button addon"
                            {...register4('tabs_or_caps_or_puffs')}
                            defaultValue={eachMedicineData && eachMedicineData.dose_type}
                            >
                            <option value="">Tabs or Caps or Puffs</option>
                            <option value="tabs">Tabs</option>
                            <option value="caps">Caps</option>
                            <option value="puffs">Puffs</option>
                            </select>
                        <div className="invalid-feedback">{errors4.tabs_or_caps_or_puffs?.message}</div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 ">
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Intakes or Applications
                        </label>
                        <input
                        type="text"
                        {...register4('intakesorapplications')}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter Number e.g.,2"
                        defaultValue={eachMedicineData && eachMedicineData.intake}
                        />
                        <div className="invalid-feedback">{errors4.intakesorapplications?.message}</div>
                    </div>
                    </div>
                    <div className="col-lg-6 ">
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Route
                        </label>
                        <select
                        className="form-select"
                        aria-label="Default select example"
                        name="route"
                        {...register4('route')}
                        defaultValue={eachMedicineData && eachMedicineData.route}
                        >
                        <option value="">Select Route</option>
                        <option value="oral">Oral</option>
                        <option value="topical">Topical</option>
                        <option value="rectal">Rectal</option>
                        <option value="Inhalation">Inhalation</option>
                        <option value="sublingual_and_buccal">Sublingual And Buccal</option>
                        <option value="vaginal">Vaginal</option>
                        <option value="ocular">Ocular</option>
                        <option value="otic">Otic</option>
                        <option value="nasal">Nasal</option>
                        <option value="nebulization">Nebulization</option>
                        <option value="cutaneous">Cutaneous</option>
                        <option value="transdermal">Transdermal</option>
                        </select>
                        <div className="invalid-feedback">{errors4.route?.message}</div>
                    </div>
                    </div>
                    <div className="col-lg-6 ">
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Frequency
                        </label>
                        <select
                        className="form-select"
                        aria-label="Default select example"
                        name="frequency"
                        {...register4('frequency')}
                        defaultValue={eachMedicineData && eachMedicineData.frequency}
                        >
                        <option value="">Select Frequency</option>
                        <option value="Daily">Daily</option>
                        <option value="BID">BID</option>
                        <option value="TID">TID</option>
                        <option value="QID">QID</option>
                        </select>
                        <div className="invalid-feedback">{errors4.frequency?.message}</div>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Refills
                        </label>
                        <select
                        className="form-select"
                        aria-label="Default select example"
                        name="refills"
                        {...register4('refills')}
                        defaultValue={eachMedicineData && eachMedicineData.refills}
                        >
                        <option value="">Select Refills</option>
                        <option value="none">None</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        </select>
                        <div className="invalid-feedback">{errors4.refills?.message}</div>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Quantity
                        </label>
                        <input
                        type="text"
                        {...register4('quantity')}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter Quantity e.g., 36"
                        defaultValue={eachMedicineData && eachMedicineData.quantity}
                        />
                        <div className="invalid-feedback">{errors4.quantity?.message}</div>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Comments
                        </label>
                        <input
                        type="text"
                        {...register4('comments')}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter Comments"
                        defaultValue={eachMedicineData && eachMedicineData.comments}
                        />
                        <div className="invalid-feedback">{errors4.comments?.message}</div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="modal-footer">
                <button
                    type="submit"
                    className="btn submit-btn font_18"
                >
                    Update
                </button>
                </div>
            </div>
            </form>
            </div>
        </div>
        <div className="modal-backdrop fade show"></div>
        </>)
        }

        {showSendRxModal &&
        (<>
        <div
            className={`modal fade ${showSendRxModal ? 'show' : ''}`}
            id="sharerx"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
            style={{ display: showSendRxModal ? 'block' : 'none' }}
        >
            <div className="modal-dialog prescription-modal table-modal modal-dialog-centered modal-md">
                <div className="modal-content">
                <div className="modal-header border-0">
                    <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closeSendRxModal}
                    />
                </div>
                <div className="modal-body">
                    <div className="table-responsive">
                    <table className="table table-bordered prescribe-table">
                        <tbody>
                        <tr></tr>
                        <tr>
                            <td scope="row">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                                id="flexCheckDefault"
                                defaultChecked={true}
                                disabled
                            />
                            </td>
                            <td>
                            <div>
                                <label
                                className="form-check-label label-font"
                                htmlFor="flexCheckDefault"
                                >
                                Patient:{" "}
                                <span className="text-blue">{prescriptionPatientData && prescriptionPatientData.patient_email}</span>
                                </label>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                                id="flexCheckDefault1"
                                defaultChecked={true}
                                disabled
                            />
                            </td>
                            <td>
                            <div>
                                <label
                                className="form-check-label label-font"
                                htmlFor="flexCheckDefault1"
                                >
                                Doctor:{" "}
                                <span className="text-blue">{localStorage.getItem("user_email")}</span>
                                </label>
                            </div>
                            </td>
                        </tr>
                        {
                            chemistList && chemistList.map((item, index) => {
                                return (
                                    <tr id={item.id} key={index}>
                                        <td scope="row">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={item.id}
                                            id="flexCheckDefault2"
                                            onChange={(e)=>handleChemistList(e)}
                                        />
                                        </td>
                                        <td>
                                        <div>
                                            <label
                                            className="form-check-label label-font"
                                            htmlFor="flexCheckDefault2"
                                            >
                                            Chemist:{" "}
                                            <span className="text-blue">
                                                {item.email}
                                            </span>
                                            </label>
                                        </div>
                                        </td>
                                    </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    </div>
                </div>
                <div className="modal-footer border-0">
                    {/* <button
                    type="button"
                    className="connect-btn w-100"
                    // data-bs-toggle="modal"
                    // data-bs-target="#view-presciption"
                    >
                    QR Codes
                    </button> */}
                    <button
                    type="button"
                    data-bs-dismiss="modal"
                    className="btn submit-btn font_18"
                    onClick={handleSendRx}
                    >
                    Send
                    </button>
                </div>
                </div>
            </div>
        </div>
        <div className="modal-backdrop fade show"></div>
        </>)
        }

    </main>
    </>
  )
}

export default Prescription;