import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { getDoctor, getDoctorSpecialities, makeAppoinment } from '../../../api/apiHandler';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';


import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import moment from 'moment/moment';



function NewDoctorList() {
  const navigate = useNavigate();
  const [doctorsList, setDoctorssList] = useState([]);
  const [doctorSpecialities,setDoctorSpecialities] = useState([]);
  const [totalRecordCount, setTotalRecordCount] = useState(0)
  const [pageNo, setPageNo] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchSpecialityId, setSearchSpecialityId] = useState('');

  useEffect(() => {
    if(searchQuery == ''){
      Swal.fire({
        title: 'Please wait...',
        didOpen: () => {
            Swal.showLoading();
        }
      });
    }
    getDoctor({ "page": pageNo, "record_count": perPage, "search_text": searchQuery, "search_speciality_id":searchSpecialityId }).then((response) => {
      Swal.close();
      response = JSON.parse(response)
      // console.log("response",response);
      if (response.code == process.env.REACT_APP_SUCCESS) {
        setDoctorssList(response.data.doctors)
        setTotalRecordCount(Math.ceil(response.data.total_record_count / perPage))
      } else {
        setDoctorssList([]);
      }
    })
    // Swal.close();
  }, [searchQuery, searchSpecialityId, pageNo]);

  useEffect(()=>{
    getDoctorSpecialities({}).then((response) => {
      response = JSON.parse(response)
      if (response.code == process.env.REACT_APP_SUCCESS) {
        response.data.map((item)=>{
            const specilityObj = {
                label: item.name, 
                value: item.id,
                icon: item.icon,
            }
            doctorSpecialities.push(specilityObj)
        })
        // setDoctorSpecialities(response.data)
      }
    })
  },[])

  
  const handlePageClick = (event) => {
    setPageNo(event.selected + 1)
  };

  const connectMeeting = (doctor_id) => {
   
    const bookAppointmentObj = {
      "doctor_id" : parseInt(doctor_id),
      "appointment_date" : moment().utc().format('YYYY-MM-DD'),
      "appointment_time" : moment().utc().format('hh:mm A'),
      "is_schedule" : 1
    }
    makeAppoinment(bookAppointmentObj).then((res) => {
      var response = JSON.parse(res)
      console.log("response",response);
      if (response.code == process.env.REACT_APP_SUCCESS) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 5000
        })
        handleStartMeeting(response.data)
      }
      else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      }
    });  
  }

  const handleStartMeeting = (patient_data) => {
    console.log("patient_data",patient_data);
    const startMeetingObj = {
      "name" : localStorage.getItem("user_name"),
      "email" : localStorage.getItem("user_email"),
      "appointment_id" : patient_data.id,
      "start_meeting" : 1
    }
    navigate('/meeting',{ state : startMeetingObj })
  }

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  const handleSearchSpecialityId = (data) => {
    // console.log("sss>>>>>>>>>>",data.value);
    setSearchSpecialityId(data.value)
  }

  return (
    <>
      <Header />
      <section className="doctorsec-pad">
        <div className="container">
          <div className="mt-4">
            <p className="font_22 font_600 mb-0">Doctors </p>
            {/* <nav style={{ '--bs-breadcrumb-divider': '>' }} aria-label="breadcrumb">
              <ol className="breadcrumb">
                <Link to="/doctor/home" className="font_14">
                  Home
                </Link>
                <li className="breadcrumb-item active font_14" aria-current="page"> Doctors</li>
              </ol>
            </nav> */}
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                    <Link to="/patient/home" className="font_14">
                        Home
                    </Link>
                    </li>
                    <li className="breadcrumb-item active font_14" aria-current="page"> Doctors</li>
                </ol>
            </nav>
          </div>
          <div className="card">
            <div className="card-body doctor-table-card">
              <div className="table-responsive">
              <div className="row justify-content-end">
              <div className="search-bar col-lg-3 col-md-3">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearch}
                  />
              </div>
              <div className="col-lg-3 col-md-3">
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    name="speacialization"
                    options={doctorSpecialities}
                    onChange={handleSearchSpecialityId}
                  />
                  {/* <select onChange={handleSearchSpecialityId}>
                    <option value="">Select specialization</option>
                    {doctorSpecialities.map((item, key) => (
                    <option key={key} value={item.id}>
                        {item.name}
                    </option>
                    ))}
                  </select> */}
                </div>
                
              </div>
                
                <table id="mangeUser" className="table table-hover dataTable js-exportable">
                  <thead>
                    <tr>
                      <th >ID</th>
                      <th >Doctor Name</th>
                      <th >Phone Number</th>
                      <th >Email Address</th>
                      <th >Address</th>
                      <th >Hospital</th>
                      <th >Status</th>
                      <th >Action</th>
                    </tr>
                  </thead>

                  <tbody>

                    {
                      doctorsList && doctorsList.map((item, index) => {
                        return (
                          <tr id={item.id} key={index}>
                            <td >
                              {item.id}
                            </td>

                            <td>
                              <h3 className="doc-details">
                                <div className="doc-profile">
                                  <img src={item.profile_image} alt="" />
                                </div>
                                {item.first_name} {item.middle_name} {item.last_name}
                              </h3>
                            </td>
                            <td >
                              {item.country_code + " " + item.phone_no}
                            </td>
                            <td >
                              {item.email}
                            </td>
                            <td >
                              {item.address}
                            </td>
                            <td >
                              {item.hospital_or_clinic}
                            </td>
                            <td >
                              {item.is_available === '0' ? <b><p className="mb-0 doc-status bg-danger">Not available</p></b> : <b><p className="mb-0 doc-status">Available</p></b>}
                            </td>

                            <td >
                              <button className="connect-btn" onClick={() =>connectMeeting(item.id)}>Connect</button>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
               
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=" > "
                  onPageChange={handlePageClick}
                  pageCount={totalRecordCount}
                  previousLabel=" < "
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  disabledClassName="disabled"
                  activeClassName="active"
                  activeLinkClassName="active-link"
                />
               
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default NewDoctorList;
