import React from 'react';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';

function StaticTermsAndConditionPage() {
  return (
    <>
        <main className="bg-color">
            <Header />
            <section className="heading-section">
                <p className="font_54 text-white font_500 text-center">Terms of use</p>
            </section>
            <section className="mb-5">
                <div className="container">
                    <div className="terms-use">
                        <p className="font_500 font_16">
                            Effective Date:&nbsp; August 31, 2021
                        </p>
                        <p className="font_15">
                            These Terms of Use (“Terms”) govern your use of the, Monte Sereno
                            Holdings Corporation (“MSH” “we,” “us,” or “our”) website, platform
                            and any other website or online service that MSH operates and that
                            links to these Terms (collectively, the “Services”).
                        </p>
                        <p>
                            <span className="font_500">
                                Please review these Terms carefully before using the Services.
                            </span>
                            &nbsp;We may change these Terms or modify any features of the Services
                            at any time.&nbsp; The most current version of the Terms can be viewed
                            by clicking on the “Terms of Use” link posted through the Services.
                            <span className="font_500">
                                &nbsp;You accept the Terms by using the Services, and you accept any
                                changes to the Terms by continuing to use the Services after we post
                                the changes.
                            </span>
                        </p>
                        <hr />
                        <div>
                            <p className="font_20 font_500 mb-0">I.&nbsp;Privacy</p>
                            <p className="text">
                                By using the Services, you consent to our processing your
                                information consistent with our Privacy Policy (See Privacy Policy
                                Tab).
                            </p>
                        </div>
                        <div>
                            <p className="font_20 font_500 mb-0">
                                II.&nbsp;Important Information about Your Use of the Services
                            </p>
                            <p className="text">
                                Do not use the services for emergency medical needs. If &nbsp; if
                                you experience a medical emergency, call the emergency number within
                                your locality, immediately.
                            </p>
                            <p className="text">
                                MSH does not provide any physicians’ or other healthcare providers’
                                (collectively, “Providers”) services itself.&nbsp; All of the
                                Providers are independent of MSH and merely using the Services to
                                communicate with you.&nbsp; Any information or advice received from
                                a Provider comes from the Provider, and not from MSH.&nbsp; Your
                                interactions with the Providers via the Services are not intended to
                                take the place of your relationship(s) with your regular health care
                                practitioner(s).
                            </p>
                            <p className="text">
                                Neither MSH nor any of its licensors or suppliers or any third
                                parties who promote the Services or provide you with a link to the
                                Services shall be liable for any professional Advice you obtain from
                                a Provider via the Service nor for any information obtained from our
                                Services.&nbsp; You acknowledge your reliance on any Providers or
                                information provided by the Services is solely at your own risk and
                                you assume full responsibility for all risk associated therewith.
                            </p>
                            <p className="text">
                                MSH does not make any representations or warranties about the
                                training or skill of any Providers using the Services.&nbsp; You are
                                ultimately responsible for choosing your particular Provider on the
                                Services.
                            </p>
                            <p className="text">
                                You hereby certify that you are physically located in the State you
                                have entered as your current location on the Services.&nbsp; You
                                acknowledge that your ability to access and use the Services is
                                conditioned upon the truthfulness of this certification and that the
                                Providers you access through the Services are relying upon this
                                certification in order to interact with you.&nbsp; In the event that
                                your certification is inaccurate, you agree to indemnify MSH and the
                                Providers you interact with from any resulting damages, costs, or
                                claims.
                            </p>
                        </div>
                        <div>
                            <p className="font_20 font_500 mb-0">III.&nbsp;Account Enrollment</p>
                            <p className="text">
                                To access Providers using the Services, you must first establish an
                                individual user account (“Account”) by providing certain
                                information.&nbsp; You agree that you will not create more than one
                                Account, or create an Account for anyone other than yourself (with
                                the exception of subaccounts established for minor children of whom
                                you are a parent or legal guardian).&nbsp; You agree to provide
                                true, accurate, current, and complete information on the Account
                                enrollment form and to keep this information current and updated as
                                needed.
                            </p>
                            <p className="text">
                                You represent and warrant that you are at least 18 years of age and
                                possess the legal right and ability, on behalf of yourself or a
                                minor child of whom you are a parent or legal guardian, to agree to
                                these Terms of Use.
                            </p>
                        </div>
                        <div>
                            <p className="font_20 font_500 mb-0">IV.&nbsp;Prohibited Conduct</p>
                            <p className="text">
                                You may not access or use, or attempt to access or use, the Services
                                to take any action that could harm us or any third party, interfere
                                with the operation of the Services, or use the Services in a manner
                                that violates any laws.&nbsp; For example, and without limitation,
                                you may not:
                            </p>
                            <ul className="text list-unstyled">
                                <li className="liststyle">
                                    <div>
                                        <i className="fas fa-circle-check text-blue01 pe-1" />
                                    </div>
                                    <div>
                                        {" "}
                                        transmit any message or information under a false name or
                                        otherwise misrepresent your affiliation or the origin of
                                        materials you transmit;
                                    </div>
                                </li>
                                <li className="liststyle">
                                    <div>
                                        <i className="fas fa-circle-check text-blue01 pe-1" />
                                    </div>
                                    <div>
                                        provide information on the enrollment form that is untrue,
                                        inaccurate, not current, or incomplete;
                                    </div>
                                </li>
                                <li className="liststyle">
                                    <div>
                                        <i className="fas fa-circle-check text-blue01 pe-1" />
                                    </div>
                                    <div>
                                        transmit any message or information that is unlawful, libelous,
                                        defamatory, obscene, fraudulent, predatory of minors, harassing,
                                        threatening, or hateful;
                                    </div>
                                </li>
                                <li className="liststyle">
                                    <div>
                                        <i className="fas fa-circle-check text-blue01 pe-1" />
                                    </div>
                                    <div>
                                        transmit any message or information that infringes or violates
                                        the intellectual property, privacy, or publicity rights of
                                        others;
                                    </div>
                                </li>
                                <li className="liststyle">
                                    <div>
                                        <i className="fas fa-circle-check text-blue01 pe-1" />
                                    </div>
                                    <div>
                                        reproduce, retransmit, distribute, disseminate, sell, publish,
                                        broadcast, or circulate content received through the Services to
                                        anyone without prior express permission;
                                    </div>
                                </li>
                                <li className="liststyle">
                                    <div>
                                        <i className="fas fa-circle-check text-blue01 pe-1" />
                                    </div>
                                    <div>
                                        engage in unauthorized spidering, “scraping,” or harvesting of
                                        content or personal information, or use any other unauthorized
                                        automated means to compile information;
                                    </div>
                                </li>
                                <li className="liststyle">
                                    <div>
                                        <i className="fas fa-circle-check text-blue01 pe-1 " />
                                    </div>
                                    <div>
                                        take any action that imposes an unreasonable or
                                        disproportionately large load on our network or infrastructure;
                                    </div>
                                </li>
                                <li className="liststyle">
                                    <div>
                                        <i className="fas fa-circle-check text-blue01 pe-1" />
                                    </div>
                                    <div>
                                        use any device, software, or routine to interfere or attempt to
                                        interfere with the proper working of the Services or any
                                        activity conducted on the Services or attempt to probe, scan,
                                        test the vulnerability of, or breach the security of any system
                                        or network;
                                    </div>
                                </li>
                                <li className="liststyle">
                                    <div>
                                        <i className="fas fa-circle-check text-blue01 pe-1" />
                                    </div>
                                    <div>
                                        attempt to modify, translate, decipher, decompile, disassemble,
                                        reverse-engineer, or create derivative works of any of the
                                        software comprising or in any way making up a part of the
                                        Services; or
                                    </div>
                                </li>
                                <li className="liststyle">
                                    <div>
                                        <i className="fas fa-circle-check text-blue01 pe-1" />
                                    </div>
                                    <div>
                                        engage in any other conduct that restricts or inhibits any
                                        person from using or enjoying the Services, or that, in our sole
                                        judgment, exposes us or any of our users, affiliates, or any
                                        other third party to any liability, damages, or detriment of any
                                        type.
                                    </div>
                                </li>
                            </ul>
                            <p className="text">
                                Violations of system or network security may result in civil or
                                criminal liability.&nbsp; We may investigate and work with law
                                enforcement authorities to prosecute users who violate the
                                Terms.&nbsp; We may suspend or terminate your access to the Services
                                for any or no reason at any time without notice.
                            </p>
                        </div>
                        <div>
                            <p className="font_20 font_500 mb-0">V.&nbsp;Payment Authorization</p>
                            <p className="text">
                                By providing a debit card, credit card or other payment method
                                accepted by MSH (“Payment Method”), you are expressly agreeing that
                                we are authorized to charge to the Payment Method any fees for your
                                use of the Services, together with any applicable taxes.&nbsp;
                                Please note that MSH may not receive complete information from your
                                health insurance plan, if applicable, regarding the applicable
                                co-pay due from you for your consultation.&nbsp; As such, you may be
                                billed more than once with respect to a consultation to account for
                                additional co-pay amounts due, if any. &nbsp;Should you choose not
                                to enter your health plan billing details, you elect to be seen as
                                self-pay, thereby waiving health plan claim submission.
                            </p>
                            <p className="text">
                                You agree that authorizations to charge your Payment Method remains
                                in effect until you cancel it in writing, and you agree to notify
                                MSH of any changes to your Payment Method.&nbsp; You certify that
                                you are an authorized user of the Payment Method and will not
                                dispute charges for the Services that correspond to consultation
                                fees, or the co-payment required by your health plan.&nbsp; You
                                acknowledge that the origination of Automated Clearing House (ACH)[
                                ACH stands for Automated Clearing House, a U.S. financial network
                                used for electronic payments and money transfers. Also known as
                                “direct payments,” ACH payments are a way to transfer money from one
                                bank account to another without using paper checks, credit card
                                networks, wire transfers, or cash.] transactions to your account
                                must comply with applicable provisions of U.S. law and other
                                relevant international law.&nbsp; In the case of an ACH transaction
                                rejected for insufficient funds, MSH may at its discretion attempt
                                to process the charge again at any time within 30 days.
                            </p>
                            <p className="text">
                                You acknowledge and agree that fees for consultations may increase
                                at any time.
                            </p>
                        </div>
                        <div>
                            <p className="font_20 font_500 mb-0">
                                VI. Intellectual Property Rights
                            </p>
                            <p className="text">
                                The Services are protected under the copyright laws of the United
                                States and other countries.&nbsp; All copyrights in the Services are
                                owned by us or our third-party licensors to the full extent
                                permitted under the United States Copyright Act and all
                                international copyright laws. You may not publish, reproduce,
                                distribute, display, perform, edit, adapt, modify, or otherwise
                                exploit any part of the Services without our written consent.
                            </p>
                            <p className="text">
                                All rights in the product names, company names, trade names, logos,
                                service marks, trade dress, slogans, product packaging, and designs
                                of the Services, whether or not appearing in large print or with the
                                trademark symbol, belong exclusively to MSH or its licensors and are
                                protected from reproduction, imitation, dilution, or confusing or
                                misleading uses under national and international trademark and
                                copyright laws. The use or{" "}
                            </p>
                            <p className="text">
                                {" "}
                                ACH stands for Automated Clearing House, a U.S. financial network
                                used for electronic payments and money transfers. Also known as
                                “direct payments,” ACH payments are a way to transfer money from one
                                bank account to another without using paper checks, credit card
                                networks, wire transfers, or cash.
                            </p>
                            <p className="text">
                                misuse of these trademarks or any materials, except as authorized
                                herein, is expressly prohibited, and nothing stated or implied on
                                the Services confers on you any license or right under any patent or
                                trademark of MSH, its affiliates, or any third party.
                            </p>
                        </div>
                        <div>
                            <p className="font_20 font_500 mb-0">VII.&nbsp;Password Security</p>
                            <p className="text">
                                You are solely responsible for the security of your username and
                                password, and for any use of the Services using your username and
                                password.&nbsp; You should not share your username or password with
                                any third party or allow any third party to access the Services
                                using your username and password.&nbsp; You agree to notify us if
                                you have any reason to believe that your username or password has
                                been lost, compromised, or misused in any way.&nbsp; We reserve the
                                right to revoke or deactivate your username and password at any
                                time.
                            </p>
                        </div>
                        <div>
                            <p className="font_20 font_500 mb-0">
                                VIII.&nbsp;Website/App and Third-Party Contents
                            </p>
                            <p className="text">
                                Other than information received directly by you from Providers, the
                                content on the Services should not be considered medical
                                advice.&nbsp; You should always talk to an appropriately qualified
                                health care professional for diagnosis and treatment, including
                                information regarding which medications or treatment may be
                                appropriate for you.&nbsp; NONE OF THE CONTENT ON THE SERVICES
                                REPRESENTS OR WARRANTS THAT ANY PARTICULAR MEDICATION OR TREATMENT
                                IS SAFE, APPROPRIATE, OR EFFECTIVE FOR YOU.&nbsp; MSH does not
                                recommend or endorse any specific tests, providers, medications,
                                products, or procedures.
                            </p>
                            <p className="text">
                                The Services may provide links to third-party content.&nbsp; You
                                acknowledge and agree that we are not responsible for the
                                availability of such third-party content, and we do not control,
                                endorse, sponsor, recommend, or otherwise accept responsibility for
                                such content.&nbsp; Use of any linked third-party content is at the
                                user’s own risk.
                            </p>
                        </div>
                        <div>
                            <p className="font_20 font_500 mb-0">IX.&nbsp;Consent to Services</p>
                            <p className="text">
                                The Service respects and upholds patient confidentiality with
                                respect to protected health information as outlined by
                                the&nbsp;Health Insurance Portability and Accountability Act
                                (“HIPAA”), and, subject to HIPAA regulations, will obtain express
                                patient consent prior to sharing any patient-identifiable
                                information to a third party for purposes other than treatment,
                                payment or health care operations.&nbsp; In addition, by clicking
                                the “AGREE” button you are authorizing Providers to release your
                                contact information to MSH solely in order for MSH to provide you
                                with information about additional clinical services or general
                                wellness. &nbsp;You may opt out of receiving such information by
                                contacting us at&nbsp;privacy@monteserenohealth.com.&nbsp; Finally,
                                when using the Service you may be asked if you would like to share
                                certain Protected Health Information (PHI)[ Learn more about
                                protected health information at:&nbsp;
                                <a
                                    className="list-link"
                                    href="http://www.hhs.gov/ocr/privacy/hipaa/understanding/index.html]"
                                >
                                    http://www.hhs.gov/ocr/privacy/hipaa/understanding/index.html]
                                </a>
                                collected by Apple’s HealthKit or similar software with MSH.&nbsp;
                                By clicking on “SYNC” you are authorizing MSH to collect and
                                Provider to utilize such PHI.
                            </p>
                            <p className="text">
                                {" "}
                                Learn more about protected health information at:{" "}
                                <a
                                    className="list-link"
                                    href=" http://www.hhs.gov/ocr/privacy/hipaa/understanding/index.html"
                                >
                                    &nbsp;http://www.hhs.gov/ocr/privacy/hipaa/understanding/index.html
                                </a>
                            </p>
                        </div>
                        <div>
                            <p className="font_20 font_500 mb-0">
                                X.&nbsp;Consent for Treatment Performed
                            </p>
                            <p className="text">
                                We are providing this information on behalf of Providers:
                            </p>
                            <p className="text">
                                Our Services involve the use of communications to enable health care
                                providers at sites remote from patients to provide consultative
                                services.&nbsp; Providers may include primary care practitioners,
                                specialists, and/or subspecialists.&nbsp; The information may be
                                used for diagnosis, therapy, follow-up and/or education, and may
                                include live two-way audio and video and other materials (e.g.,
                                medical records, data from medical devices).
                            </p>
                            <p className="text">
                                The communications systems used will incorporate reasonable security
                                protocols to protect the confidentiality of patient information and
                                will include reasonable measures to safeguard the data and to ensure
                                its integrity against intentional or unintentional corruption.&nbsp;
                                At the end of each encounter, the patient will be provided an
                                encounter summary, which may be kept for the patient’s records and
                                may be shared with the patient’s local primary care or other
                                provider, as appropriate.
                            </p>
                        </div>
                        <div>
                            <p className=" font_500 mb-2">Anticipated Benefits:</p>
                            <ul>
                                <li className="pb-1">
                                    Improved access to medical care by enabling a patient to remain at
                                    his or her home or office while consulting a clinician.
                                </li>
                                <li className="pb-1">
                                    More efficient medical evaluation and management.
                                </li>
                            </ul>
                        </div>
                        <div>
                            <p className=" font_500 mb-2">Possible Risks:</p>
                            <p>
                                As with any medical procedure, there are potential risks associated
                                with the use of the internet.&nbsp; Provider believes that the
                                likelihood of these risks materializing is very low.&nbsp; These
                                risks may include, without limitation, the following:
                            </p>
                            <ul>
                                <li className="pb-1">
                                    Delays in medical evaluation and consultation or treatment may
                                    occur due to deficiencies or failures of the equipment.
                                </li>
                                <li className="pb-1">
                                    Security protocols could fail, causing a breach of privacy of
                                    personal medical information.
                                </li>
                                <li className="pb-1">
                                    Lack of access to complete medical records may result in adverse
                                    drug interactions or allergic reactions or other negative
                                    outcomes.
                                </li>
                            </ul>
                        </div>
                        <div>
                            <p className=" font_500 mb-2">
                                By accepting these Terms, you acknowledge that you understand and
                                agree with the following:
                            </p>
                            <p>
                                As with any medical procedure, there are potential risks associated
                                with the use of the internet.&nbsp; Provider believes that the
                                likelihood of these risks materializing is very low.&nbsp; These
                                risks may include, without limitation, the following:
                            </p>
                            <ol>
                                <li className="pb-2">
                                    I understand that the laws that protect privacy and the
                                    confidentiality of medical information also apply to Services
                                    obtained on this platform; I have received the HIPAA Notice which
                                    explains these issues in greater detail.
                                </li>
                                <li className="pb-2">
                                    I understand that Services provided on this platform may involve
                                    electronic communication of my personal medical information to
                                    medical practitioners who may be located in other areas, including
                                    out of state.
                                </li>
                                <li className="pb-2">
                                    I understand that I may expect the anticipated benefits from the
                                    use of the Services provided by MSH, but that no results can be
                                    guaranteed or assured.
                                </li>
                                <li className="pb-2">
                                    I understand that my healthcare information may be shared with
                                    others (including health care providers and health insurers) for
                                    treatment, payment, and healthcare operations purposes.
                                    Psychotherapy notes are maintained by clinicians but are not
                                    shared with others, while billing codes and encounter summaries
                                    are shared with others and with me.&nbsp; If I obtain
                                    psychotherapy from Provider, I understand that my therapist has
                                    the right to limit the information provided to me if in my
                                    therapist’s professional judgment sharing the information with me
                                    would be harmful to me.
                                </li>
                                <li className="pb-2">
                                    I further understand that my healthcare information may be shared
                                    in the following circumstances:
                                    <ul className="pt-1">
                                        <li className="font_14">
                                            When a valid court order is issued for medical records.
                                        </li>
                                        <li className="font_14">
                                            Reporting suspected abuse, neglect, or domestic violence.
                                        </li>
                                        <li className="font_14">
                                            Preventing or reducing a serious threat to anyone’s health or
                                            safety.
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                            <p className="mb-2 font_500">
                                Patient Consent to the Use of Our Services
                            </p>
                            <p>
                                I have read and understand the information provided above, and
                                understand the risks and benefits of telemedicine, and by accepting
                                these Terms I hereby give my informed consent to participate in a
                                telemedicine visit under the terms described herein.
                            </p>
                        </div>
                        <div>
                            <p className="font_20 font_500 mb-0">
                                XI.&nbsp;&nbsp;&nbsp;Disclaimer of Warranties; Limitation of
                                Liability
                            </p>
                            <p className="text">
                                Your use of the Services is at your own risk. The Services are
                                provided “as is” without warranties of any kind, either express or
                                implied, including without limitation warranties of title,
                                merchantability, fitness for a particular purpose, non-infringement,
                                or other violation of rights. We do not warrant the adequacy,
                                currency, accuracy, likely results, or completeness of the Services
                                or any third-party sites linked to or from the Services, or that the
                                functions provided will be uninterrupted, virus-free, or error-free.
                                We expressly disclaim any liability for any errors or omissions in
                                the content included in the Services or any third-party sites linked
                                to or from the Services. some jurisdictions may not allow the
                                exclusion of implied warranties, so some of the above exclusions may
                                not apply to you.
                            </p>
                            <p className="text">
                                In no event will we, or our parents, subsidiaries, affiliates,
                                licensors, suppliers and their directors, officers, affiliates,
                                subcontractors, employees, &nbsp;agents, and assigns be liable for
                                any direct or indirect, special, incidental, consequential or
                                punitive damages, lost profits, or other damages whatsoever arising
                                in connection with the use of the Services, any interruption in
                                availability of the Services, delay in operation or transmission,
                                computer virus, loss of data, or use, misuse, reliance, review,
                                manipulation, or other utilization in any manner whatsoever of the
                                Services or the data collected through the Services, even if one or
                                more of them has been advised of the possibility of such damages or
                                loss.&nbsp; BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
                                LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, MSH’S LIABILITY
                                IN SUCH JURISDICTIONS SHALL BE LIMITED TO THE MAXIMUM EXTENT
                                PERMITTED BY LAW.
                                <br />
                                You agree that your sole remedy for Any claim arising out of or
                                connected with the Services will be to cease using the Services.
                            </p>
                            <p>
                                You acknowledge and agree that MSH is not engaged in the practice of
                                medicine and that MSH is not determining appropriate medical use of
                                the services.&nbsp; MSH, its licensors, suppliers, and all third
                                parties who promote the services or provide you with a link to the
                                services expressly disclaim any and all liability resulting from the
                                delivery of healthcare via the service, including but not limited to
                                liability for medical malpractice
                            </p>
                        </div>
                        <div>
                            <p className="font_20 font_500 mb-0">
                                XII.&nbsp;&nbsp;Indemnification
                            </p>
                            <p className="text">
                                You agree to indemnify, defend and hold us and our parents,
                                subsidiaries, affiliates, licensors, suppliers, and their directors,
                                officers, affiliates, subcontractors, employees, agents, and assigns
                                harmless from and against any and all loss, costs, expenses
                                (including reasonable attorneys’ fees and expenses), claims, damages
                                and liabilities related to or associated with your use of the
                                Services and any alleged violation by you of these Terms.&nbsp; We
                                reserve the right to assume the exclusive defense of any claim for
                                which We are entitled to indemnification under this section.&nbsp;
                                In such event, you shall provide us with such cooperation as we
                                reasonably request.
                            </p>
                        </div>
                        <div>
                            <p className="font_20 font_500 mb-0">
                                XIII.&nbsp;Choice of Law and Forum
                            </p>
                            <p className="text">
                                The Services are intended for use by residents in Africa.&nbsp; We
                                do not intentionally provide access to the Services to individuals
                                located outside of Africa.&nbsp; You agree that your access to and
                                use of the Services will be governed by and will be construed in
                                accordance with the local laws of the country where the customer is
                                situate, without regard to principles of conflicts of laws.&nbsp;
                                You agree that any claim or dispute against us arising out of or
                                relating to the Services must be resolved in line with the
                                applicable laws, unless agreed upon by all parties.
                            </p>
                        </div>
                        <div>
                            <p className="font_20 font_500 mb-0">XIV.&nbsp;Miscellaneous</p>
                            <p className="text">
                                These Terms constitute the entire agreement between you and us,
                                superseding any prior or contemporaneous communications and
                                proposals (whether oral, written or electronic) between you and
                                us.&nbsp; In the event any provision of these Terms is held
                                unenforceable, it will not affect the validity or enforceability of
                                the remaining provisions and will be replaced by an enforceable
                                provision that comes closest to the intention underlying the
                                unenforceable provision. You agree that no joint venture,
                                partnership, employment, or agency relationship exists between you
                                and us as a result of these Terms or your access to and use of the
                                Services.
                            </p>
                            <p className="text">
                                Our failure to enforce any provisions of these Terms or respond to a
                                violation by any party does not waive our right to subsequently
                                enforce any terms or conditions of the Terms or respond to any
                                violations.&nbsp; Nothing contained in these Terms is in derogation
                                of our right to comply with governmental, court, and law enforcement
                                requests or requirements relating to your use of the Services or
                                information provided to or gathered by us with respect to such use
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </main>
    </>
  )
}

export default StaticTermsAndConditionPage;
