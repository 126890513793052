import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { getDoctor } from '../../../api/apiHandler';
import { useNavigate, Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';

const columns = [

  {
    name: <th style={{ textAlign: "center" }}>Doctor Name</th>,
    selector: row => <td style={{ textAlign: "center" }}>
      <h3 className="doc-details ">
        <div className="doc-profile">
          <img src={row.profile_image} width={50} alt={row.first_name}></img>
        </div>
        {row.first_name + " " + row.middle_name + " " + row.last_name}
      </h3>
    </td>,
  },
  {
    name: <th style={{ textAlign: "center" }}>Phone number</th>,
    selector: row => <td style={{ textAlign: "center" }}>
      {row.country_code + " " + row.phone_no}
    </td>,
  },
  {
    name: <th style={{ textAlign: "center" }}>Email address</th>,
    selector: row => <td style={{ textAlign: "center" }}>
      {row.email}
    </td>,
  },
  {
    name: <th style={{ textAlign: "center" }}>Status</th>,
    selector: row => <td style={{ textAlign: "center" }}>
      <p className={`${row.is_available == "1" ? "mb-0 doc-status" : "mb-0 doc-status bg-danger"}`}>{row.is_available == "1" ? "Available " : "Not available "}</p>
    </td>,
  },
  {
    name: <th style={{ textAlign: "center" }}>Action </th>,
    selector: row => <td style={{ textAlign: "center" }} className="text-align-end">
      <a href="" className="connect-btn">Connect</a>
    </td>,
  },
];


function Pagination() {
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const [totalRows, setTotalRows] = useState(1);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    fetchData(1, perPage);
  }, [perPage])

  const fetchData = async (page, per_page) => {
    Swal.fire({
      title: 'Please wait...',
      didOpen: () => {
        Swal.showLoading()
      }
    })
    getDoctor({ "page": page, "record_count": per_page }).then((response) => {
      response = JSON.parse(response)
      if (response.code == process.env.REACT_APP_SUCCESS) {
        Swal.close()
        setItems(response.data.doctors);
        setTotalRows(response.data.total_record_count);
      } else {
        Swal.close()
        setError(error);
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      }
    })
  }

  const handlePageChange = page => {
    fetchData(page, perPage);
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  } else {

    return (
      <>
      <Header />
      <section className="doctorsec-pad">
        <div className="container">
          <div className="mt-4">
            <p className="font_22 font_600 mb-0">Doctors </p>
            <nav style={{ '--bs-breadcrumb-divider': '>' }} aria-label="breadcrumb">
              <ol className="breadcrumb">
                <Link to="/doctor/home" className="font_14">
                  Home
                </Link>
                <li className="breadcrumb-item active font_14" aria-current="page"> Doctors</li>
              </ol>
            </nav>
          </div>
          <div className="card">
            <div className="card-body doctor-table-card">
              <div className="table-responsive">
                <DataTable
                  columns={columns}
                  data={items}
                  pagination
                  paginationServer
                  highlightOnHover
                  striped
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
    );
  }
}

export default Pagination;