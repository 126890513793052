import React from 'react';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';

function StaticAboutUsPage() {
  return (
    <>
        <main className="bg-color">
            <Header/>
            <section className="heading-section">
                <p className="font_54 text-white font_500 text-center">About us</p>
            </section>
            <section className="mb-5">
                <div className="container">
                    <div className="terms-use p-0 overflow-hidden mb-4">
                        <div className="row g-0">
                            <div className="col-lg-4">
                                <div className="about-banner">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/about-us-img--1.jpg"} alt="image" />
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="details-about">
                                    <p className="font_36 font_500 mb-0 text-blue"> THE PROBLEM</p>
                                    <p>
                                        Africa’s healthcare is in crisis -fragile, overburdened, and
                                        underfunded.{" "}
                                    </p>
                                    <ul>
                                        <li className="font_14 text-gray font_500">
                                            {/* <div><i class="fa-solid fa-chevron-right"></i></div> */}
                                            <div>
                                                High Infectious Burden: 18% global population but 60% Global
                                                HIV, 90% Global malaria, worms.
                                            </div>
                                        </li>
                                        <li className="font_14 text-gray font_500">
                                            {/* <div><i class="fa-solid fa-chevron-right"></i></div> */}
                                            <div>
                                                High Chronic load – hypertension, heart disease, diabetes.{" "}
                                            </div>
                                        </li>
                                        <li className="font_14 text-gray font_500">
                                            {/* <div><i class="fa-solid fa-chevron-right"></i></div> */}
                                            <div>
                                                Bad expensive and vestigial Primary Care: 10k patients to 1
                                                doctor, 75% fake drugs killing 122k kids/yr. under 5 years
                                                old, and 19 of the 20 highest global maternal and highest
                                                neonatal death rates.
                                            </div>
                                        </li>
                                        <li className=" font_14 text-gray font_500">
                                            {/* <div><i class="fa-solid fa-chevron-right"></i></div> */}
                                            <div>
                                                {" "}
                                                Non-communicable diseases, such as hypertension, heart
                                                disease, and diabetes are on the rise; and injuries remain
                                                among the top causes of death in the Region.
                                            </div>
                                        </li>
                                    </ul>
                                    <p className="text-gray mb-0">
                                        As Africa urbanizes, it is caught between dismantling
                                        traditional rural healthcare infrastructure and building modern
                                        urban healthcare infrastructure. Neither functions well.
                                        <br /> So, the composite system is fragile, understaffed,
                                        inefficient and underfunded. Infectious diseases like COVID
                                        threaten to overrun this fragile healthcare system and amplify
                                        the negative impacts. Worse still, disrupting healthcare will
                                        disrupt the weak agricultural supply chain leading to increased
                                        death, starvation, and internally displaced persons (IDPs).
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="solution-sec mb-3">
                        <p className="font_36 font_500 text-blue">
                            THE SOLUTION: GPT4 AI-POWERED B2B2C HEALTHCARE MARKETPLACE
                        </p>
                        <p>
                            MSH is rolling out an AI-powered One-Stop Healthcare platform
                            throughout Africa in collaboration with Amazon and Access Bank, the
                            largest in Africa by customer base. Our platform is a B2B2C healthcare
                            marketplace, containing the entire healthcare ecosystem.
                        </p>
                        <p>
                            Our GPT4 Applications include a Doctor COPILOT, collaboration among
                            doctors speaking different languages, paper record auto-digitization
                            and query, and a Point of Care App.
                        </p>
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <p className="primary-care-title">
                                    AI-powered One Stop Healthcare Platform Universal Primary Care
                                </p>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="service-card">
                                    <img
                                        src={process.env.PUBLIC_URL + "/assets/images/reduce-cost.png"}
                                        className="icon"
                                        alt=""
                                    />
                                    <div className="title">Reduce Drugs Cost 90%</div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="service-card">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/fake-drugs.png"} className="icon" alt="" />
                                    <div className="title">Eliminate 75% Fakes</div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="service-card">
                                    <img
                                        src={process.env.PUBLIC_URL + "/assets/images/healthcare-cost.png"}
                                        className="icon"
                                        alt=""
                                    />
                                    <div className="title">Healthcare Cost Reduce 45%-55%</div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <p className="font_16 text-blue">
                                        The MSH platform will help deliver proactive affordable
                                        universal primary care, reduce healthcare costs by 45-55%,
                                        decrease drug costs by 90%, eliminate the 75% fake drugs, and
                                        increase the effective doctor/patient ratio to a few per
                                        thousand.
                                    </p>
                                    <p className="font_24 font_500 text-gray">
                                        Our platform will also increase healthcare quality, access, and
                                        utilization.{" "}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="partners-sec mb-3 bg-white">
                        <p className="font_36 font_500 text-blue">MSH PARTNERS </p>
                        <div className="slider">
                            <div className="slide-track">
                                <div className="slide">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/aws.png"} alt="" />
                                </div>
                                <div className="slide">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/Google-Logo.png"} alt="" />
                                </div>
                                <div className="slide">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/AXA_Logo.svg.png"} alt="" />
                                </div>
                                <div className="slide">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/aiico-multishield.jpg"} alt="" />
                                </div>
                                <div className="slide">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/access-logo.png"} alt="" />
                                </div>
                                <div className="slide">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/logo-SECAM.png"} alt="" />
                                </div>
                                <div className="slide">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/Mayo-Clinic-Logo.jpg"} alt="" />
                                </div>
                                <div className="slide">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/aws.png"} alt="" />
                                </div>
                                <div className="slide">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/Google-Logo.png"} alt="" />
                                </div>
                                <div className="slide">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/AXA_Logo.svg.png"} alt="" />
                                </div>
                                <div className="slide">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/aiico-multishield.jpg"} alt="" />
                                </div>
                                <div className="slide">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/access-logo.png"} alt="" />
                                </div>
                                <div className="slide">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/logo-SECAM.png"} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <p className="font_20 text-blue">
                                        Our partners include AWS, and Access Bank who have agreed to
                                        give us their 50 million customers at their sole expense.{" "}
                                    </p>
                                    <p className="font_18 font_500 text-gray">
                                        PARTNERS also include two of the largest HMOs (Axa Mansard and
                                        AIICO Multishield); ECHo Impact Fund, the Catholic Church (via
                                        SECAM) with 16k health facilities and 10k+ doctors. We also have
                                        a chain of 24 pharmacies.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="partners-sec mb-3 bg-white">
                        <p className="font_36 font_500 text-blue mb-5">MSH EXECUTIVE TEAM</p>
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-xl-3 col-md-6 col-6">
                                <div className="teammate">
                                    <div className="teammate-photo">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/team-member1.png"} alt="image" />
                                    </div>
                                    <p className="font_20 text-center mb-1">CLEMENT OKOH</p>
                                    <p className="mb-0 text-blue01 font_500">CEO</p>
                                    {/* <button class="linked-icon">
                            <img src={process.env.PUBLIC_URL +"assets/images/LinkedIn_icon_circle.svg.png" alt="linkedin">
                        </button> */}
                                    <a
                                        href="https://www.linkedin.com/in/clement-okoh-9ba00b14a/"
                                        target="_blank"
                                        className="linked-icon"
                                    >
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/LinkedIn_icon_circle.svg.png"}
                                            alt="linkedin"
                                        />
                                    </a>
                                </div>
                            </div>
                            {/* <div class="col-lg-4 col-xl-3 col-md-6 col-6">
                    <div class="teammate">
                        <div class="teammate-photo">
                            <img src={process.env.PUBLIC_URL +"assets/images/team-member2.png" alt="image">
                        </div>
                        <p class="font_20 text-center mb-1">
                            HADIZA BALA USMAN


                        </p>
                        <p class="mb-0 text-blue01 font_500">BOARD
                        </p>
                    
                        <a href="https://www.linkedin.com/in/hadiza-bala-usman-27390712a/" target="_blank"
                            class="linked-icon">
                            <img src={process.env.PUBLIC_URL +"assets/images/LinkedIn_icon_circle.svg.png" alt="linkedin">
                        </a>
                    </div>
                </div> */}
                            <div className="col-lg-4 col-xl-3 col-md-6 col-6">
                                <div className="teammate">
                                    <div className="teammate-photo">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/team-member3.png"} alt="image" />
                                    </div>
                                    <p className="font_20 text-center mb-1">JOYCE WIGWE</p>
                                    <p className="mb-0 text-blue01 font_500">COO</p>
                                    {/* <button class="linked-icon">
                            <img src={process.env.PUBLIC_URL +"assets/images/LinkedIn_icon_circle.svg.png" alt="linkedin">
                        </button> */}
                                    <a
                                        href="https://www.linkedin.com/in/joyceshynglewigwe/"
                                        target="_blank"
                                        className="linked-icon"
                                    >
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/LinkedIn_icon_circle.svg.png"}
                                            alt="linkedin"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-xl-3 col-md-6 col-6">
                                <div className="teammate">
                                    <div className="teammate-photo">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/team-member4.png"} alt="image" />
                                    </div>
                                    <p className="font_20 text-center mb-1">JAMES BENNETT JR.</p>
                                    <p className="mb-0 text-blue01 font_500">BOARD</p>
                                    {/* <button class="linked-icon">
                            <img src={process.env.PUBLIC_URL +"assets/images/LinkedIn_icon_circle.svg.png" alt="linkedin">
                        </button> */}
                                    <a
                                        href="https://www.linkedin.com/in/jay-bennett-b929698/"
                                        target="_blank"
                                        className="linked-icon"
                                    >
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/LinkedIn_icon_circle.svg.png"}
                                            alt="linkedin"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-xl-3 col-md-6 col-6">
                                <div className="teammate">
                                    <div className="teammate-photo">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/team-member5.png"} alt="image" />
                                    </div>
                                    <p className="font_20 text-center mb-1">LAWRENCE ANGA</p>
                                    <p className="mb-0 text-blue01 font_500">GENERAL COUNSEL</p>
                                    {/* <button class="linked-icon">
                            <img src={process.env.PUBLIC_URL +"assets/images/LinkedIn_icon_circle.svg.png" alt="linkedin">
                        </button> */}
                                    <a
                                        href="https://www.linkedin.com/in/lawrence-fubara-anga-san-fcti-64439717/"
                                        target="_blank"
                                        className="linked-icon"
                                    >
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/LinkedIn_icon_circle.svg.png"}
                                            alt="linkedin"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-xl-3 col-md-6 col-6">
                                <div className="teammate">
                                    <div className="teammate-photo">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/team-member6.png"} alt="image" />
                                    </div>
                                    <p className="font_20 text-center mb-1">JAMES CHAPMAN</p>
                                    <p className="mb-0 text-blue01 font_500">US GENERAL COUNSEL</p>
                                    {/* <button class="linked-icon">
                            <img src={process.env.PUBLIC_URL +"assets/images/LinkedIn_icon_circle.svg.png" alt="linkedin">
                        </button> */}
                                    <a
                                        href="https://www.linkedin.com/in/jimchapman1/"
                                        target="_blank"
                                        className="linked-icon"
                                    >
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/LinkedIn_icon_circle.svg.png"}
                                            alt="linkedin"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-xl-3 col-md-6 col-6">
                                <div className="teammate">
                                    <div className="teammate-photo">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/team-member7.png"} alt="image" />
                                    </div>
                                    <p className="font_20 text-center mb-1">TOMI DAVIES</p>
                                    <p className="mb-0 text-blue01 font_500">CIC</p>
                                    {/* <button class="linked-icon">
                            <img src={process.env.PUBLIC_URL +"assets/images/LinkedIn_icon_circle.svg.png" alt="linkedin">
                        </button> */}
                                    <a
                                        href="https://www.linkedin.com/in/tomidee/?originalSubdomain=uk"
                                        target="_blank"
                                        className="linked-icon"
                                    >
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/LinkedIn_icon_circle.svg.png"}
                                            alt="linkedin"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-xl-3 col-md-6 col-6">
                                <div className="teammate">
                                    <div className="teammate-photo">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/team-member8.png"} alt="image" />
                                    </div>
                                    <p className="font_20 text-center mb-1">PRISHANI SATYAPAL</p>
                                    <p className="mb-0 text-blue01 font_500">ECHO</p>
                                    {/* <button class="linked-icon">
                            <img src={process.env.PUBLIC_URL +"assets/images/LinkedIn_icon_circle.svg.png" alt="linkedin">
                        </button> */}
                                    <a
                                        href="https://www.linkedin.com/in/prishani-satyapal-3573b4a/?originalSubdomain=za"
                                        target="_blank"
                                        className="linked-icon"
                                    >
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/LinkedIn_icon_circle.svg.png"}
                                            alt="linkedin"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="partners-sec mb-3 bg-white">
                        <p className="font_36 font_500 text-blue mb-5">MSH MEDICAL PANEL</p>
                        <div className="row">
                            <div className="col-lg-4 col-xl-4 col-md-6 col-6">
                                <div className="teammate">
                                    <div className="teammate-photo">
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/medical-panel-memeber-1.png"}
                                            alt="image"
                                        />
                                    </div>
                                    <p className="font_20 text-center mb-1 text-uppercase">
                                        Dr. Alexandra Anga
                                    </p>
                                    {/* <button class="linked-icon">
                            <img src={process.env.PUBLIC_URL +"assets/images/LinkedIn_icon_circle.svg.png" alt="linkedin">
                        </button> */}
                                    <a
                                        href="https://www.linkedin.com/in/alexandra-laraba-anga-b7779860/"
                                        target="_blank"
                                        className="linked-icon"
                                    >
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/LinkedIn_icon_circle.svg.png"}
                                            alt="linkedin"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-xl-4 col-md-6 col-6">
                                <div className="teammate">
                                    <div className="teammate-photo">
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/medical-panel-memeber-2.png"}
                                            alt="image"
                                        />
                                    </div>
                                    <p className="font_20 text-center mb-1 text-uppercase">
                                        Dr. Koye Oyerinde
                                    </p>
                                    {/* <button class="linked-icon">
                            <img src={process.env.PUBLIC_URL +"assets/images/LinkedIn_icon_circle.svg.png" alt="linkedin">
                        </button> */}
                                    <a
                                        href="https://www.linkedin.com/in/koyeoyerinde/"
                                        target="_blank"
                                        className="linked-icon"
                                    >
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/LinkedIn_icon_circle.svg.png"}
                                            alt="linkedin"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-xl-4 col-md-6 col-6">
                                <div className="teammate">
                                    <div className="teammate-photo">
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/medical-panel-memeber-3.png"}
                                            alt="image"
                                        />
                                    </div>
                                    <p className="font_20 text-center mb-1 text-uppercase">
                                        Dr. Yvonne Onyike
                                    </p>
                                    {/* <button class="linked-icon">
                            <img src={process.env.PUBLIC_URL +"assets/images/LinkedIn_icon_circle.svg.png" alt="linkedin">
                        </button> */}
                                    <a
                                        href="https://www.linkedin.com/in/yvonne-koyenhi-okoh-md-mph-faafp-0b016b3/"
                                        target="_blank"
                                        className="linked-icon"
                                    >
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/LinkedIn_icon_circle.svg.png"}
                                            alt="linkedin"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-xl-4 col-md-6 col-6">
                                <div className="teammate">
                                    <div className="teammate-photo">
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/medical-panel-memeber-4.png"}
                                            alt="image"
                                        />
                                    </div>
                                    <p className="font_20 text-center mb-1 text-uppercase">
                                        Dr. Ahmed Shelbaya
                                    </p>
                                    {/* <button class="linked-icon">
                            <img src={process.env.PUBLIC_URL +"assets/images/LinkedIn_icon_circle.svg.png" alt="linkedin">
                        </button> */}
                                    <a
                                        href="https://www.linkedin.com/in/ahmed-shelbaya-782b5b61/"
                                        target="_blank"
                                        className="linked-icon"
                                    >
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/LinkedIn_icon_circle.svg.png"}
                                            alt="linkedin"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-xl-4 col-md-6 col-6">
                                <div className="teammate">
                                    <div className="teammate-photo">
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/medical-panel-memeber-5.png"}
                                            alt="image"
                                        />
                                    </div>
                                    <p className="font_20 text-center mb-1 text-uppercase">
                                        Dr. Daniel Lopez-Acuna
                                    </p>
                                    {/* <button class="linked-icon">
                            <img src={process.env.PUBLIC_URL +"assets/images/LinkedIn_icon_circle.svg.png" alt="linkedin">
                        </button> */}
                                    <a
                                        href="https://www.linkedin.com/in/daniel-lopez-acuna-66507014/"
                                        target="_blank"
                                        className="linked-icon"
                                    >
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/LinkedIn_icon_circle.svg.png"}
                                            alt="linkedin"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-xl-4 col-md-6 col-6">
                                <div className="teammate">
                                    <div className="teammate-photo">
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/medical-panel-memeber-6.png"}
                                            alt="image"
                                        />
                                    </div>
                                    <p className="font_20 text-center mb-1 text-uppercase">
                                        Rhonice Burnett
                                    </p>
                                    {/* <button class="linked-icon">
                            <img src={process.env.PUBLIC_URL +"assets/images/LinkedIn_icon_circle.svg.png" alt="linkedin">
                        </button> */}
                                    <a
                                        href="https://www.linkedin.com/in/rhonice-burnett-8876014/"
                                        target="_blank"
                                        className="linked-icon"
                                    >
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/LinkedIn_icon_circle.svg.png"}
                                            alt="linkedin"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="partners-sec mb-3 bg-white">
                        <div className="text-center">
                            <p className="font_36 font_500 text-blue mb-sm-4">
                                THE MONTE SERENO HEALTH STORY
                            </p>
                            <p className="mb-0 font_20 font_500">Osondu – A Cancer Odyssey</p>
                            <p className="mb-1 font_20 font_500">
                                Clement I. Okoh, Ph.D. Founder, Monte Sereno Health
                            </p>
                            <a
                                target="_blank"
                                href="https://www.clementokoh.com"
                                className="font_16 text-blue01 text-decoration-underline mb-3 d-block"
                            >
                                www.clementokoh.com{" "}
                            </a>
                        </div>
                        <div>
                            <p className="mb-0">
                                I founded Monte Sereno Health to transform healthcare in Africa.
                            </p>
                            <p>
                                We intend to move the African healthcare industry onto the web and
                                use technology to bypass the lack of infrastructure.{" "}
                            </p>
                            <p className="font_18 text-blue mb-1 font_500">The Inspiration:</p>
                            <p>
                                I was moved to create MSH after my near-death experience with
                                aggressive multiple myeloma
                            </p>
                            <p>
                                After being misdiagnosed with a muscle strain, I fell in a Lagos
                                hospital on March 20, 2017, fractured my spine at a myeloma tumor
                                lesion, and was paralyzed.{" "}
                            </p>
                            <p>
                                I flew out of Nigeria the same day with a 4 to 5-yr life expectancy
                                and faced 6 potentially fatal complications: Stroke, PE clot, DVT
                                clot. ATA, blood poisoning, and massive internal bleeding.
                            </p>
                            <p>
                                Back in the US, Dr. (Prof) Daniel Sciubba, the Director of Spinal
                                Tumor, Deformity Surgery, and Spinal Oncology at Johns Hopkins
                                University, led his team to remove the tumor and fuse my spine. But
                                they thought I would never walk again. Or even stand up.
                            </p>
                            <p className="mb-0">In his words:</p>
                            <p className="font_600 ps-4">
                                "During your operation, we put transcranial signals down your spine
                                and out your limbs. We got the signals out of your hands, but
                                nothing out of your legs. If I were a betting man, I would have bet
                                everything I had that you will never walk again".
                            </p>
                            <p>
                                But I am now in complete remission and can walk well without a cane.
                            </p>
                            <p className="font_18 text-blue mb-1 font_500">The Covenant - MSH:</p>
                            <p className="mb-0">
                                In November 2019 I published an Amazon genre best-selling memoir
                                about my cancer odyssey, titled “OSONDU” (Osondu is an Igbo language
                                proverb, meaning: “One never tires of the race for life”). The final
                                paragraph in the book's introduction is:
                            </p>
                            <p className="ps-4">
                                “My neurosurgeon, Dr. Daniel Sciubba, said I was blessed. My parish
                                pastor, Fr. Joe Rogers, said I am a walking miracle. After reading
                                this book, you may agree with them. I received a clear message from
                                running this gauntlet, and I am determined to return to Africa to
                                help deliver affordable, high-quality medical care. My efforts will
                                use technology to lower costs and bypass the lack of infrastructure.
                                “
                            </p>
                            <p className="font_16">
                                Monte Sereno Health is the result of the covenant I made during my
                                second day in ICU, when I’d already had last rites, and wasn't sure
                                I would live. This is the company I founded to answer that clear
                                message and transform African Healthcare.
                            </p>
                            <hr />
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </main>
    </>
  )
}

export default StaticAboutUsPage;
