
export function loginRedirectCall(){
    let path;
    if(localStorage.getItem("user_login_type_role") == 1){
      path= window.location.protocol+"//"+window.location.host+"/patient/splash-screen" 
    } else {
      path= window.location.protocol+"//"+window.location.host+"/doctor/splash-screen" 
    }
//    window.open(path,'mywindow').focus()
    window.location.href = path;
  }

export function logOutRedirect(){
    let path= window.location.protocol+"//"+window.location.host+"/" 
    window.location.href = path;
  }
export function logOutRedirectCall(){
    localStorage.removeItem("user_login");
    localStorage.removeItem("user_token");
    localStorage.removeItem("user_name");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_email");
    localStorage.removeItem("user_country_code");
    localStorage.removeItem("user_phone");
    localStorage.removeItem("user_role");
    localStorage.removeItem("user_login_type_role");
    localStorage.removeItem("profile_image");
    logOutRedirect()
  }
 
export function loginStoreData(data){
  localStorage.setItem("user_login",true);
  localStorage.setItem("user_token",data.token);
  localStorage.setItem("user_name",data.first_name);
  localStorage.setItem("user_id",data.id);
  localStorage.setItem("user_email",data.email);
  localStorage.setItem("user_country_code",data.country_code);
  localStorage.setItem("user_phone",data.mobile_number);
  localStorage.setItem("user_role",data.role);
  localStorage.setItem("user_login_type_role",data.login_type_role);
  localStorage.setItem("profile_image",data.profile_image);
  loginRedirectCall()
}
