import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';

function WellnessNutrition() {
  return (
    <>
        <main className="bg-color">
            <Header/>
            <div className="chatbody">
                <div className="chat-main">
                <div className="sideNav1">
                    <li className="active">
                    <i className="fa-regular fa-comment-dots" />
                    </li>
                    <li className="d-none">
                    <i className="fa-solid fa-phone" />
                    </li>
                    <li>
                    <i className="fa-solid fa-gear" />
                    </li>
                    <li>
                    <i className="fa-solid fa-trash-can" />
                    </li>
                    <li>
                    <i className="fa-regular fa-star" />
                    </li>
                    <li className="d-none">
                    <i className="fa-solid fa-address-book" />
                    </li>
                </div>
                <div className="sideNav2">
                    <div className="SideNavhead">
                    <h2 className="community-title">
                        Welcome to the Wellness & Nutrition Support Room
                        <img
                        className="wellness-icon"
                        src={process.env.PUBLIC_URL + "/assets/images/wellness-icon.png"}
                        alt="icon"
                        />
                    </h2>
                    </div>
                    <div className="SearchInputHolder">
                    <i className="fa-solid fa-magnifying-glass" />
                    <input className="searchInput" placeholder="Search For Chat.." />
                    <hr />
                    </div>
                    <div className="avatar-group">
                    <div className="user-avatar">
                        <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="image" />
                    </div>
                    <div className="flex-1">
                        <p className="avatar-name">David Johnson</p>
                        <p className="avatar-msg">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Earujdsajf djf df dfjdkj dlkjfl.kjl dlkjf lkjlkdjfm, sequi.
                        </p>
                    </div>
                    </div>
                    <div className="avatar-group">
                    <div className="user-avatar">
                        <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="image" />
                    </div>
                    <div className="flex-1">
                        <p className="avatar-name">David Johnson</p>
                        <p className="avatar-msg">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Earujdsajf djf df dfjdkj dlkjfl.kjl dlkjf lkjlkdjfm, sequi.
                        </p>
                    </div>
                    </div>
                    <div className="avatar-group">
                    <div className="user-avatar">
                        <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="image" />
                    </div>
                    <div className="flex-1">
                        <p className="avatar-name">David Johnson</p>
                        <p className="avatar-msg">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Earujdsajf djf df dfjdkj dlkjfl.kjl dlkjf lkjlkdjfm, sequi.
                        </p>
                    </div>
                    </div>
                    <div className="avatar-group">
                    <div className="user-avatar">
                        <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="image" />
                    </div>
                    <div className="flex-1">
                        <p className="avatar-name">David Johnson</p>
                        <p className="avatar-msg">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Earujdsajf djf df dfjdkj dlkjfl.kjl dlkjf lkjlkdjfm, sequi.
                        </p>
                    </div>
                    </div>
                    <div className="avatar-group">
                    <div className="user-avatar">
                        <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="image" />
                    </div>
                    <div className="flex-1">
                        <p className="avatar-name">David Johnson</p>
                        <p className="avatar-msg">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Earujdsajf djf df dfjdkj dlkjfl.kjl dlkjf lkjlkdjfm, sequi.
                        </p>
                    </div>
                    </div>
                    <div className="avatar-group">
                    <div className="user-avatar">
                        <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="image" />
                    </div>
                    <div className="flex-1">
                        <p className="avatar-name">David Johnson</p>
                        <p className="avatar-msg">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Earujdsajf djf df dfjdkj dlkjfl.kjl dlkjf lkjlkdjfm, sequi.
                        </p>
                    </div>
                    </div>
                    <div className="avatar-group">
                    <div className="user-avatar">
                        <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="image" />
                    </div>
                    <div className="flex-1">
                        <p className="avatar-name">David Johnson</p>
                        <p className="avatar-msg">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Earujdsajf djf df dfjdkj dlkjfl.kjl dlkjf lkjlkdjfm, sequi.
                        </p>
                    </div>
                    </div>
                    <div className="avatar-group">
                    <div className="user-avatar">
                        <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="image" />
                    </div>
                    <div className="flex-1">
                        <p className="avatar-name">David Johnson</p>
                        <p className="avatar-msg">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Earujdsajf djf df dfjdkj dlkjfl.kjl dlkjf lkjlkdjfm, sequi.
                        </p>
                    </div>
                    </div>
                    <div className="avatar-group">
                    <div className="user-avatar">
                        <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="image" />
                    </div>
                    <div className="flex-1">
                        <p className="avatar-name">David Johnson</p>
                        <p className="avatar-msg">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Earujdsajf djf df dfjdkj dlkjfl.kjl dlkjf lkjlkdjfm, sequi.
                        </p>
                    </div>
                    </div>
                </div>
                <section className="Chat user-chat-show">
                    <div className="ChatHead">
                    <div className="d-block d-lg-none">
                        <Link to="#" className=" user-chat-remove">
                        <i className="fa-solid fa-angle-left" />
                        </Link>
                    </div>
                    <li className="group w-100">
                        <p className="GroupName">MSH Wellness & Nutrition Support Community</p>
                        <Link to="#" className="avatar user-chat-remove">
                        <img src={process.env.PUBLIC_URL + "/assets/images/wellness-icon.png"} alt="image" />
                        </Link>
                    </li>
                    {/* <div className="callGroup">
                        <i className="fa-solid fa-phone"></i>
                        <i className="fa-solid fa-video"></i>

                    </div> */}
                    </div>
                    <div className="MessageContainer" id="chatElement">
                    <span />
                    {/* <div className="messageSeperator">Yesterday</div> */}
                    <div className="message me">
                        <p className="messageContent">Hello!</p>
                        <div className="messageDetails">
                        <div className="messageTime">3:21 PM</div>
                        <i className="fa-solid fa-check" />
                        </div>
                    </div>
                    <div className="message me">
                        <p className="messageContent">How are You!</p>
                        <div className="messageDetails">
                        <div className="messageTime">3:22 PM</div>
                        <i className="fa-solid fa-check" />
                        </div>
                    </div>
                    <div className="message you p-2">
                        <div className="messageContent">
                        <div className="video-card-main">
                            <div className="video-card">
                            <div className="video-card-img">
                                <img src={process.env.PUBLIC_URL + "/assets/images/stress-attendant.jpg"} alt="image" />
                            </div>
                            <p className="mb-0 title">
                                Smooth Health: Stress And It's Attendant Effects | Dr. Anu Martins{" "}
                            </p>
                            </div>
                            <a
                            target="_blank"
                            href="https://www.youtube.com/watch?v=-TA3K3mMjY0"
                            className="video-link"
                            >
                            https://www.youtube.com/watch?v=-TA3K3mMjY0
                            </a>
                        </div>
                        </div>
                        <div className="messageDetails">
                        <div className="messageTime">3:30 PM</div>
                        <i className="fa-solid fa-check" />
                        </div>
                    </div>
                    <div className="message you">
                        <div className="messageContent">
                        <div className="video-card-main">
                            <div className="video-card">
                            <div className="video-card-img">
                                <img src={process.env.PUBLIC_URL + "/assets/images/stress-attendant.jpg"} alt="image" />
                            </div>
                            <p className="mb-0 title">
                                Smooth Health: Living With Asthma | Dr. Anu Martins{" "}
                            </p>
                            </div>
                            <a
                            target="_blank"
                            href="https://www.youtube.com/live/-btc7tvGU_A?si=OiNs6u8AKOA2OkRX"
                            className="video-link"
                            >
                            https://www.youtube.com/live/-btc7tvGU_A?si=OiNs6u8AKOA2OkRX
                            </a>
                        </div>
                        </div>
                        <div className="messageDetails">
                        <div className="messageTime">3:32 PM</div>
                        <i className="fa-solid fa-check" />
                        </div>
                    </div>
                    <div className="message you">
                        <div className="messageContent">
                        <div className="video-card-main">
                            <div className="video-card">
                            <div className="video-card-img">
                                <img src={process.env.PUBLIC_URL + "/assets/images/stress-attendant.jpg"} alt="image" />
                            </div>
                            <p className="mb-0 title">
                                Smooth Health: Healthy Eating In Current Times | Dr. Anu Martins{" "}
                            </p>
                            </div>
                            <a
                            target="_blank"
                            href="https://www.youtube.com/watch?v=IgsnVGMCXgw"
                            className="video-link"
                            >
                            https://www.youtube.com/watch?v=IgsnVGMCXgw
                            </a>
                        </div>
                        </div>
                        <div className="messageDetails">
                        <div className="messageTime">3:32 PM</div>
                        <i className="fa-solid fa-check" />
                        </div>
                    </div>
                    <div className="message you">
                        <div className="messageContent">
                        <div className="video-card-main">
                            <div className="video-card">
                            <div className="video-card-img">
                                <img src={process.env.PUBLIC_URL + "/assets/images/childhood.png"} alt="image" />
                            </div>
                            <p className="mb-0 title">
                                Childhood Obesity - Ifeyinwa Omesiete Child Nutritionist
                            </p>
                            </div>
                            <a
                            target="_blank"
                            href="https://www.dropbox.com/scl/fi/bjucj8meo1wymgu4vpul3/video-output-0344DCBF-F9D2-405C-9822-A48521C69041-3.MOV?rlkey=31phtaase97g7i0hzov566onq&dl=0"
                            className="video-link"
                            >
                            https://www.dropbox.com/scl/fi/bjucj8meo1wymgu4vpul3/video-output-0344DCBF-F9D2-405C-9822-A48521C69041-3.MOV?rlkey=31phtaase97g7i0hzov566onq&amp;dl=0
                            </a>
                        </div>
                        </div>
                        <div className="messageDetails">
                        <div className="messageTime">3:32 PM</div>
                        <i className="fa-solid fa-check" />
                        </div>
                    </div>
                    <div className="message you p-2">
                        <div className="messageContent">
                        <div className="video-card-main">
                            <div className="video-card">
                            <div className="video-card-img video-play">
                                <video width={320} height={240} controls>
                                <source src={process.env.PUBLIC_URL + "/assets/video/part1.MP4"} type="video/mp4" />
                                </video>
                            </div>
                            <p className="mb-0 title">
                                Nutrition by Mrs. Sherese Ijewere of Carib Health Group
                            </p>
                            </div>
                            {/* <a target="_blank" href="https://www.youtube.com/watch?v=-TA3K3mMjY0"
                                                    class="video-link">https://www.youtube.com/watch?v=-TA3K3mMjY0</a> */}
                        </div>
                        </div>
                        <div className="messageDetails">
                        <div className="messageTime">3:30 PM</div>
                        <i className="fa-solid fa-check" />
                        </div>
                    </div>
                    <div className="message you p-2">
                        <div className="messageContent">
                        <div className="video-card-main">
                            <div className="video-card">
                            <div className="video-card-img video-play">
                                <video width={320} height={240} controls>
                                <source src={process.env.PUBLIC_URL + "/assets/video/part2.MP4"} type="video/mp4" />
                                </video>
                            </div>
                            <p className="mb-0 title">
                                Nutrition by Mrs. Sherese Ijewere of Carib Health Group
                            </p>
                            </div>
                            {/* <a target="_blank" href="https://www.youtube.com/watch?v=-TA3K3mMjY0"
                                                    class="video-link">https://www.youtube.com/watch?v=-TA3K3mMjY0</a> */}
                        </div>
                        </div>
                        <div className="messageDetails">
                        <div className="messageTime">3:30 PM</div>
                        <i className="fa-solid fa-check" />
                        </div>
                    </div>

                    </div>
                    <form id="MessageForm">
                    <div className="chat-input-group">
                        <input
                        type="text"
                        placeholder="Enter Message..."
                        id="MessageInput"
                        />
                        <span className="input-group-text p-0" id="basic-addon2">
                        <label htmlFor="formFile" className="form-label mb-0">
                            {" "}
                            <i className="fa-solid fa-paperclip font_18" />
                        </label>
                        <input
                            className="form-control d-none"
                            type="file"
                            id="formFile"
                        />
                        </span>
                    </div>
                    <button className="Send">
                        <i className="fa-solid fa-paper-plane" />
                    </button>
                    </form>
                </section>
                </div>
            </div>
            <Footer/>
        </main>
    </>
  )
}

export default WellnessNutrition;