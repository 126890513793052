import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { myAppoinments } from '../../../api/apiHandler';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';


import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import moment from 'moment-timezone';


function MyAppointments() {
  const navigate = useNavigate();
  const [myAppointmentList, setMyAppointmentList] = useState([]);
  const [totalRecordCount, setTotalRecordCount] = useState(0)
  const [pageNo, setPageNo] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [userTimezone, setUserTimezone] = useState('');


  useEffect(() => {
    // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezone = moment.tz.guess();
    // console.log('timezone: ', timezone);
    setUserTimezone(timezone);
    myAppoinments({ "page": pageNo, "record_count": perPage, "search_text": searchQuery}).then((response) => {
      // Swal.close();
      response = JSON.parse(response)
      if (response.code == process.env.REACT_APP_SUCCESS) {
        setMyAppointmentList(response.data.doctor_data)
        setTotalRecordCount(Math.ceil(response.data.total_record_count / perPage))
      } else {
        setMyAppointmentList([]);
      }
    })

    // Swal.close();
  }, [searchQuery, pageNo]);

  
  const handlePageClick = (event) => {
    setPageNo(event.selected + 1)
  };

  
  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  return (
    <>
      <Header />
      <section className="doctorsec-pad">
        <div className="container">
          <div className="mt-4">
            <p className="font_22 font_600 mb-0">My Appointments </p>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <Link to="/doctor/home" className="font_14">
                    Home 
                    </Link>
                </li>
                <li className="breadcrumb-item active font_14" aria-current="page">My Appointments </li>
              </ol>
            </nav>
          </div>
          <div className="card">
            <div className="card-body doctor-table-card">
              <div className="table-responsive">
              <div className="row justify-content-end">
                <div className="search-bar col-lg-3 col-md-3">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearch}
                  />
                </div>
                <div className="search-bar">
                </div>
              </div>
                <table id="mangeUser" className="table table-hover dataTable js-exportable">
                  <thead>
                    <tr>
                      <th >ID</th>
                      <th >Doctor Name</th>
                      <th >Phone Number</th>
                      <th >Email Address</th>
                      <th >Date Time</th>
                      <th >Request Status</th>
                    </tr>
                  </thead>

                  <tbody>

                    {
                        myAppointmentList && myAppointmentList.map((item, index) => {
                        return (
                          <tr id={item.id} key={index}>
                            <td >
                              {item.id}
                            </td>

                            <td>
                              <h3 className="doc-details">
                                <div className="doc-profile">
                                  <img src={item.doctor_information.profile_image} alt="" />
                                </div>
                                {item.doctor_information.doctor_full_name}
                              </h3>
                            </td>
                            <td >
                              {item.doctor_information.country_code +" "+item.doctor_information.doctor_phone_no}
                            </td>
                            <td >
                              {item.doctor_information.doctor_email}
                            </td>
                            <td >
                              {/* {item.appointment_date+" "+item.appointment_time} */}
                              {/* {moment.utc(item.appointment_date + " " + item.appointment_time).tz(userTimezone).format('DD/MM/YYYY hh:mm A')} */}
                              {moment.tz(`${item.appointment_date} ${item.appointment_time}`, 'YYYY-MM-DD hh:mm A', 'UTC').tz(userTimezone).format('DD/MM/YYYY hh:mm A')}
                            </td>
                            <td >
                                {/* <p className="meeting-status status-ongoing font_600 ">{item.appointment_status}</p> */}
                                <button>
                                  {item.appointment_status === 1 ? (
                                      <p className="meeting-status status-pending font_600 ">Pending</p> 
                                    ) : item.appointment_status === 2 ? (
                                      <p className="meeting-status status-accept font_600 ">Accept</p>
                                    ) : item.appointment_status === 3 ? (
                                      <p className="meeting-status status-reject font_600 ">Reject</p>
                                    ) : item.appointment_status === 4 ? (
                                      <p className="meeting-status status-ongoing font_600 ">Ongoing</p>
                                    ) : item.appointment_status === 5 ? (
                                      <p className="meeting-status status-accept font_600 ">Done</p>
                                    ) : (
                                      <p className="meeting-status status-reject font_600 ">Cancel</p>
                                  )}
                                </button>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=" > "
                  onPageChange={handlePageClick}
                  pageCount={totalRecordCount}
                  previousLabel=" < "
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  disabledClassName="disabled"
                  activeClassName="active"
                  activeLinkClassName="active-link"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default MyAppointments;