import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { forgotPassword } from '../../../api/apiHandler';
import Swal from 'sweetalert2'
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';

function Forgotpassword() {
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter valid email')
      .required('Please enter email')
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const onSubmit = (data) => {
    Swal.fire({
      title: 'Please wait...',
      didOpen: () => {
          Swal.showLoading()
      }
  })
    forgotPassword({ "email": data.email }).then((res) => {
      var response = JSON.parse(res)
      console.log('response', response)
      Swal.close()
      if (response.code == process.env.REACT_APP_SUCCESS) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 3000
        })
        setTimeout(() => {
          // if(localStorage.getItem("user_role") == 1){
          //   navigate("/")
          // } else {
          //   navigate("/")
          // }
          navigate("/")
        }, 3000)

      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      }
    })

  }

  return (
    <>
      <Header />
      <main className="msh--main bg-img ">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-xl-5 col-xxl-5 col-12">
              <form className="form-horizontal m-t-30" onSubmit={handleSubmit(onSubmit)}>
                <div className="login-sec">
                  <div className="logo-size">
                    <img src={process.env.PUBLIC_URL + "/assets/images/logo--a.png"} alt="logo" />
                  </div>
                  <p className="font_26 font_600 text-center mb-md-4 mt-md-3">Forgot Password</p>
                  <p className="text-center">Enter your email address and we will
                    send a verification code to reset your password.</p>
                  <div className="input-group mb-3">
                    <span className="input-group-text ps-0" id="basic-addon1">
                      <img src={process.env.PUBLIC_URL + "/assets/images/icon/mail.png"} alt="mail" />
                    </span>
                    <input type="email" {...register('email')} className="form-control ps-0" id="useremail" placeholder="Enter your email" />
                    <div className="invalid-feedback">{errors.email?.message}</div>
                  </div>
                  <button className="btn btn-primary w-md waves-effect waves-light login-btn w-100 border-0 mb-3" type="submit">Send Mail</button>
                </div>
              </form>
            </div>

          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default Forgotpassword;