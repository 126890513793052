import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SplashScreen = () => {

  const [showFirstSection, setShowFirstSection] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const delay = () => {
      setShowFirstSection(false);

      // Redirect to the home page after the delay
      setTimeout(() => {
        navigate("/patient/home");
      }, 2000);
    };

    const delayTime = 1000;
    const delayTimeout = setTimeout(delay, delayTime);
    return () => clearTimeout(delayTimeout);
  }, []);

  return (
    <div className="splash_screen msh--main h-100">
      {showFirstSection && (
        <section className="h-100">
          <div className="container h-100">
            <div className="row justify-content-center align-items-center h-100">
              <div className="logo">
                <img src={process.env.PUBLIC_URL + "/assets/images/logo--a.png"} alt="Logo" />
              </div>
              <p className="title text-white text-center">One-Stop Healthcare You Can Trust</p>
              <p className="text-white font_25 text-center">&#x2022; AI-Powered &#x2022;</p>
            </div>
          </div>
        </section>
      )}

      {!showFirstSection && (
        <section className="h-100">
          <div className="container h-100">
            <div className="row justify-content-center align-items-center">
              <div className="msh--main bg-blue overflow-hidden" id="loadingDiv">
                <div className="img-load">
                  <img src={process.env.PUBLIC_URL + "/assets/images/image-load.png"} alt="Loading" />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default SplashScreen;