import React, { useEffect, useState,useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { signup, getCountryList, getIsabelCountryList } from '../../../api/apiHandler';
import Swal from 'sweetalert2';
import $, { data } from 'jquery';
import Select from 'react-select';

import 'select2';
import 'select2/dist/css/select2.min.css';

function App() {
  const [role, setRole] = useState('1'); // Initial role state
  const [showLicenseInput, setShowLicenseInput] = useState(false); // State to show/hide license input
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [cpasswordVisible, setCpasswordVisible] = useState(false);
  const [countryList, setCountryList] = useState([])
  const [selectedCountry, setSelectedCountry] = useState("");
  const [isabelCountryList, setIsabelCountryList] = useState([])

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };
  const toggleCpasswordVisibility = () => {
    setCpasswordVisible((prev) => !prev);
  };
  useEffect(() => {
    getCountryList({}).then((response) => {
      response = JSON.parse(response)
      if (response.data.length > 0) {
        setSelectedCountry({
          label: response.data[0].calling_code, 
          value: response.data[0].name
        });
      }
      if (response.code == process.env.REACT_APP_SUCCESS) {
        response.data.map((item)=>{
          const countryListObj = {
              label: item.calling_code, 
              value: item.name,
          }
          setCountryList(prevState => [...prevState, countryListObj]);
          
        })
        // setCountryList(response.data)
      }
    })
    
    getIsabelCountryList({}).then((response) => {
      response = JSON.parse(response)
      console.log('response: ', response);
      if (response.code == process.env.REACT_APP_SUCCESS) {
          setIsabelCountryList(response.data)
      }
      Swal.close()
    })

  }, [])

  // const selectRef = useRef(null);

  // useEffect(() => {
  //   // Initialize select2
  //   $(selectRef.current).select2({
  //     placeholder: 'Search for a country code',
  //     allowClear: true, // Optional: Adds a clear button
  //   });

  //   // If you want to handle changes in React state, you can use the following event
  //   $(selectRef.current).on('change', (e) => {
  //     const selectedValue = e.target.value;
  //     // Handle the selected value in your React state or perform any other actions
  //   });

  //   // Clean up when the component is unmounted
  //   return () => {
  //     $(selectRef.current).select2('destroy');
  //   };
  // }, [countryList]); // Add dependencies if needed

  const handleRoleChange = (event) => {
    setRole(event.target.value);
    setShowLicenseInput(event.target.value === '2' || event.target.value === '3');
  };

  const handleCountryChange = (data) => {
    setSelectedCountry(data);
  }

  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter valid email')
      .required('Please enter email'),
    first_name: Yup.string()
      .required('Please enter first name'),      
    middle_name: Yup.string()
      .required('Please enter middle name'),
    last_name: Yup.string()
      .required('Please enter last name'),
    // country_code: Yup.string()
    //   .required("Please select country code"),
    phone_no: Yup.string()
      .required("Please enter phone number")
      .matches(/^[0-9]+$/, 'Only numbers are allowed for this field')
      .min(7, "Phone number must be at least 7 digits")
      .max(10, "Phone number must be at most 10 digits"),
    isabel_country_name: Yup.string()
      .required("Please select isabel country name"),
    password: Yup.string()
      .required('Please enter password')
      .min(6, 'Password must be at least 6 characters'),
    confirmPassword: Yup.string()
      .required('Please enter confirm password')
      .oneOf([Yup.ref('password'), null], 'Password and confirm password does not match'),
    licenseNumber: Yup.string().when('role', {
      is: (value) => value === '2' || value === '3',
      then: Yup.string().required('Please enter license number'),
      otherwise: Yup.string(),
    }),

  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = (data) => {
    Swal.fire({
      title: 'Please wait...',
      didOpen: () => {
        Swal.showLoading()
      }
    })
    var newData = {
      "first_name": data.first_name,
      "middle_name": data.middle_name,
      "last_name": data.last_name,
      "email": data.email,
      "password": data.password,
      "phone_no": data.phone_no,
      "role": data.role,
      "license_no": data.licenseNumber,
      "country_code": selectedCountry.label,
      "country_id": parseInt(data.isabel_country_name),
      "ip": data.phone_no,
    }
    console.log("newData",newData);
    signup(newData).then((res) => {
      Swal.close()
      var response = JSON.parse(res)
      if (response.code == process.env.REACT_APP_SUCCESS) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 5000
        })

        navigate("/");
      }
      else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      }
    });
  }

  const handleIsableCountry = (e) => {
    console.log(e.target.value);
  }

  return (
    <form className="form-horizontal m-t-30" onSubmit={handleSubmit(onSubmit)} >
      <main className="msh--main bg-img signup-sec">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-xl-6 col-xxl-5 col-12">
              <div className="login-sec">
                <div className="logo-size">
                  <img src={process.env.PUBLIC_URL + "/assets/images/logo--a.png"} alt="logo" />
                </div>
                <p className="font_26 font_600 text-center mb-md-4 mt-md-3">Signup Form</p>
                <div className="input-group mb-3">
                  <span className="input-group-text ps-0" id="basic-addon1">
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/user.png"} alt="mail" />
                  </span>
                  <input type="text" {...register('first_name')} className="form-control ps-0" id="first_name" placeholder="Enter your first name" />
                  <div className="invalid-feedback">{errors.first_name?.message}</div>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text ps-0" id="basic-addon1">
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/user.png"} alt="mail" />
                  </span>
                  <input type="text" {...register('middle_name')} className="form-control ps-0" id="middle_name" placeholder="Enter your middle name" />
                  <div className="invalid-feedback">{errors.middle_name?.message}</div>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text ps-0" id="basic-addon1">
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/user.png"} alt="mail" />
                  </span>
                  <input type="text" {...register('last_name')} className="form-control ps-0" id="last_name" placeholder="Enter your last name" />
                  <div className="invalid-feedback">{errors.last_name?.message}</div>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text ps-0" id="basic-addon1">
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/mail.png"} alt="mail" />
                  </span>
                  <input type="email" {...register('email')} className="form-control ps-0" id="email" placeholder="Enter your email" />
                  <div className="invalid-feedback">{errors.email?.message}</div>
                </div>

                <div className="input-group mb-4">
                  <span className="input-group-text ps-0" id="basic-addon1">
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/phone.png"} alt="lock" />
                  </span>

                  <div className="d-flex flex-1">
                    <div>
                    {/* <select className="form-control" ref={selectRef} {...register('country_code')}>
                        {countryList.map((item, key) => (
                          <option key={key} value={item.calling_code}>
                            {item.calling_code}
                          </option>
                        ))}
                      </select> */}
                      <Select 
                        // {...register('country_code')}
                        value={selectedCountry}
                        aria-labelledby="aria-label"
                        onChange={handleCountryChange}
                        menuPortalTarget={document.body}
                        name="aria-live-color"
                        options={countryList}
                        className="my-custom-select"
                         />
                        {/* <input type="hidden" {...register('country_code')} value={selectedCountry} /> */}
                      <div className="invalid-feedback">{errors.country_code?.message}</div>
                    </div>

                    <div>
                      <input type="text" className="form-control" {...register('phone_no')} id="phone_no" placeholder="Enter your phone number" />
                      <div className="invalid-feedback">{errors.phone_no?.message}</div>
                    </div>

                  </div>


                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text ps-0" id="basic-addon1">
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/map.svg"} alt="mail" style={{height:"24px",width:"24px"}}/>
                  </span>
                  <select className="form-control" {...register('isabel_country_name')}>
                      <option value="">Select Country</option>
                      {isabelCountryList && isabelCountryList.map((item) => (
                          <option key={item.country_name} value={item.country_id}>
                              {item.country_name}
                          </option>
                      ))}
                  </select>
                  <div className="invalid-feedback">{errors.isabel_country_name?.message}</div>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text ps-0" id="basic-addon1">
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/lock.png"} alt="lock" />
                  </span>
                  <input type={passwordVisible ? 'text' : 'password'}  {...register('password')} className="form-control ps-0" id="password" placeholder="Enter your password" />
                  <span className="input-group-text" onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
                    <i className={`fa-solid fa-eye${passwordVisible ? '' : '-slash'}`}></i>
                  </span>
                  <div className="invalid-feedback">{errors.password?.message}</div>
                </div>
                <div className="input-group mb-4">
                  <span className="input-group-text ps-0" id="basic-addon1">
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/lock.png"} alt="lock" />
                  </span>
                  <input type={cpasswordVisible ? 'text' : 'password'} {...register('confirmPassword')} className="form-control ps-0" id="confirmPassword" placeholder="Enter your confirm password" />
                  <span className="input-group-text" onClick={toggleCpasswordVisibility} style={{ cursor: 'pointer' }}>
                    <i className={`fa-solid fa-eye${cpasswordVisible ? '' : '-slash'}`}></i>
                  </span>
                  <div className="invalid-feedback">{errors.confirmPassword?.message}</div>
                </div>

                <div className="mb-4">
                  <p className="mb-2 font_18 font_500">Select role</p>
                  <div className="d-flex gap-3 align-items-center">
                    <div className="form-check d-flex align-items-center gap-2">
                      <input
                        className="form-check-input radio"
                        type="radio"
                        name="role"
                        {...register('role')}
                        id="patient"
                        value="1"
                        checked={role === '1'}
                        onChange={handleRoleChange}
                      />
                      <label className="form-check-label" htmlFor="patient">
                        Patient
                      </label>
                    </div>
                    <div className="form-check d-flex align-items-center gap-2">
                      <input
                        className="form-check-input radio"
                        type="radio"
                        name="role"
                        {...register('role')}
                        id="doctor"
                        value="2"
                        checked={role === '2'}
                        onChange={handleRoleChange}
                      />
                      <label className="form-check-label" htmlFor="doctor">
                        Doctor
                      </label>
                    </div>
                    <div className="form-check d-flex align-items-center gap-2">
                      <input
                        className="form-check-input radio"
                        type="radio"
                        {...register('role')}
                        name="role"
                        id="chemist"
                        value="3"
                        checked={role === '3'}
                        onChange={handleRoleChange}
                      />
                      <label className="form-check-label" htmlFor="chemist">
                        Chemist
                      </label>
                    </div>
                    <div className="invalid-feedback">{errors.role?.message}</div>
                  </div>
                  {showLicenseInput && (
                    <div id="div1" className="mt-2">
                      <div className="input-group">
                        {/* <span className="input-group-text ps-0" id="basic-addon1">
                          <img src={process.env.PUBLIC_URL + "/assets/images/icon/license.png"} alt="License" />
                        </span> */}
                        <div className="form-check ps-0">
                          <input
                            type="text"
                            className="form-control ps-0"
                            {...register('licenseNumber')}
                            name='licenseNumber'
                            id='licenseNumber'
                            placeholder="License number"
                            aria-describedby="basic-addon1"
                          />
                          <div className="invalid-feedback">{errors.licenseNumber?.message}</div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <button className="login-btn w-100 border-0 mb-4" type="submit">Signup</button>
                <div className="form-check mb-3">
                  <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                  <label className="form-check-label font_14" htmlFor="flexCheckChecked">
                    By continuing, you agree to the <Link to="/patient/terms"  target="_blank" className="text-blue">Terms of Service</Link> and <Link to="/patient/policy" target="_blank" className="text-blue">Privacy Policy.</Link>
                  </label>
                </div>
                <p className="text-center font_14 text-dark mb-0"> Already a member?<Link to="/" className="text-blue ps-1">Login here</Link></p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </form>
  );
}

export default App;