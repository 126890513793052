import { axiosClient } from "./apiClient";

export function signup(data){
    return axiosClient.post('auth/signup', data);
}
export function login(data){
    return axiosClient.post('auth/login', data);
}
export function forgotPassword(data){
    return axiosClient.post('auth/forgot-password', data);
}
export function logout(data){
    return axiosClient.get('auth/logout', data);
}
export function getProfile(data){
    return axiosClient.get('auth/get-profile', data);
}
export function editProfile(data){
    return axiosClient.post('auth/update-profile', data);
}
export function getCountryList(data){
    return axiosClient.get('auth/get-countries', data);
}
export function changePassword(data){
    return axiosClient.post('auth/change-password', data);
}
export function home(data){
    return axiosClient.post('auth/home', data);
}
export function verifyToken(data){
    return axiosClient.get('auth/verify-token', data);
}
export function getDoctor(data){
    return axiosClient.post('patient/doctors-list', data);
}
export function searchChatGpt(data){
    return axiosClient.post('gpt/gpt-get-response', data);
}
export function createChatCompletion(data){
    return axiosClient.post('gpt/poc-create-chat-completion', data);
}
export function createChatCompletionForDocsumo(data){
    return axiosClient.post('gpt/docsumo-create-chat-completion', data);
}

export function getManagePage(data){
    return axiosClient.post('auth/get-page-content', data);
}
export function patientfeedback(data){
    return axiosClient.post('feedback/send-patient', data);
}
export function verifyEmail(data){
    return axiosClient.post('auth/verify-email', data);
}

export function doctorAppointment(data){
    return axiosClient.post('patient/doctors-list', data);
}
export function storeDataSitepal(data){
    return axiosClient.post('isabel/ranked-differential-diagnoses-sc', data);
}
export function storeDoctorDataSitepal(data){
    return axiosClient.post('isabel/ranked-differential-diagnoses-pro', data);
}

export function getDoctorSpecialities(data){
    return axiosClient.get('auth/get-doctors-specialties', data);
}
export function editDoctorProfile(data){
    return axiosClient.post('auth/update-doctor-profile', data);
}
export function getDoctorProfile(data){
    return axiosClient.get('auth/get-doctor-profile', data);
}

export function doctorfeedback(data){
    return axiosClient.post('feedback/send-doctor', data);
}
export function prescription(data){
    return axiosClient.post('doctor/add-prescription', data);
}

export function uploadMedicalRecord(data){
    return axiosClient.post('docsumo/store-docsumo-data',data);
}

export function getReportDetails(data){
    return axiosClient.post('isabel/get-generated-reports', data);
}
export function getIsabelCountryList(data){
    return axiosClient.get('isabel/get-countries-sc', data);
}


export function patientAppointmentRequests(data){
    return axiosClient.post('doctor/patient-appointment-requests', data);
}
export function patientMeetingStatus(data){
    return axiosClient.post('doctor/patient-meetings-status', data);
}
export function updateAppoinmentStatus(data){
    return axiosClient.post('doctor/update-appointment-status', data);
}

export function checkUpdateAppoinmentStatus(data){
    return axiosClient.post('patient/check-appointment-status-list', data);
}
export function myAppoinments(data){
    return axiosClient.post('patient/my-appointments', data);
}


export function generateCodes(data){
    return axiosClient.get('patient/generate-permission-codes', data);
}
export function permissionCodeRequestDoctorList(data){
    return axiosClient.post('patient/permission-code-doctors-request-list', data);
}
export function sendPermissionCode(data){
    return axiosClient.post('patient/send-permission-codes', data);
}

export function connectedPatientList(data){
    return axiosClient.post('doctor/connected-patient-list', data);
}
export function sendPermissionCodeRequest(data){
    return axiosClient.post('doctor/send-permission-codes-request', data);
}

export function checkPermissionCodeAccessbility(data){
    return axiosClient.post('patient/check-permission-code-accessibility', data);
}

export function setDoctorAvailability(data){
    return axiosClient.post('doctor/set-availability', data);
}
export function getDoctorAvailability(data){
    return axiosClient.get('doctor/get-availability', data);
}

export function doctorMeeting(data){
    return axiosClient.post('meeting/start-aws-chime-meeting', data);
}
export function joinOtherUserApi(data){
    return axiosClient.post('meeting/create-attendee', data);
}
export function getDownloadChimeConversationGptResponseTextFile(data){
    return axiosClient.post('meeting/get-download-chime-conversation-gpt-response-text-file', data);
}
export function getAwsChimeChatGptResponse(data){
    return axiosClient.post('doctor/get-aws-chime-chat-gpt-response', data);
}
export function findPrescriptionListFromChimeConversation(data){
    return axiosClient.post('doctor/find-prescription-list-from-chime-conversation', data);
}
export function isRxSentForAutoFillPrescription(data){
    return axiosClient.post('doctor/is-rx-sent-for-autofill-prescription', data);
}
export function storeChimePrompt(data){
    return axiosClient.post('doctor/store-chime-prompt', data);
}
export function addChimePromptInConversation(data){
    return axiosClient.post('doctor/add-chime-prompt-in-conversation', data);
}
export function allChemistList(data){
    return axiosClient.get('doctor/chemist-list', data);
}
export function prescriptionSendRx(data){
    return axiosClient.post('doctor/prescription-send-rx', data);
}


export function getPatientReportShareMedical(data){
    return axiosClient.post('doctor/get-patient-report-share-medical', data);
}
export function getPatientReport(data){
    return axiosClient.post('patient/get-patient-report-share-medical', data);
}

export function makeAppoinment(data){
    return axiosClient.post('patient/make-appointment', data);
}
export function checkPermissionCode(data){
    return axiosClient.post('doctor/check-permission-code-expiration', data);
}

export function patientInformation(data){
    return axiosClient.post('doctor/patient-information', data);
}
export function addPrescriptionMedicine(data){
    return axiosClient.post('doctor/add-prescription-medicines', data);
}
export function getMedicineList(data){
    return axiosClient.post('doctor/get-prescription-medicines', data);
}
export function getMedicineDetailsById(data){
    return axiosClient.post('doctor/get-prescription-medicine-details-by-id', data);
}
export function updatePrescriptionMedicine(data){
    return axiosClient.post('doctor/update-prescription-medicine', data);
}
export function updateSafetyQcChecked(data){
    return axiosClient.post('doctor/update-safety-qc-checked-for-prescription', data);
}
export function removePrescriptionMedicine(data){
    return axiosClient.post('doctor/delete-prescription-medicine', data);
}

export function getPatientDocsumoRecordList(data){
    return axiosClient.post('doctor/get-patient-docsumo-record-list', data);
}
export function extractData(data){
    return axiosClient.post('docsumo/extract-data', data);
}
export function getDownloadPatientReportExtractDataTextFile(data){
    return axiosClient.post('docsumo/get-download-patient-report-extract-data-text-file', data);
}
export function getPatientOwnDocsumoRecordList(data){
    return axiosClient.post('patient/get-patient-own-docsumo-record-list', data);
}