import React, { useEffect } from 'react'
import { logout } from '../../../api/apiHandler';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logOutRedirectCall } from '../../patient/common/RedirectPathMange';
import Swal from 'sweetalert2';

function Logout() {   
    useEffect(()=>{
        
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
              Swal.showLoading()
            }
          })
        logout({}).then((res) => {
            
            
            var response = JSON.parse(res);
            if (response.code == process.env.REACT_APP_SUCCESS) {
                Swal.close()
                setTimeout(()=>{
                    logOutRedirectCall()
                },1000)
            }
            else {
                Swal.close()
                toast.error(response.message, {
                    position: "top-center",
                    autoClose: 4000,
                    closeOnClick: true,
                    pauseOnHover: true
                });
            }
        })
    },[])
    
    return (
        <div>
        </div>
    )
}

export default Logout;