import React from 'react';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';

function StaticPrivacyPolicyPage() {
  return (
    <>
        <main className="bg-color">
           <Header/>
            <section className="heading-section">
                <p className=" font_54 text-white font_500 text-center">Privacy Policy</p>
            </section>
            <section className="mb-5">
                <div className="container">
                    <div className="terms-use">
                        <p className="font_500 font_16">
                            Effective Date:&nbsp; August 31, 2021
                        </p>
                        <p className="font_15">
                            Monte Sereno Holdings Corporation (“MSH ,” “we,” “us,” or “our”)
                            recognizes the importance of protecting the privacy of your personal
                            information, and we have prepared this Privacy Policy to provide you
                            with important information about the privacy practices applicable to
                            the MSH Platform and any website or service that links to or refers to
                            this Privacy Policy (collectively, the “Services”).&nbsp; In addition,
                            individually identifiable information that you provide to us for
                            purposes of obtaining medical care from Monte Sereno Health Silva
                            Platform (“SILVA”) (such information is also referred to as “Protected
                            Health Information” or “PHI”) will also be subject to any SILVA Health
                            Insurance Portability and Accountability Act Notice of Privacy
                            Practices (the “HIPAA Notice”), which may be provided in future.&nbsp;
                            The HIPAA Notice describes how SILVA and we can use and share your PHI
                            and also describes your rights with respect to your PHI.&nbsp; To the
                            extent that there is a conflict between this Privacy Policy and the
                            HIPAA Notice with respect to PHI, the HIPAA Notice will prevail.
                        </p>
                        <hr />
                        <div>
                            <p className="font_20 font_500 mb-0">
                                I.&nbsp;COLLECTION OF INFORMATION
                            </p>
                            <p className="text">
                                We may collect the following kinds of information when you use the
                                Services:
                            </p>
                            <p className="text">
                                Information you provide directly to us.&nbsp;For certain activities,
                                such as when you register, use our telemedicine services, subscribe
                                to our alerts, or contact us directly, we may collect some or all of
                                the following types of information:
                            </p>
                            <ul>
                                <li className="font_16">
                                    Contact information, such as your full name, email address, mobile
                                    phone number, and address;Username and password;
                                </li>
                                <li className="font_16">
                                    Payment information, such as your credit card number, expiration
                                    date, and credit card security code;
                                </li>
                                <li className="font_16">
                                    For health care providers, information about your employment, such
                                    as your job title, practice area, primary specialty, and medical
                                    license status, gender, date of birth, languages spoken,
                                    educational background, address, photograph, social security
                                    number, Tax ID, NPI number, professional license information and
                                    bank account information;
                                </li>
                                <li className="font_16">
                                    Personal health information, including information about your
                                    diagnosis, previous treatments, general health, health insurance
                                    and information which you have stored in Apple HealthKit or
                                    similar software (to the extent you choose to synch that data with
                                    our services); and Any other information you provide to us.
                                </li>
                            </ul>
                        </div>
                        <div className="text">
                            <p>
                                We may combine such information with information we already have
                                about you. Information we collect automatically.&nbsp;We may collect
                                certain information automatically when you use our Services, such as
                                your Internet protocol (IP) address, device and advertising
                                identifiers, browser type, operating system, Internet service
                                provider, pages that you visit before and after using the Services,
                                the date and time of your visit, information about the links you
                                click and pages you view within the Services, and other standard
                                server log information.&nbsp; We may also collect certain location
                                information when you use our Services, such as your computer’s IP
                                address, your mobile device’s GPS signal, or information about
                                nearby WiFi access points and cell towers.
                            </p>
                            <p>
                                We may use cookies, pixel tags, Local Shared Objects, and similar
                                technologies to automatically collect this information.&nbsp;
                                Cookies are small bits of information that are stored by your
                                computer’s web browser.&nbsp; Pixel tags are very small images or
                                small pieces of data embedded in images, also known as “web beacons”
                                or “clear GIFs,” that can recognize cookies, the time and date a
                                page is viewed, a description of the page where the pixel tag is
                                placed, and similar information from your computer or device.&nbsp;
                                Local Shared Objects (sometimes referred to as “Flash Cookies”) are
                                similar to standard cookies except that they can be larger and are
                                downloaded to a computer or mobile device by the Adobe Flash media
                                player.&nbsp; By using the Services, you consent to our use of
                                cookies and similar technologies.
                            </p>
                            <p>
                                We may also collect technical data to address and fix technical
                                problems and improve our Services, including the memory state of
                                your device when a system or app crash occurs while using our
                                Services.&nbsp; Your device or browser settings may permit you to
                                control the collection of this technical data.&nbsp; This data may
                                include parts of a document you were using when a problem occurred,
                                or the contents of your communications.&nbsp; By using the Services,
                                you are consenting to the collection of this technical data.
                            </p>
                            <p>
                                Information we obtain from your health care providers and other
                                sources. In connection with your treatment, we may collect medical
                                records from your past, current, and future health care
                                providers.&nbsp; This may include information about your diagnosis,
                                previous treatments, general health, laboratory and pathology test
                                results and reports, social histories, any family history of
                                illness, and records about phone calls and emails related to your
                                illness.
                            </p>
                            <p>
                                We may also receive information about you from other sources,
                                including through third-party services and organizations. We may
                                combine our first-party data, such as your email address or name,
                                with third-party data from other sources and use this to contact you
                                (e.g. through direct mail).&nbsp;For example, if you access
                                third-party services, such as Facebook, Google, or Twitter, through
                                the Services to login to the Services or to share information about
                                your experience on the Services with others, we may collect
                                information from these third-party services.
                            </p>
                        </div>
                        <div>
                            <p className="font_20 font_500 mb-2">II.&nbsp;USE OF INFORMATION</p>
                            <p className="mb-2 text">
                                We generally use the information we collect online to:
                            </p>
                            <ul>
                                <li className=" mb-1">Provide and improve the Services;</li>
                                <li className=" mb-1">Contact you;</li>
                                <li className=" mb-1">
                                    Fulfill your requests for products, services, and information;
                                </li>
                                <li className="mb-1">
                                    Send you information about additional clinical services or general
                                    wellness from us or on behalf of our affiliates and trusted
                                    third-party partners;
                                </li>
                                <li className="mb-1">
                                    Analyze the use of the Services and user data to understand and
                                    improve the Services; Customize the content you see when you use
                                    the Services;
                                </li>
                                <li className=" mb-1">
                                    Conduct research using your information, which may be subject to
                                    your separate written authorization;
                                </li>
                                <li className=" mb-1">
                                    Prevent potentially prohibited or illegal activities and otherwise
                                    in accordance with our Terms of Use and
                                </li>
                                <li className=" mb-1">
                                    For any other purposes disclosed to you at the time we collect
                                    your information or pursuant to your consent.
                                </li>
                            </ul>
                        </div>
                        <div>
                            <p className="font_20 font_500 mb-2">
                                III.&nbsp;SHARING OF INFORMATION
                            </p>
                            <p className="mb-2 text">
                                We are committed to maintaining your trust, and we want you to
                                understand when and with whom we may share the information we
                                collect.
                            </p>
                            <ul>
                                <li className="mb-1">
                                    <span className="font_500">
                                        Healthcare providers, insurance companies, and other
                                        healthcare-related entities:
                                    </span>
                                    &nbsp;We may share your information with other health care
                                    providers, laboratories, government agencies, insurance companies,
                                    organ procurement organizations, medical examiners or funeral
                                    directors, and other entities relevant to providing you with
                                    treatment options and support.
                                </li>
                                <li className="mb-1">
                                    <span className="font_500">
                                        Authorized third-party vendors and service providers:
                                    </span>
                                    &nbsp;We may share your information with third-party vendors and
                                    service providers that help us with specialized services,
                                    including billing, payment processing, customer service, email
                                    deployment, business analytics, marketing (including but not
                                    limited to advertising, attribution, deep-linking, direct-mail,
                                    mobile marketing, optimization and retargeting), performance
                                    monitoring, hosting, and data processing. These third-party
                                    vendors and service providers may not use your information for
                                    purposes other than those related to the services they are
                                    providing to us.
                                </li>
                                <li className="mb-1">
                                    <span className="font_500">HealthKit (or similar software):</span>
                                    &nbsp;If you ask us to, we will share your synched HealthKit
                                    information with your medical provider.&nbsp;&nbsp;We do not use
                                    or disclose HealthKit information for any marketing or advertising
                                    purposes or sell this information to any third-party.
                                </li>
                                <li className="mb-1">
                                    <span className="font_500">Research partners:</span>&nbsp;We may
                                    share your information with our research partners to conduct
                                    health-related research; such sharing may be subject to your
                                    separate written authorization.
                                </li>
                                <li className="mb-1">
                                    <span className="font_500">Corporate affiliates:</span>
                                    We may share your information with our corporate affiliates that
                                    are subject to this policy.
                                </li>
                                <li className="mb-1">
                                    <span className="font_500">Business transfers:</span>&nbsp;We may
                                    share your information in connection with a substantial corporate
                                    transaction, such as the sale of a website, a merger,
                                    consolidation, asset sale, or in the unlikely event of bankruptcy.
                                </li>
                                <li className="mb-1">
                                    <span className="font_500">Legal purposes:</span>&nbsp;We may
                                    disclose information to respond to subpoenas, court orders, legal
                                    process, law enforcement requests, legal claims or government
                                    inquiries, and to protect and defend the rights, interests,
                                    health, safety, and security of MSH, our affiliates, users, or the
                                    public. If we are legally compelled to disclose information about
                                    you to a third party, we will attempt to notify you by sending an
                                    email to the email address in our records unless doing so would
                                    violate the law or unless you have not provided your email address
                                    to us.
                                </li>
                                <li className="mb-1">
                                    <span className="font_500">
                                        With your consent or at your direction:
                                    </span>{" "}
                                    We may share information for any other purposes disclosed to you
                                    at the time we collect the information or pursuant to your consent
                                    or direction.
                                </li>
                            </ul>
                            <p className="text">
                                If you access third-party services, such as Facebook, Google, or
                                Twitter, through the Services to login to the Services or to share
                                information about your experience on the Services with others, these
                                third-party services may be able to collect information about you,
                                including information about your activity on the Site, and they may
                                notify your connections on the third-party services about your use
                                of the Site, in accordance with their own privacy policies.
                            </p>
                            <p className="text">
                                If you choose to engage in public activities on the Site or third
                                party sites that we link to, you should be aware that any
                                information you share there can be read, collected, or used by other
                                users of these areas.&nbsp; You should use caution in disclosing
                                personal information while participating in these areas. We are not
                                responsible for the information you choose to submit in these public
                                areas
                            </p>
                        </div>
                        <div>
                            <p className="font_20 font_500 mb-2">IV.&nbsp;SECURITY</p>
                            <p className="text">
                                We use reasonable measures to help protect information from loss,
                                theft, misuse and unauthorized access, disclosure, alteration and
                                destruction.&nbsp; You should understand that no data storage system
                                or transmission of data over the Internet or any other public
                                network can be guaranteed to be 100 percent secure.&nbsp; Please
                                note that information collected by third parties may not have the
                                same security protections as information you submit to us, and we
                                are not responsible for protecting the security of such information.
                            </p>
                        </div>
                        <div>
                            <p className="font_20 font_500 mb-2">V.&nbsp;YOUR CHOICES</p>
                            <p className="text">
                                You may opt out of receiving general health and wellness or
                                treatment options that may be relevant to you by emailing us at
                                privacy@monteserenohealth.com.&nbsp;{" "}
                            </p>
                            <p className="text">
                                You may be able to refuse or disable cookies by adjusting your web
                                browser settings.&nbsp; Because each web browser is different,
                                please consult the instructions provided by your web browser
                                (typically in the “help” section).&nbsp; Please note that you may
                                need to take additional steps to refuse or disable Local Shared
                                Objects and similar technologies.&nbsp; For example, Local Shared
                                Objects can be controlled through the instructions on
                                Adobe’s&nbsp;Setting Manager page.&nbsp; If you choose to refuse,
                                disable, or delete these technologies, some of the functionality of
                                the Services may no longer be available to you.
                            </p>
                        </div>
                        <div>
                            <p className="font_20 font_500 mb-2">
                                VI.&nbsp;THIRD-PARTY ADVERTISING, LINKS, AND CONTENTS
                            </p>
                            <p className="text">
                                Some of the Services may contain links to content maintained by
                                third parties that we do not control.&nbsp; We allow third parties,
                                including business partners, advertising networks, and other
                                advertising service providers, to collect information about your
                                online activities through cookies, pixels, local storage, and other
                                technologies. These third parties may use this information to
                                display advertisements on our Services and elsewhere online tailored
                                to your interests, preferences, and characteristics.&nbsp; We are
                                not responsible for the privacy practices of these third parties,
                                and the information practices of these third parties are not covered
                                by this Privacy Policy.&nbsp;{" "}
                            </p>
                            <p className="text">
                                Some third parties collect information about users of our Services
                                to provide interest-based advertising on our Services and elsewhere,
                                including across browsers and devices.&nbsp; These third parties may
                                use the information they collect on our Services to make predictions
                                about your interests in order to provide you ads (from us and other
                                companies) across the internet.&nbsp; Some of these third parties
                                may participate in an industry organization that gives users the
                                opportunity to opt out of receiving ads that are tailored based on
                                your online activities.&nbsp; Due to differences between using apps
                                and websites on mobile devices, you may need to take additional
                                steps to disable targeted ad technologies in mobile apps. &nbsp;Many
                                mobile devices allow you to opt out of targeted advertising for
                                mobile apps using the settings within the mobile app or your mobile
                                device. &nbsp;For more information, please check your mobile
                                settings.&nbsp; You also may uninstall our apps using the standard
                                uninstall process available on your mobile device or app
                                marketplace.
                            </p>
                            <p className="text">
                                To opt out of interest-based advertising across browsers and devices
                                from companies that participate in the&nbsp;Digital Advertising
                                Alliance&nbsp;or&nbsp;Network Advertising Initiative&nbsp;opt-out
                                programs, please visit their respective websites.&nbsp; You may also
                                be able to opt out of interest-based advertising through the
                                settings within the mobile app or your mobile device, but your
                                opt-out choice may apply only to the browser or device you are using
                                when you opt out, so you should opt out on each of your browsers and
                                devices if you want to disable all cross-device linking for
                                interest-based advertising. &nbsp;If you opt out, you will still
                                receive ads but they may not be as relevant to you and your
                                interests, and your experience on our Services may be degraded.
                            </p>
                            <p className="text">
                                Do-Not-Track Signals and Similar Mechanisms. Some web browsers
                                transmit “do-not-track” signals to websites. Because of differences
                                in how web browsers incorporate and activate this feature, it is not
                                always clear whether users intend for these signals to be
                                transmitted, or whether they even are aware of them. We currently do
                                not take action in response to these signals.
                            </p>
                        </div>
                        <div>
                            <p className="font_20 font_500 mb-2">VII.&nbsp;INTERNATIONAL USERS</p>
                            <p className="text">
                                We maintain information in the United States of America and in
                                accordance with the laws of the United States and Nigeria, which may
                                not provide the same level of protection as the laws in your
                                jurisdiction.&nbsp; By using the website and providing us with
                                information, you understand and agree that your information may be
                                transferred to and stored on servers located outside your resident
                                jurisdiction and, to the extent you are a resident of a country
                                other than the United States, that you consent to the transfer of
                                such data to the United States for processing by us in accordance
                                with this Privacy Policy.
                            </p>
                        </div>
                        <div>
                            <p className="font_20 font_500 mb-2">VIII.&nbsp;CHILDREN</p>
                            <p className="text">
                                We do not knowingly allow individuals under the age of 18 to create
                                accounts that allow access to our Services.{" "}
                            </p>
                        </div>
                        <div>
                            <p className="font_20 font_500 mb-2">
                                IX.&nbsp;CHANGES TO THE PRIVACY POLICY
                            </p>
                            <p className="text">
                                We may update this Privacy Policy from time to time. When we update
                                the Privacy Policy, we will revise the “Effective Date” date above
                                and post the new Privacy Policy.&nbsp; We recommend that you review
                                the Privacy Policy each time you visit the Services to stay informed
                                of our privacy practices.{" "}
                            </p>
                        </div>
                        <div>
                            <p className="font_20 font_500 mb-2">X.&nbsp;QUESTIONS?</p>
                            <p className="text">
                                If you have any questions about this Privacy Policy or our
                                practices, please email us at privacy@monteserenohealth.com{" "}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </main>
    </>
  )
}

export default StaticPrivacyPolicyPage;
