import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import parse from 'html-react-parser';
import * as Yup from 'yup';
import { createChatCompletion } from '../../../api/apiHandler';
import Swal from 'sweetalert2';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import randtoken from 'rand-token'
import { v4 as uuidv4 } from 'uuid';
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

export default function SearchChatGpt() {

  const location = useParams();
  const [pocType,setPocType] = useState(location.poc_type);
  const [conversation, setConversation] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  console.log('searchValue: ', searchValue);  
  const [optionSelected, setOptionSelected] = useState(false);
  const [uuid, setUuid] = useState('');
  
  const [isNewchat,setIsNewChat] = useState(1);
  const [handleSweetAlert,setHandleSweetAlert] = useState(true);
  const [showListeningModal, setListeningModal] = useState(false);
  const [handleStartStopListening, setStartStopListening] = useState(true);  

  const {  interimTranscript, resetTranscript,transcript, listening } = useSpeechRecognition();

  const validationSchema = Yup.object().shape({
    prompt: Yup.string()
      .required('prompt is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, reset, setValue } = useForm(formOptions);
  const { errors } = formState;

  useEffect(()=>{
    generateUuidIfEmpty()
    // showSweetAlert()
  },[])

  useEffect(() => {
    let timeoutId;
    if (optionSelected) {
      timeoutId = setTimeout(() => {
        if (!searchValue.trim()) {
          Swal.fire({
            title: 'Please request for your requirement',
            icon: 'warning',
          });
        }
      }, 60000);
    }

    return () => clearTimeout(timeoutId);
  }, [searchValue, optionSelected]);

  useEffect(() => {
    if(optionSelected){
      pocCreateChatCompletion("Hello",uuid)
    }
  },[optionSelected])

  const generateUuidIfEmpty = () => {
    if (!uuid) {
      // const newUuid = randtoken.generate(16, "abcdefghijklnmopqrstuvwxyz");
      const newUuid = uuidv4();
      console.log("Generated uuid:", newUuid);
      setUuid(newUuid);
      setTimeout(()=> {
        Swal.fire({
          title: 'Please wait...',
          didOpen: () => {
            Swal.showLoading()
          }
        })
    
        pocCreateChatCompletion("Hello",newUuid)
      },500)
    }
  };

  const showSweetAlert = () => {
    Swal.fire({
      title: `Hello Dr. ${localStorage.getItem("user_name")}, How are you today?`,
      imageUrl: `${process.env.PUBLIC_URL + "/assets/images/logo.png"}`,
      imageWidth: 150,
      imageHeight: 120,
      showCancelButton: true,
      confirmButtonText: "I'm feeling great",
      cancelButtonText: "I'm doing okay",
    }).then((result) => {
      setHandleSweetAlert(false)
      if (result.isConfirmed) {
        handleOptionSelection('Option 1');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        handleOptionSelection('Option 2');
      }
    });
  }

  const handleOptionSelection = (option) => {
    console.log(`User selected option: ${option}`);
    setOptionSelected(true);
    Swal.fire({
      title: 'Please wait...',
      didOpen: () => {
        Swal.showLoading()
      }
    })
  };
  
  const onSubmit = (data) => {
    console.log('data: ', data);
    pocCreateChatCompletion(data.prompt,uuid)
  }
  
  const pocCreateChatCompletion = (prompt,uuid) => {
    Swal.fire({
      title: 'Please wait...',
      didOpen: () => {
        Swal.showLoading()
      }
    })
    createChatCompletion({ "prompt": prompt, "uuid": uuid, "is_new_chat": isNewchat, "type": parseInt(pocType), "login_type_role": parseInt(localStorage.getItem("user_login_type_role"))}).then((res) => {
      console.log('response===>', res)
      var response = JSON.parse(res);
  
      if (response.code == process.env.REACT_APP_SUCCESS) {
        setIsNewChat(0)
        setConversation([]);
        setSearchValue('');
        reset()
        const responseData = response.data.response;
       
        const newMessages = responseData.map(item => {
          if(item.is_new_chat == 1){
            if(handleSweetAlert){
              showSweetAlert()
            }
            // return {}
          } else {
            let messageContent = JSON.parse(item.response);
            
            const newMsgObj = {
              role: messageContent.choices[0].message.role,
              message: messageContent.choices[0].message.content,
              prompt: item.prompt,
              is_new_chat: item.is_new_chat
            }
            setConversation(prevConversation => [...prevConversation, newMsgObj]);
          }
        });
       
      }
      else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      }
      if(!handleSweetAlert){
        Swal.close()
      }
    });
  }

  const openListeningModal = () => {
    setListeningModal(true)
  }

  const closeListeningModal = () => {
    SpeechRecognition.stopListening();
    resetTranscript()
    setStartStopListening(true)
    setListeningModal(false)
    setValue('prompt',searchValue,{shouldValidate : false})
  }

  const handleListening = () => {
    if(handleStartStopListening){
      SpeechRecognition.startListening({ continuous: true, timeout: 5000 });
      setStartStopListening(false)
    } else {
      // SpeechRecognition.stopListening();
      // resetTranscript()
      // setStartStopListening(true)
      closeListeningModal()
    }
  }

  useEffect(() => {
    let timeout;
    if (transcript) {
      console.log('transcript: ', transcript);
      
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setSearchValue(transcript);
      }, 1000); 
    } else {
      console.log("transscrpt functirn calluued");
    }
    return () => clearTimeout(timeout);
  }, [transcript, resetTranscript]);

  return (
    <>
      <Header />
      <section className="search-sec">
        <form className="form-horizontal m-t-30" onSubmit={handleSubmit(onSubmit)} >
          <div className="container">
            <div className="d-flex gap-2">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  <i className="fa-solid fa-magnifying-glass"> </i>
                </span>
                <input type="text" {...register('prompt')} className="form-control" id="prompt" value={searchValue}
                placeholder={parseInt(location.poc_type) == 7 ? "Hello I have a fever and a headache and some fatigue. What might be the problem? what do you need for accurate differential diagnosis?" :
                parseInt(location.poc_type) == 2 ? "Please check the following prescription for interactions and indications. Please revise the prescription to remove interactions and ameliorate severe indications." : 
                 " Send your message..."}
                onChange={(e)=>{setSearchValue(e.target.value)}}/>
                <div className="invalid-feedback">{errors.prompt?.message}</div>
              </div>
              <div className="d-flex gap-2">
                <button className="search-btn" type="submit">
                  <img src={process.env.PUBLIC_URL + "/assets/images/icon/send1.svg"} alt="search" />
                </button>
                <button className="search-btn" type="button" onClick={openListeningModal}>
                  <img src={process.env.PUBLIC_URL + "/assets/images/icon/mdi_microphone.svg"} alt="microphone" />
                </button>
              </div>
            </div>


            <div className="ans-block">
              {conversation.map((entry, index) => (
                <div key={index}>
                  {entry.is_new_chat == 0 ? 
                    <div className="que-block">
                      <img src={localStorage.getItem("profile_image")} alt="profile" className="profile" />
                      <div>
                      <p className='mb-0 font_16 font_600'>You</p>
                      <p className="mb-0 font_16">{entry.prompt}</p>
                      </div>
                    
                    </div> : ""}
                 
                    <div className="ans-sub-block">
                      <div className="bg-[#040404] rounded-lg  mb-2 overflow-auto subblock">
                        <div className='aiicon'>
                        <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} alt="profile" className="profile" />
                        </div>
                        <div>
                        <p className="mb-2 font_16 text-blue font_600">Monte Sereno</p>
                        <pre className="mb-2 text-justify white-space-pre">{parse(entry.message)}</pre>
                        </div>
                      </div>
                    </div>
                </div>
              ))}
            </div>
          </div>
        </form>
      </section>
      <Footer />

      {showListeningModal &&
        (<>
        <div
            className={`modal fade ${showListeningModal ? 'show' : ''}`}
            id="staticBackdrop1"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
            tabIndex="-1"
            style={{ display: showListeningModal ? 'block' : 'none' }}
        >
            <div className="modal-dialog prescription-modal modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header border-0">
                    <h5 className="modal-title" id="staticBackdropLabel">Listening</h5>
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={closeListeningModal}
                    />
                    </div>
                    <div className="modal-body">
                      <div className="text-center">
                        {!handleStartStopListening ? <img src={process.env.PUBLIC_URL + "/assets/images/icon/icons8-sound.gif"} alt="search" /> : ""}
                      </div>
                      {/* <div className="d-flex gap-3">
                        <pre className="mb-2 text-justify white-space-pre">{searchValue}</pre>
                      </div> */}
                    </div>
                    <div className="modal-footer">
                      {/* <button type="button" className="btn btn-secondary" onClick={handleListening}>{handleStartStopListening ? "Start" : "Stop"}</button> */}
                      <button
                        type="button"
                        className="btn submit-btn font_18"
                        onClick={handleListening}
                    >
                        {handleStartStopListening ? "Start" : "Stop"}
                    </button>
                    </div>
                </div>
            </div>           
        </div>
        <div className="modal-backdrop fade show"></div>
        </>)
      }
    </>
  )
}