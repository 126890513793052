import React,{useState,useEffect} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import DayTimePicker from '@mooncake-dev/react-day-time-picker';
import moment from 'moment/moment';
import styled from 'styled-components';
import { makeAppoinment } from '../../../api/apiHandler';
import Swal from 'sweetalert2';

const Container = styled.div`
  width: 475px;
  margin: 1em auto;
  padding: 1em;
  background-color: #fff;
  color: #333;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 2px 4px #00000018;
  @media (max-width: 520px) {
    width: 100%;
  }
`;

function BookAppointment() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isScheduling, setIsScheduling] = useState(false);
    const [isScheduled, setIsScheduled] = useState(false);

    const handleScheduled = (dateTime) => {
        setIsScheduling(true);
        setTimeout(()=>{
            setIsScheduled(true);

        },2000)
        const formattedDate = moment(dateTime).utc().format('YYYY-MM-DD');
        const formattedTime = moment(dateTime).utc().format('hh:mm A');
        patientBookAppointment(id,formattedDate,formattedTime)
    }

    const dateStrings = [
      "2024-01-10T10:00:00.000Z",
      "2024-01-10T10:15:00.000Z",
      "2024-01-10T10:30:00.000Z",
    ];
    
    const timeSlotValidator = (slotTime) => {
      // Assuming you have the API response available
      
      // Convert slotTime to a string in the same format as API response
      const slotTimeString = slotTime.toISOString();
      // console.log("slotTimeString",slotTimeString);
    
      // Check if the slot is in the list of booked slots
      const isSlotBooked = dateStrings.includes(slotTimeString);
      // console.log("isSlotBooked",isSlotBooked); 
      // Return true if the slot is not booked (available)
      return !isSlotBooked;
    };
    

    // const timeSlotValidator = (slotTime) => {
    //     console.log("slotTime---------",slotTime);
    //     const eveningTime = new Date(
    //         slotTime.getFullYear(),
    //         slotTime.getMonth(),
    //         slotTime.getDate(),
    //         18,
    //         0,
    //         0
    //       );
    //       const isValid = slotTime.getTime() > eveningTime.getTime();
    //       return isValid;
    // }

    const patientBookAppointment = (doctor_id,appointment_date,appointment_time) => {
      const bookAppointmentObj = {
        "doctor_id" : parseInt(doctor_id),
        "appointment_date" : appointment_date,
        "appointment_time" : appointment_time,
        "is_schedule" : 0
      }
      console.log("bookAppointmentObj",bookAppointmentObj);
      makeAppoinment(bookAppointmentObj).then((res) => {
        var response = JSON.parse(res)
        console.log("response",response);
        if (response.code == process.env.REACT_APP_SUCCESS) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: response.message,
            toast: true,
            showConfirmButton: false,
            timer: 5000
          })
        }
        else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: response.message,
            toast: true,
            showConfirmButton: false,
            timer: 2000
          })
        }
      });  
    }

  return (
    <>
        <Header/>
            <Container>
                <button className='back-btn' onClick={()=>navigate(-1)}>Back</button>
                <DayTimePicker 
                  timeSlotSizeMinutes={15} 
                  isLoading={isScheduling} 
                  isDone={isScheduled} 
                  timeSlotValidator={timeSlotValidator}
                  onConfirm={handleScheduled} />
            </Container>
        <Footer/> 
    </>
  )
}

export default BookAppointment;
