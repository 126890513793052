import React from 'react';
import { Link } from 'react-router-dom';
import './Error.css'

function PageNotFound() {

    
    return (
        // <div className="text-center mt-5">
        //   <img src={process.env.PUBLIC_URL + "/assets/images/404.webp"} height="800" />
        // </div>
        // <div className='content-page mt-5'>
        //   <div className='content'>
        //     <div className='container-fluid' >
        //       <section className="page_404">
        //         <div className="container">
        //           <div className="row">	
        //             <div className="col-sm-12 ">
        //               <div className="col-sm-10 col-sm-offset-1  text-center">
        //                 <div className="four_zero_four_bg">
        //                   <h1 className="text-center ">404</h1>
        //                 </div>
        //                 <div className="contant_box_404">
        //                   <h3 className="h2">
        //                     Look like you're lost
        //                   </h3>
        //                   <p>the page you are looking for not available</p>
        //                   {/* {localStorage.getItem("user_role") == 1 ? 
        //                     <Link to="/patient/home" className="link_404">Go to Home</Link>:
        //                     <Link to="/doctor/home" className="link_404">Go to Home</Link>
        //                   } */}
        //                   <Link to="/" className="link_404">Go to Home</Link>
        //                 </div>
        //               </div>
        //             </div>
        //           </div>
        //         </div>
        //       </section>
        //     </div>
        //   </div>
        // </div>

        <main className="msh--main bg-img">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6 col-xl-5 col-xxl-5 col-12">
                <div className="login-sec">
                  <div className="logo-size">
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/info.svg"} alt="logo" />
                  </div>
                  <h1 className="font_64 mb-0 text-gray text-center font_600 ">404 </h1>
                  <p className="font_26 font_600 text-center mb-3">
                    Oops! Page not found.
                  </p>
                  <Link to="/" className="login-btn mb-3">
                    Go Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </main>
    );
}


export default PageNotFound;