import { Button, Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useCallback, useState } from 'react';
import usePrevious from "../../../hooks/usePrevious";
import monitor, { ImageValidity, DeviceOrientation, SessionState, HealthMonitorCodes } from '@binah/web-sdk'; // Import the Binah SDK
import Webcam from 'react-webcam';
import StartButton from "../../../components/StartButton";

const YourComponent = () => {
  const [progress, setProgress] = useState(0);
  const [heartRate, setHeartRate] = useState(90);
  const [showModal, setShowModal] = useState(true);
  const [session, setSession] = useState();
  const webcamRef = useRef(null);
  const [sessionState, setSessionState] = useState(null);
  const [vitalSigns, setVitalSigns] = useState(null);
  const [warning, setWarning] = useState(null);
  const [error, setError] = useState(null);
  const [startMeasuring, setStartMeasuring] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMonitorReady, setIsMonitorReady] = useState(false);
  const [enabledVitalSigns, setEnabledVitalSigns] = useState();
  const [offlineMeasurements, setOfflineMeasurements] = useState();
  const prevSessionState = usePrevious(sessionState);
  let processingTime = 120;
  var monitorInitialized = false;


  const updateVitalSigns = useCallback((newVitalSigns) => {
    alert('updateVitalSigns');
    setVitalSigns((prevVitalSigns) => ({
      ...prevVitalSigns,
      ...newVitalSigns,
    }));
  }, []);


  const onVitalSign = useCallback((vitalSign) => {
    alert('onVitalSign');
    console.log({ vitalSign })
    updateVitalSigns(vitalSign);
  }, [updateVitalSigns]);



  const onFinalResults = useCallback((vitalSignsResults) => {
    console.log({ vitalSignsResults })
    alert('onFinalResults');
    updateVitalSigns(vitalSignsResults.results);
  }, [updateVitalSigns]);

  const onError = (errorData) => {
    alert('onError');
    console.log({ errorData })
    setError(errorData);
  };

  const onWarning = (warningData) => {
    alert('onWarning');
    console.log({ warningData })
    if (warningData.code === HealthMonitorCodes.MEASUREMENT_CODE_MISDETECTION_DURATION_EXCEEDS_LIMIT_WARNING) {
      setVitalSigns(null);
    }

    if (warningData.code === HealthMonitorCodes.MEASUREMENT_CODE_UNSUPPORTED_ORIENTATION_WARNING) {
      console.log('unsupported orientation', warningData.code)
    } else {
      setWarning(warningData);
    }
  };

  const onImageData = useCallback((imageValidity) => {
    let message;

    if (imageValidity !== ImageValidity.VALID) {
      switch (imageValidity) {
        case ImageValidity.INVALID_DEVICE_ORIENTATION:
          message = 'Unsupported Orientation';
          break;
        case ImageValidity.TILTED_HEAD:
          message = 'Head Tilted';
          break;
        case ImageValidity.FACE_TOO_FAR: // Placeholder, currently not supported
          message = 'You Are Too Far';
          break;
        case ImageValidity.UNEVEN_LIGHT:
          message = 'Uneven Lighting';
          break;
        case ImageValidity.INVALID_ROI:
        default:
          message = 'Face Not Detected';
      }
      console.log('imageValidity', message)
    }
  }, []);

  const onStateChange = useCallback((state) => {
    setSessionState(state);
    if (state === SessionState.MEASURING) {
      setVitalSigns(null);
    }
  }, []);

  const onEnabledVitalSigns = useCallback((vitalSigns) => {
    setEnabledVitalSigns(vitalSigns);
  }, []);

  const onOfflineMeasurement = useCallback(
    (offlineMeasurements) => {
      console.log({ offlineMeasurements })
      setOfflineMeasurements(offlineMeasurements);
    },
    [],
  );
  const onActivation = useCallback(
    (activationId) => {
      console.log({ activationId })

    },
    []);



  useEffect(() => {
    const totalTime = 120; // Total time in seconds
    const updateInterval = 100; // Update interval in milliseconds
    const steps = totalTime / (updateInterval / 1000);

    let currentStep = 0;

    const intervalId = setInterval(() => {
      currentStep += 1;
      const newProgress = parseFloat((currentStep / steps) * 100).toFixed(2);
      setProgress(newProgress);

      if (currentStep >= steps) {
        clearInterval(intervalId);
      }
    }, updateInterval);

    return () => clearInterval(intervalId);
  }, []);
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    (async () => {
      try {
        if (monitorInitialized) {
          return;
        }
        monitorInitialized = true;
        await monitor.initialize({
          licenseKey: '4B30D7-C110BD-4DCDB6-4D3745-FAAE99-C8DF3B',
          licenseInfo: {
            onEnabledVitalSigns,
            onOfflineMeasurement,
            onActivation,
          },
        });
        console.log(`Initialized monitor`);
        setIsMonitorReady(true);
        setError({ code: -1 });
      } catch (e) {
        console.error('Error initializing HealthMonitor', e);
        setIsMonitorReady(false);
        setError({ code: e.errorCode });
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!isMonitorReady || !processingTime || !webcamRef.current.video) {
          return;
        }
        sessionState === SessionState.ACTIVE && session.terminate();

        const devices = await navigator.mediaDevices.enumerateDevices();

        const cameraDevices = devices.filter(device => device.kind === 'videoinput');
        const cameraDeviceId = cameraDevices.length > 0 ? cameraDevices[0].deviceId : '';

        const options = {
          input: webcamRef.current.video,
          cameraDeviceId: cameraDeviceId,
          processingTime,
          onVitalSign,
          onFinalResults,
          onError,
          onWarning,
          onStateChange,
          orientation: DeviceOrientation.PORTRAIT,
          onImageData,
        };

        const faceSession = await monitor.createFaceSession(options);
        console.log(`Session created`, faceSession);
        setSession(faceSession);
        setError({ code: -1 });
      } catch (e) {
        setError({ code: e.errorCode });
        console.error('Error creating a session', e);
      }
    })();
  }, [processingTime, isMonitorReady]);

  const toggleStartButton = useCallback(() => {
    setIsLoading(true);
    if (sessionState === SessionState.ACTIVE) {
      setStartMeasuring(true);
    } else if (isMeasuring()) {
      setStartMeasuring(false);
    }
  }, [sessionState, setIsLoading, processingTime]);

  useEffect(() => {
    if (isMeasuring()) {
      setIsLoading(false);
    }
    if (
      sessionState === SessionState.ACTIVE &&
      prevSessionState !== sessionState
    ) {
      setStartMeasuring(false);
      setIsLoading(false);
    }
  }, [sessionState]);

  useEffect(() => {
    if (startMeasuring) {
      if (sessionState === SessionState.ACTIVE) {
        session.start();
        setError({ code: -1 });
      }
    } else {
      sessionState === SessionState.MEASURING && session.stop();
    }
  }, [startMeasuring]);

  const isMeasuring = useCallback(
    () => sessionState === SessionState.MEASURING,
    [sessionState],
  );

  return (
    <>
      {showModal && (
        <Modal show={openModal} onHide={closeModal} centered id="modal-emergency" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
          aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content modal-custom">
              <div className="modal-header border-0">
                <h5 className="modal-title text-center" id="staticBackdropLabel"><i
                  className="fa-solid fa-circle-exclamation pe-2"></i>Instructions</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}> </button>
              </div>
              <div className="modal-body pb-0">
                <div className="d-flex justify-content-between">
                  <div className=" text-center d-flex display-column justify-content-center align-items-center">
                    <div className="instruct-detail">
                      <img src={process.env.PUBLIC_URL + "/assets/images/trevel_17.jpg"} alt="" />
                    </div>
                    <p className="mb-0 font_500 font_18 pt-2">No Hat</p>
                  </div>
                  <div className="text-center d-flex display-column justify-content-center align-items-center">
                    <div className="instruct-detail">
                      <img src={process.env.PUBLIC_URL + "/assets/images/glasses_ban.jpg"} alt="" />
                    </div>
                    <p className="mb-0 font_500 font_18 pt-2">No Glasses</p>
                  </div>
                  <div className="text-center d-flex display-column justify-content-center align-items-center">
                    <div className="instruct-detail">
                      <img src={process.env.PUBLIC_URL + "/assets/images/no_mask.jpg"} alt="" />
                    </div>
                    <p className="mb-0 font_500 font_18 pt-2">No Mask</p>
                  </div>
                  <div className="text-center d-flex display-column justify-content-center align-items-center">
                    <div className="instruct-detail well-lit">
                      <img src={process.env.PUBLIC_URL + "/assets/images/bulb.svg"} alt="" />
                    </div>
                    <p className="mb-0 font_500 font_18 pt-2">Well-lit</p>
                  </div>
                </div>
              </div>
              <p className="font_18 text-center pt-4">Please remove your glasses, do not wear a mask or hat, stand in
                a
                well-it area.
                Press the start
                button to read the vitals.</p>
              <div className="modal-footer border-0">
                <button type="button" className="btn modal-button font_500" data-bs-dismiss="modal" onClick={closeModal}> Start Vitals
                  Scan</button>



              </div>
            </div>
          </div>
        </Modal>
      )}
      <main className="msh--main h-100">
        <div className="container-fluid h-100">
          <div className="row align-items-center justify-content-center h-100">
            <div className="col-12">
              <Webcam
                ref={webcamRef}
                id="video"
                muted={true}
                playsInline={true}
              />
              <StartButton
                isLoading={isLoading}
                isMeasuring={isMeasuring()}
                onClick={toggleStartButton}
              />

            </div>
            <div className="col-xl-5 col-lg-5 col-md-8 col-10 p-0 text-center">
              <div className="position-relative">
                <Link to="/doctor/report-generate" className="binah-card">
                  <div className="row align-items-center justify-content-center h-100">
                    <div className="col-3">
                      <p className="font_20 mb-0 heartbpm font_500">
                        <img src={process.env.PUBLIC_URL + "/assets/images/icons8-heart-48.png"} alt="" />
                        {vitalSigns != null
                          ? vitalSigns.heartRate.value || '--'
                          : 'N/A'} <sub className="font_10 font_400">HR</sub><br></br>
                      </p>
                    </div>
                    <div className="col-3">
                      <p className="font_20 mb-0 heartbpm font_500">
                        <img src={process.env.PUBLIC_URL + "/assets/images/icons8-heart-48.png"} alt="" />
                        {vitalSigns != null
                          ? vitalSigns.breathingRate.value || '--'
                          : 'N/A'} <sub className="font_10 font_400">BR</sub><br></br>
                      </p>
                    </div>
                    <div className="col-3">
                      <p className="font_20 mb-0 heartbpm font_500">
                        <img src={process.env.PUBLIC_URL + "/assets/images/icons8-heart-48.png"} alt="" />
                        {vitalSigns != null
                          ? vitalSigns.stress.value || '--'
                          : 'N/A'} <sub className="font_10 font_400">SL</sub><br></br>
                      </p>
                    </div>
                    <div className="col-3">
                      <p className="font_20 mb-0 heartbpm font_500">
                        <img src={process.env.PUBLIC_URL + "/assets/images/icons8-heart-48.png"} alt="" />
                        {vitalSigns != null
                          ? vitalSigns.hrvSdnn.value || '--'
                          : 'N/A'} <sub className="font_10 font_400">SDNN</sub><br></br>
                      </p>
                    </div>
                  </div>
                  <p className="pt-3 text-gray font_500 mb-2 font_16">Stay still for accurate results</p>
                  <img src={process.env.PUBLIC_URL + "/assets/images/heartbeat-5510281-4584163.gif"} className="vital-icon" alt="" />
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${progress}%` }}
                      aria-valuenow={progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {progress}%
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default YourComponent;