import $ from 'jquery';
import 'daterangepicker';
import React,{ useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import AWS from 'aws-sdk';
import Swal from 'sweetalert2';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import { uploadMedicalRecord,getPatientReport } from '../../../api/apiHandler';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from 'moment';

function ShareMedical() {
       
    const [startDate,setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate,setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [recordType,setRecordType] = useState(0);
    console.log('recordType: ', recordType);

    const [uploadPaperRecord, setUploadPaperRecord] = useState(null);
    const [paperRecordImage, setPaperRecordImage] = useState(null);

    const [uploadEFileRecord, setUploadEFileRecord] = useState(null);
    const [eFileRecordImage, setEFileRecordImage] = useState(null);

    const [uploadVoiceCameraRecord, setUploadVoiceCameraRecord] = useState(null);
    const navigate = useNavigate();
    const inputRef = useRef(null);
   

    useEffect(() => {
        // Initialize DateRangePicker after component mount
        $(inputRef.current).daterangepicker({
            opens: 'left'
        }, function(start, end, label) {
            setStartDate(start.format('YYYY-MM-DD'))
            setEndDate(end.format('YYYY-MM-DD'))
            // console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
        });

        // // Ensure to destroy DateRangePicker when component unmounts to avoid memory leaks
        // return () => {
        //     $(inputRef.current).data('daterangepicker').remove();
        // };
    }, []);


    // upload paper record
    const uploadPaperRedordData = async (file,file_path) => {
        const S3_BUCKET = process.env.REACT_APP_AWS_S3_NAME;
        const REGION = process.env.REACT_APP_AWS_S3_REGION;       

        AWS.config.update({
            accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_KEY,
            region: REGION,
          });
          const ext = file.name.split('.').pop()
          const s3 = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
          });

        try {
            const newFileName = new Date().getTime() + '.'+ext;
            const params = {
                Bucket: S3_BUCKET + file_path,
                Key: newFileName,
                Body: file,
                ACL: "public-read",
            };

            return new Promise((resolve, reject) => {
                s3.putObject(params, (err, data) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(newFileName);
                    }
                });
            });
        } catch (error) {
            console.error("Error in UploadPaperRecordData:", error);
        }
    };

    const handlePaperRecord = (e) => {
        const fileExtension = e.target.files[0].name.split('.').pop().toLowerCase();
        if(['jpeg', 'jpg', 'png'].includes(fileExtension)){
            setPaperRecordImage(URL.createObjectURL(e.target.files[0]))
            setUploadPaperRecord(e.target.files[0]);
        } else {
            setPaperRecordImage(null)
        }
    }

    const handleEFile = (e) => {
        const fileExtension = e.target.files[0].name.split('.').pop().toLowerCase();
        if(['jpeg', 'jpg', 'png'].includes(fileExtension)){
            setEFileRecordImage(URL.createObjectURL(e.target.files[0]))
            setUploadEFileRecord(e.target.files[0]);
        } else {
            setEFileRecordImage(null)
        }
    }

    const handleVoiceCameraRecord = (e) => {
        setUploadVoiceCameraRecord(e.target.files[0]);
    }

    // validation for download medical record
    const validationSchema = Yup.object().shape({
        // record_name: Yup.string()
        //     .required("Please enter name of record")
        //     .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field'),
        // permission_code: Yup.string()
        //     .required('Please enter permission code'),
        specific_record: Yup.string()
            .required('Please select specific record'),

    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const handleEmailInput = async () => {
        const email = prompt("Enter your email:");
        if (email === null) return handleEmailInput();
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Please enter a valid email address',
                toast: true,
                showConfirmButton: false,
                timer: 1000
            });
            return handleEmailInput();
        }
        return email;
    };

    const onSubmit = async (data) => {
        let params;
        if(recordType == 3){
            const userEmail = await handleEmailInput()
            if (!userEmail) return;

            params = {
                "record_type": recordType,
                "email": userEmail,
                "start_date": startDate,
                "end_date": endDate,
                "type": parseInt(data.specific_record)
            };
            console.log('params', params);
        } else {

            params = {
                "record_type": recordType,
                "start_date": startDate,
                "end_date": endDate,
                "type": parseInt(data.specific_record)
            }
            console.log('params', params)
        }
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        getPatientReport(params).then((response) => {
            response = JSON.parse(response)
            console.log("response",response);
            if (response.code == process.env.REACT_APP_SUCCESS) {
                Swal.close()
                if(recordType == 3){
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                } else {
                    window.open(response.data.url, '_blank');
                }
            } else {
                Swal.close()
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        }).catch((errors) => {
            Swal.close()
            console.log("errror",errors.response);
            if(errors.response != undefined){
                console.log("enter errr");
                if(errors.response.status == process.env.REACT_APP_NOT_FOUND){
                    Swal.fire({
                        position: 'top-end',
                        icon: 'info',
                        title: 'No data found',
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                }else{
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Something went wrong',
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    }) 
                }
            }
        })
        
    }

    // validation for upload medical record
    const validationSchema2 = Yup.object().shape({
        imageType: Yup.string().required('Please select your image type'),
        paperRecord: Yup.mixed()
            .test(
                'fileType',
                'Invalid file type. Only JPG, JPEG, and PNG files are allowed.',
                (value) => {
                    if (value && value.length > 0) {
                        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
                        return allowedTypes.includes(value[0].type);
                    }
                    // If value is not present (empty), consider it as valid.
                    return true;
                }
            ),

        eFile: Yup.mixed()
            .test(
                'fileType',
                'Invalid file type. Only JPG, JPEG, and PNG files are allowed.',
                (value) => {
                    if (value && value.length > 0) {
                        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
                        return allowedTypes.includes(value[0].type);
                    }
                    // If value is not present (empty), consider it as valid.
                    return true;
                }
            ),

        voiceCameraRecord: Yup.mixed()
            .test(
                'fileType',
                'Invalid file type. Only MP3 and MP4 files are allowed.',
                (value) => {
                    if (value && value.length > 0) {
                        const allowedTypes = ['audio/mp3', 'video/mp4'];
                        return allowedTypes.includes(value[0].type);
                    }
                    // If value is not present (empty), consider it as valid.
                    return true;
                }
            ),

    });

    const formOptions2 = { resolver: yupResolver(validationSchema2) };
    const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 } } = useForm(formOptions2);

    const onSubmit2 = async (data) => {
        if (uploadPaperRecord != null) {
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            await uploadPaperRedordData(uploadPaperRecord,"/docsumo/paper_record").then((paperrecord)=>{
                Swal.close()
                console.log('paperrecordpaperrecord',paperrecord)
                data.paperRecordImage = paperrecord;

            }).catch((e)=>{
                Swal.close()
            });
        }

        if (uploadEFileRecord != null) {
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            await uploadPaperRedordData(uploadPaperRecord,"/docsumo/efile_record").then((efilerecord)=>{
                Swal.close()
                console.log('efilerecordefilerecord',efilerecord)
                data.eFileRecordImage = efilerecord;

            }).catch((e)=>{
                Swal.close()
            });
        }

        if (uploadVoiceCameraRecord != null) {
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            await uploadPaperRedordData(uploadPaperRecord,"/docsumo/voice_camera_record").then((voicecamerarecord)=>{
                Swal.close()
                console.log('voicecamerarecordvoicecamerarecord',voicecamerarecord)
                data.voiceCameraRecordImage = voicecamerarecord;

            }).catch((e)=>{
                Swal.close()
            });
        }

        if(uploadPaperRecord != null || uploadEFileRecord != null || uploadVoiceCameraRecord != null){
            var params = {
                "patient_id": parseInt(localStorage.getItem('user_id')),
                "doctor_id": 0,
                "chemist_id": 0,
                "user_type": parseInt(process.env.REACT_APP_USER_TYPE_PATIENT),
                "paper_record_file": data.paperRecordImage != null ? [data.paperRecordImage] : [],
                "e_file": data.eFileRecordImage != null ? [data.eFileRecordImage] : [],
                "camera_input_file": data.voiceCameraRecordImage != null ? [data.voiceCameraRecordImage] : [],
                "type": data.imageType,
                "file_type": "url",
                // "name_of_record": "abc",
                // "permission_code": "ABCAS123",
            }
            console.log('params', params)
            
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            uploadMedicalRecord(params).then((response) => {
                response = JSON.parse(response)
                if (response.code == process.env.REACT_APP_SUCCESS) {
                    Swal.close()
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setTimeout(() => {
                        navigate(0)
                    }, 2000)
                } else {
                    Swal.close()
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })
        }
        
    }

  return (
    <>
        <main className="flex-column fix-footer">
            <Header />
            <section className="share-medical search-sec">
                <div className="container">
                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                    <button
                        className="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                    >
                        Download Medical Record
                    </button>
                    </li>
                    <li className="nav-item" role="presentation">
                    <button
                        className="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                    >
                        Upload Medical Record
                    </button>
                    </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                    >
                    <div className="feedback-sec">
                        <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="card p-2 p-sm-3">
                            <p className="font_30 font_500 text-blue text-download d-flex align-items-center gap-2 mb-0">
                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/download-icon.svg"} alt="" />
                            Download Medical Records
                            </p>
                            <hr />
                            <div className="row">
                            {/* <div className="col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                <label
                                    htmlFor="exampleFormControlInput1"
                                    className="form-label"
                                >
                                    Name of Record
                                </label>
                                <input
                                    type="text"
                                    {...register('record_name')}
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Enter name of record"
                                />
                                <div className="invalid-feedback">{errors.record_name?.message}</div>
                                </div>
                            </div> */}
                            {/* <div className="col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                <label
                                    htmlFor="exampleFormControlInput1"
                                    className="form-label"
                                >
                                    Permission Code
                                </label>
                                <input
                                    type="text"
                                    {...register('permission_code')}
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Enter permission code"
                                />
                                <div className="invalid-feedback">{errors.permission_code?.message}</div>
                                </div>
                            </div> */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">
                                    Date
                                </label>
                                <input
                                    type="text"
                                    {...register('daterange')}
                                    name="daterange"
                                    className="form-control"
                                    ref={inputRef}
                                />
                                </div>
                            </div>
                            {/* <div className="col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                <label
                                    htmlFor="exampleFormControlInput1"
                                    className="form-label"
                                >
                                    Specific Record if Any
                                </label>
                                <input
                                    type="text"
                                    {...register('specific_record')}
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="I want to download Ai vital signs record from 11/20/23"
                                />
                                <div className="invalid-feedback">{errors.specific_record?.message}</div>
                                </div>
                            </div> */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                <label
                                    htmlFor="exampleFormControlInput1"
                                    className="form-label"
                                >
                                    Specific Record if Any
                                </label>
                                <select
                                className="form-select font_500"
                                id="inputGroupSelect04"
                                aria-label="Example select with button addon"
                                {...register('specific_record')}
                                >
                                <option value="">Select Options</option>
                                <option value="1">Symptoms Checker</option>
                                <option value="2">AI Vital signs</option>
                                </select>
                                <div className="invalid-feedback">{errors.specific_record?.message}</div>
                                </div>
                            </div>
                            </div>
                            <div className="row">
                            <div className="col-lg-12">
                                <div className="appointments">
                                <div className="appointment-list align-items-center">
                                    {/* <div className="profile-info-widget d-flex">
                                    <div className=""></div>
                                    <div>
                                        <h5>AI Vital Signs Record</h5>
                                        <div className="patient-details">
                                        <p>
                                            <span className="font_500">Date: </span>
                                            {moment().format('DD-MM-YYYY')}
                                        </p>
                                        </div>
                                    </div>
                                    </div> */}
                                    <div className="d-flex gap-2 flex-wrap">
                                    <button type="submit" className="connect-btn download" onClick={()=>setRecordType(1)}>
                                        <i className="fa-solid fa-eye pe-2"></i>
                                        View Record</button>
                                    <button type="submit" className="connect-btn" onClick={()=>setRecordType(3)}>
                                        <i className="fa-solid fa-envelope pe-2"></i>
                                        Email Record</button>
                                    <button type="submit" className="connect-btn download" onClick={()=>setRecordType(2)}>
                                        <i className="fa-solid fa-download pe-2" />
                                        Download
                                    </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </form>
                    </div>
                    </div>
                    <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                    >
                    <div className="feedback-sec">
                        <form onSubmit={handleSubmit2(onSubmit2)}>
                        <div className="card p-2 p-sm-3">
                            <p className="font_30 font_500 text-blue text-upload d-flex align-items-center gap-2 mb-0">
                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/upload-icon.svg"} alt="" />
                            Upload Medical Records
                            </p>
                            <hr />
                            <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="mb-4">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Key
                                        Value</label>
                                    <select className="form-select" aria-label="Default select example" name="imageType" {...register2('imageType')}>
                                        <option value="">Select your image type</option>
                                        <option value="others__Kagrz">Key and Value</option>
                                        <option value="others__Kagrz__zByyE__MsuFV">Table Structure</option>
                                    </select>
                                    <div className="invalid-feedback">{errors2.imageType?.message}</div>
                                </div>
                            </div>
                            </div>
                            <div className="row">
                            <div className="col-6 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                <div className=" mb-3">
                                <div className="input--file">
                                    <div className="input-icon">
                                    {paperRecordImage != null ?
                                        <img
                                            src={paperRecordImage}
                                            alt=""
                                        /> : 
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/upload-paper-record.png"}
                                            alt=""
                                        />
                                    }
                                    </div>
                                    <input name="Select File" type="file" className="cursor-pointer" {...register2('paperRecord')} onChange={handlePaperRecord}/>
                                </div>
                                <h6 className="text-center text-blue font_18 font_500 text-uppercase">
                                    Upload paper record
                                </h6>
                                <div className="invalid-feedback">{errors2.paperRecord?.message}</div>
                                </div>
                            </div>
                            <div className="col-6 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                <div className=" mb-3">
                                <div className="input--file">
                                    <div className="input-icon">
                                    {eFileRecordImage != null ? 
                                        <img
                                            src={eFileRecordImage}
                                            alt=""
                                        /> : 
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/upload-efile.png"}
                                            alt=""
                                        />
                                    }
                                    </div>
                                    <input name="Select File" type="file" className="cursor-pointer" {...register2('eFile')} onChange={handleEFile} />
                                </div>
                                <h6 className="text-center text-blue font_18 font_500">
                                    UPLOAD e-File
                                </h6>
                                <div className="invalid-feedback">{errors2.eFile?.message}</div>
                                </div>
                            </div>
                            <div className="col-6 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                <div className=" mb-3">
                                <div className="input--file ">
                                    <div className="input-icon">
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/upload-voice-camera.png"}
                                            alt=""
                                        />
                                    </div>
                                    <input name="Select File" type="file" className="cursor-pointer" {...register2('voiceCameraRecord')} onChange={handleVoiceCameraRecord} />
                                </div>
                                <h6 className="text-center text-blue font_18 font_500">
                                    UPLOAD VOICE/CAMERA RECORD
                                </h6>
                                <div className="invalid-feedback">{errors2.voiceCameraRecord?.message}</div>
                                </div>
                            </div>
                            </div>
                            <div className="row justify-content-center">
                            <div className="col-lg-4">
                                <div className="mb-sm-4 mt-sm-4">
                                <button
                                    type="submit"
                                    className="connect-btn download w-100"
                                >
                                    Upload
                                </button>
                                </div>
                            </div>
                            </div>
                        </div>
                        </form>
                    </div>
                    </div>
                </div>
                </div>
            </section>
           <Footer/>
        </main>
 
    </>
  )
}

export default ShareMedical;