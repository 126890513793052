import React from 'react'
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import { Link } from 'react-router-dom';

function PatientList() {
  return (
    <>
        <main className="bg-color">
            <Header/>
                <section>
                    <div className="container">
                    <div className="mt-4">
                        <p className="font_22 font_600 mb-0">Calendly Appointments </p>
                        <nav style={{ '--bs-breadcrumb-divider': '>' }} aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                            <Link to="#" className="font_14">
                                Home
                            </Link>
                            </li>
                            <li className="breadcrumb-item active font_14" aria-current="page">
                            Calendly Appointments
                            </li>
                        </ol>
                        </nav>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                        <div className="card p-2 p-sm-3 mb-3">
                            <div className="appointments">
                            <div className="appointment-list">
                                <div className="profile-info-widget d-flex gap-3">
                                <div className="icon">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="" />
                                </div>
                                <div>
                                    <h5>Richard Wilson</h5>
                                    <div className="patient-details">
                                    <p>
                                        <i className="far fa-clock" /> 14 Nov 2023, 10.00 AM
                                    </p>
                                    <p>
                                        <i className="fas fa-envelope" /> richard@example.com
                                    </p>
                                    <p className="mb-0">
                                        <i className="fas fa-phone" /> +1 923 782 4575
                                    </p>
                                    </div>
                                </div>
                                </div>
                                <div className="appointment-action">
                                <Link
                                    to="#"
                                    className="btn btn-sm bg-success-light font_16"
                                >
                                    <i className="fas fa-check" /> Accept
                                </Link>
                                <Link
                                    href="#"
                                    className="btn btn-sm bg-danger-light font_16"
                                >
                                    <i className="fas fa-times" /> Cancel
                                </Link>
                                {/* <button className="btn btn-sm bg-success-light font_16">
                                                    <i className="fas fa-check"></i> Accept
                                                </button>
                                                <button className="btn btn-sm bg-danger-light font_16">
                                                    <i className="fas fa-times"></i> Cancel
                                                </button> */}
                                </div>
                            </div>
                            </div>
                            <div className="appointments">
                            <div className="appointment-list">
                                <div className="profile-info-widget d-flex gap-3">
                                <div className="icon">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/patient1.jpg"} alt="" />
                                </div>
                                <div>
                                    <h5>Elsie Gilley</h5>
                                    <div className="patient-details">
                                    <p>
                                        <i className="far fa-clock" /> 12 Nov 2023, 5.00 PM
                                    </p>
                                    <p>
                                        <i className="fas fa-envelope" />{" "}
                                        charlenereed@example.com
                                    </p>
                                    <p className="mb-0">
                                        <i className="fas fa-phone" /> +1 828 632 9170
                                    </p>
                                    </div>
                                </div>
                                </div>
                                <div className="appointment-action">
                                <Link
                                    to="#"
                                    className="btn btn-sm bg-success-light font_16"
                                >
                                    <i className="fas fa-check" /> Accept
                                </Link>
                                <Link
                                    to="#"
                                    className="btn btn-sm bg-danger-light font_16"
                                >
                                    <i className="fas fa-times" /> Cancel
                                </Link>
                                {/* <button className="btn btn-sm bg-success-light font_16">
                                                    <i className="fas fa-check"></i> Accept
                                                </button>
                                                <button className="btn btn-sm bg-danger-light font_16">
                                                    <i className="fas fa-times"></i> Cancel
                                                </button> */}
                                </div>
                            </div>
                            </div>
                            <div className="appointments">
                            <div className="appointment-list">
                                <div className="profile-info-widget d-flex gap-3">
                                <div className="icon">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/patient2.jpg"} alt="" />
                                </div>
                                <div>
                                    <h5>Richard Wilson</h5>
                                    <div className="patient-details">
                                    <p>
                                        <i className="far fa-clock" /> 14 Nov 2023, 10.00 AM
                                    </p>
                                    <p>
                                        <i className="fas fa-envelope" /> richard@example.com
                                    </p>
                                    <p className="mb-0">
                                        <i className="fas fa-phone" /> +1 923 782 4575
                                    </p>
                                    </div>
                                </div>
                                </div>
                                <div className="appointment-action">
                                <Link
                                    to="#"
                                    className="btn btn-sm bg-success-light font_16"
                                >
                                    <i className="fas fa-check" /> Accept
                                </Link>
                                <Link
                                    to="#"
                                    className="btn btn-sm bg-danger-light font_16"
                                >
                                    <i className="fas fa-times" /> Cancel
                                </Link>
                                {/* <button className="btn btn-sm bg-success-light font_16">
                                                    <i className="fas fa-check"></i> Accept
                                                </button>
                                                <button className="btn btn-sm bg-danger-light font_16">
                                                    <i className="fas fa-times"></i> Cancel
                                                </button> */}
                                </div>
                            </div>
                            </div>
                            <div className="appointments">
                            <div className="appointment-list">
                                <div className="profile-info-widget d-flex gap-3">
                                <div className="icon">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="" />
                                </div>
                                <div>
                                    <h5>Richard Wilson</h5>
                                    <div className="patient-details">
                                    <p>
                                        <i className="far fa-clock" /> 14 Nov 2023, 10.00 AM
                                    </p>
                                    <p>
                                        <i className="fas fa-envelope" /> richard@example.com
                                    </p>
                                    <p className="mb-0">
                                        <i className="fas fa-phone" /> +1 923 782 4575
                                    </p>
                                    </div>
                                </div>
                                </div>
                                <div className="appointment-action">
                                <Link
                                    to="#"
                                    className="btn btn-sm bg-success-light font_16"
                                >
                                    <i className="fas fa-check" /> Accept
                                </Link>
                                <Link
                                    to="#"
                                    className="btn btn-sm bg-danger-light font_16"
                                >
                                    <i className="fas fa-times" /> Cancel
                                </Link>
                                {/* <button className="btn btn-sm bg-success-light font_16">
                                                    <i className="fas fa-check"></i> Accept
                                                </button>
                                                <button className="btn btn-sm bg-danger-light font_16">
                                                    <i className="fas fa-times"></i> Cancel
                                                </button> */}
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </section>
            <Footer/>
        </main>
    </>

  )
}

export default PatientList;
