import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/header/Header'
import Footer from '../../../components/footer/Footer'
import Swal from 'sweetalert2';
// import randtoken from 'rand-token';
import { v4 as uuidv4 } from 'uuid';

function DoctorOptions() {

  const navigate = useNavigate();

  const handleFinished = () => {
    Swal.fire({
        icon: 'question',
        title: 'Are you sure you want to start the Vital Signs App ?',
        showCancelButton: true,
        confirmButtonText: 'Yes, Start',
        cancelButtonText: 'Cancel',
      }).then(async (result) => {
        if (result.isConfirmed) {
            // const uuid = randtoken.generate(16, "abcdefghijklnmopqrstuvwxyz");
            const uuid = uuidv4();
            console.log("Generated uuid:", uuid);
            window.location.href = `${process.env.REACT_APP_BINAH_WEB_URL}${uuid}_${localStorage.getItem("user_id")}`
        } else {
            navigate('/patient/share-medical')
        }
      });
  }

  return (
    <>
        <main className="bg-color flex-column">
            <Header/>
                <section className="options-sec share-medical">
                    <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-6 mb-3" onClick={() => navigate('/patient/isabel')}>
                        <div className="card option-card">
                            <div className="card-body card1">
                            <div className="hexagon">
                                <img
                                className="icon"
                                src={process.env.PUBLIC_URL + "/assets/images/icon/health-care-love.svg"}
                                alt="image"
                                />
                            </div>
                            Doc. Isabel
                            </div>
                        </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-6 mb-3" onClick={() => navigate('/patient/ai-doctor-2')}>
                        <div className="card option-card">
                            <div className="card-body card2">
                            <div className="hexagon">
                                <img
                                className="icon"
                                src={process.env.PUBLIC_URL + "/assets/images/icon/user-doctor.svg"}
                                alt="image"
                                />
                            </div>
                            Doc. Aiva
                            </div>
                        </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-6 mb-3" onClick={() => navigate('/patient/ai-doctor-3')}>
                        <div className="card option-card">
                            <div className="card-body card3">
                            <div className="hexagon">
                                <img
                                className="icon"
                                src={process.env.PUBLIC_URL + "/assets/images/icon/healthcare.svg"}
                                alt="image"
                                />
                            </div>
                            AI doctor 3
                            </div>
                        </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-6 mb-3" onClick={handleFinished}>
                        <div className="card option-card">
                            <div className="card-body card4">
                            <div className="hexagon">
                                <img
                                className="icon"
                                src={process.env.PUBLIC_URL + "/assets/images/icon/check.png"}
                                alt="image"
                                />
                            </div>
                            Vital Signs
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </section>
            <Footer/>
        </main>
    </>
  )
}

export default DoctorOptions;