import React, { useState, useEffect} from 'react';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';

import { Link,useLocation,useNavigate } from 'react-router-dom';
import { allChemistList, prescriptionSendRx, updateAppoinmentStatus } from '../../../api/apiHandler';

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';

function ChemistList() {

    const navigate = useNavigate();
    const location = useLocation();
    const sendPrescriptionData = location.state;
    const [sendRxData,setSendRxData] = useState(sendPrescriptionData);
    const [chemistList, setChemistList] = useState([]);
    const [totalRecordCount, setTotalRecordCount] = useState(0)
    const [pageNo, setPageNo] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        if(searchQuery == ''){
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading();
                }
            });
        }

        allChemistList({ "page": pageNo, "record_count": perPage, "search_text": searchQuery }).then((response) => {
            Swal.close();
            response = JSON.parse(response)
            console.log("response",response);
            if (response.code == process.env.REACT_APP_SUCCESS) {
                setChemistList(response.data)
                setTotalRecordCount(Math.ceil(response.data.total_record_count / perPage))
            } else {
                setChemistList([]);
            }
        })
        Swal.close();
    }, [pageNo,searchQuery]);
    
    const handlePageClick = (event) => {
        setPageNo(event.selected + 1)
    };

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
    };

    const handleMeetingStatus = () => {

        const updateStatusObj = {
            "appointment_id" : sendRxData.appointment_id,
            "appointment_status" : process.env.REACT_APP_MEETING_DONE
        }
    
        updateAppoinmentStatus(updateStatusObj).then(async(response) => {
            response = JSON.parse(response)
            if (response.code == process.env.REACT_APP_SUCCESS) {
              setTimeout(()=>{
                navigate('/doctor/home');
              },500)
            } else {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: response.message,
                toast: true,
                showConfirmButton: false,
                timer: 2000
              })
            }
        })
    }

    const handleSendRx = (chemist_data) => {
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading();
            }
        });
        const sendRxObj = {
            "prescription_id":sendRxData.prescription_id,
            "patient_id":sendRxData.patient_id,
            "chemist_id": chemist_data.id
        }
        prescriptionSendRx(sendRxObj).then((response) => {
            response = JSON.parse(response)
            Swal.close();
            if (response.code == process.env.REACT_APP_SUCCESS) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                handleMeetingStatus()
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }



  return (
    <main className="bg-color">
        <Header/>
            <section className="share-medical">
                <div className="container ">
                    <div>
                    <p className="font_22 font_600 mb-0">Chemists</p>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/doctor/home" className="font_14">
                            Home
                            </Link>
                        </li>
                        <li
                            className="breadcrumb-item active font_14"
                            aria-current="page"
                        >
                            Chemists
                        </li>
                        </ol>
                    </nav>
                    </div>
                    <div className="card">
                        <div className="card-body doctor-table-card">
                        <div className="table-responsive">
                        <div className="row justify-content-end">
                        <div className="search-bar col-lg-3 col-md-3">
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Search..."
                                value={searchQuery}
                                onChange={handleSearch}
                            />
                        </div>
                            
                        </div>
                            
                            <table id="mangeUser" className="table table-hover dataTable js-exportable">
                            <thead>
                                <tr>
                                <th >ID</th>
                                <th >Chemist Name</th>
                                <th >Phone Number</th>
                                <th >Email Address</th>
                                <th >Action</th>
                                </tr>
                            </thead>

                            <tbody>    
                                {
                                    chemistList && chemistList.map((item, index) => {
                                    return (
                                    <tr id={item.id} key={index}>
                                        <td >
                                        {item.id}
                                        </td>

                                        <td>
                                        <h3 className="doc-details">
                                            <div className="doc-profile">
                                            <img src={item.profile_image} alt="" />
                                            </div>
                                            {item.full_name}
                                            {/* {item.first_name} {item.middle_name} {item.last_name} */}
                                        </h3>
                                        </td>
                                        <td >
                                        {item.country_code + " " + item.phone_no}
                                        </td>
                                        <td >
                                        {item.email}
                                        </td>
                                        <td >
                                            <button className="connect-btn w-fit-content" onClick={()=>handleSendRx(item)}>Select</button>
                                        </td>
                                    </tr>
                                    )
                                })
                                }
                            </tbody>
                            </table>
                        
                            <ReactPaginate
                            breakLabel="..."
                            nextLabel=" > "
                            onPageChange={handlePageClick}
                            pageCount={totalRecordCount}
                            previousLabel=" < "
                            renderOnZeroPageCount={null}
                            containerClassName="pagination"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            disabledClassName="disabled"
                            activeClassName="active"
                            activeLinkClassName="active-link"
                            />
                        
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        <Footer/>
    </main>
  )
}

export default ChemistList;