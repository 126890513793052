import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getPatientOwnDocsumoRecordList, extractData, getDownloadPatientReportExtractDataTextFile } from '../../../api/apiHandler';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import moment from 'moment-timezone';
import { data } from 'jquery';



function DocsumoResults() {
  const navigate = useNavigate();
  const [docsumoResults, setDocsumoResults] = useState([]);
  const [totalRecordCount, setTotalRecordCount] = useState(0)
  const [pageNo, setPageNo] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [userTimezone, setUserTimezone] = useState('');


  useEffect(() => {
    // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezone = moment.tz.guess();
    setUserTimezone(timezone);
    docsumoResultList()
  }, [searchQuery, pageNo]);

  const docsumoResultList = () => {
    if(searchQuery == ''){
      Swal.fire({
        title: 'Please wait...',
        didOpen: () => {
            Swal.showLoading();
        }
      });
    }
    patientDocsumoRecordList()
  }

  const patientDocsumoRecordList = () => {
    getPatientOwnDocsumoRecordList({ "page": pageNo, "record_count": perPage, "search_text": searchQuery}).then((response) => {
      Swal.close();
      response = JSON.parse(response)
      console.log("response",response);
      if (response.code == process.env.REACT_APP_SUCCESS) {
        setDocsumoResults(response.data.docsumo_data)
        setTotalRecordCount(Math.ceil(response.data.total_record_count / perPage))
      } else {
        setDocsumoResults([]);
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      }
    }).catch((error)=>{
      console.log('error: ', error.response.status);
      if(error.response.status == process.env.REACT_APP_NOT_FOUND){
        setDocsumoResults([]);
        Swal.fire({
            position: 'top-end',
            icon: 'info',
            title: 'No data found!',
            toast: true,
            showConfirmButton: false,
            timer: 2000
        })
      } else {
        setDocsumoResults([]);
          Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Something went wrong',
              toast: true,
              showConfirmButton: false,
              timer: 2000
          })
      }
    })
  }

  
  const handlePageClick = (event) => {
    setPageNo(event.selected + 1)
  };

  
  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  const handleExtractData = (data) => {
    
    Swal.fire({
      title: 'Please wait...',
      didOpen: () => {
          Swal.showLoading();
      }
    });
    const extractDataObj = {
      doc_id : data.doc_id
    }
    extractData(extractDataObj).then((response) => {
      Swal.close();
      response = JSON.parse(response)
      console.log("response",response);
      if (response.code == process.env.REACT_APP_SUCCESS) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
        patientDocsumoRecordList()
      } else if (response.code == process.env.REACT_APP_PROCESSING) {
        Swal.fire({
          position: 'top-end',
          icon: 'info',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      } else if (response.code == process.env.REACT_APP_NOT_FOUND) {
        Swal.fire({
          position: 'top-end',
          icon: 'info',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      }
    })
  }

  const handleChatGpt = (data) => {
    const docsumoDataObj = {
      "doctor_id" : data.doctor_id,
      "patient_id" : data.patient_id,
      "doc_id": data.doc_id
    }
    navigate('/patient/chatgpt',{ state : docsumoDataObj })
  }

  const handleDownloadAndView = (type,data) => {
    const downloadOrViewObj = {
      doc_id : data.doc_id
    }

    getDownloadPatientReportExtractDataTextFile(downloadOrViewObj).then((response) => {
      response = JSON.parse(response)
      console.log("response",response);
      if (response.code == process.env.REACT_APP_SUCCESS) {
        // if(type === "download"){
        // } else if (type === "view"){
          
        // }
        window.open(response.data.url, '_blank');
        
      } else if (response.code == process.env.REACT_APP_NOT_FOUND) {
        Swal.fire({
          position: 'top-end',
          icon: 'info',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      }
    })
  }

  return (
    <>
      <Header />
      <section className="doctorsec-pad">
        <div className="container">
          <div className="mt-4">
              <p className="font_22 font_600 mb-0">OCR</p>
              <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                  <Link to="/" className="font_14">
                      Home
                  </Link>
                  </li>
                  <li className="breadcrumb-item active font_14" aria-current="page">
                  OCR
                  </li>
              </ol>
              </nav>
          </div>
          <div className="card">
            <div className="card-body doctor-table-card">
              <div className="table-responsive">
                <div className="row justify-content-end">
                  <div className="search-bar col-lg-3 col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={handleSearch}
                    />
                  </div>
                </div>
                <div className="search-bar">
                </div>
                <table id="mangeUser" className="table table-hover dataTable js-exportable">
                  <thead>
                    <tr>
                      <th >ID</th>
                      <th >Patient Name</th>
                      <th >Phone Number</th>
                      <th >Email Address</th>
                      <th >Date Time</th>
                      <th >Is Extracted</th>
                      <th >Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {docsumoResults && docsumoResults.map((item, index) => {
                      return (
                          <tr id={item.id} key={index}>
                            <td >
                              {item.id}
                            </td>

                            <td>
                              <h3 className="doc-details">
                                <div className="doc-profile">
                                  <img src={item.patient_information.profile_image} alt="" />
                                </div>
                                 {item.patient_information.first_name} {item.patient_information.middle_name} {item.patient_information.last_name}
                              </h3>
                            </td>
                            <td >
                              {item.patient_information.country_code + " " + item.patient_information.phone_no}
                            </td>
                            <td >
                              {item.patient_information.email}
                            </td>
                            <td >
                              {moment.utc(item.createdAt).tz(userTimezone).format('DD/MM/YYYY hh:mm A')}
                            </td>
                            <td>
                              <button className={item.is_extacted == 1 ? `disable-chatbutton flex-1` : `chatbutton flex-1`} disabled={item.is_extacted == 1} onClick={()=>handleExtractData(item)}>
                                Extract
                              </button>
                            </td>
                            <td>
                              <button className={item.is_extacted == 0 ? `disable-chatbutton flex-1` : `chatbutton flex-1`} disabled={item.is_extacted == 0} onClick={()=>handleDownloadAndView("download",item)} >
                                <i className="fa-solid fa-download"></i>
                              </button>
                              <button className={item.is_extacted == 0 ? `disable-chatbutton flex-1` : `chatbutton flex-1`} disabled={item.is_extacted == 0} onClick={()=>handleDownloadAndView("view",item)} >
                                <i className="fa-solid fa-eye"></i>
                              </button>
                              <button className={item.is_extacted == 0 ? `disable-chatbutton flex-1` : `chatbutton flex-1`} disabled={item.is_extacted == 0} onClick={()=>handleChatGpt(item)} >
                                <i className="fa-solid fa-paper-plane"></i>
                              </button>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=" > "
                  onPageChange={handlePageClick}
                  pageCount={totalRecordCount}
                  previousLabel=" < "
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  disabledClassName="disabled"
                  activeClassName="active"
                  activeLinkClassName="active-link"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default DocsumoResults;
