import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';

function MentalHealthCommunity() {
  return (
    <>
        <main className="bg-color">
            <Header/>
            <div className="chatbody">
                <div className="chat-main">
                <div className="sideNav1">
                    <li className="active">
                    <i className="fa-regular fa-comment-dots" />
                    </li>
                    <li className="d-none">
                    <i className="fa-solid fa-phone" />
                    </li>
                    <li>
                    <i className="fa-solid fa-gear" />
                    </li>
                    <li>
                    <i className="fa-solid fa-trash-can" />
                    </li>
                    <li>
                    <i className="fa-regular fa-star" />
                    </li>
                    <li className="d-none">
                    <i className="fa-solid fa-address-book" />
                    </li>
                </div>
                <div className="sideNav2">
                    <div className="SideNavhead">
                    <h2 className="community-title">
                        Welcome to the Mental Health Support Room
                        <img
                        className="wellness-icon"
                        src={process.env.PUBLIC_URL + "/assets/images/mental-health-icon.png"}
                        alt="icon"
                        />
                    </h2>
                    </div>
                    <div className="SearchInputHolder">
                    <i className="fa-solid fa-magnifying-glass" />
                    <input className="searchInput" placeholder="Search For Chat.." />
                    <hr />
                    </div>
                    <div className="avatar-group">
                    <div className="user-avatar">
                        <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="image" />
                    </div>
                    <div className="flex-1">
                        <p className="avatar-name">David Johnson</p>
                        <p className="avatar-msg">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Earujdsajf djf df dfjdkj dlkjfl.kjl dlkjf lkjlkdjfm, sequi.
                        </p>
                    </div>
                    </div>
                    <div className="avatar-group">
                    <div className="user-avatar">
                        <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="image" />
                    </div>
                    <div className="flex-1">
                        <p className="avatar-name">David Johnson</p>
                        <p className="avatar-msg">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Earujdsajf djf df dfjdkj dlkjfl.kjl dlkjf lkjlkdjfm, sequi.
                        </p>
                    </div>
                    </div>
                    <div className="avatar-group">
                    <div className="user-avatar">
                        <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="image" />
                    </div>
                    <div className="flex-1">
                        <p className="avatar-name">David Johnson</p>
                        <p className="avatar-msg">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Earujdsajf djf df dfjdkj dlkjfl.kjl dlkjf lkjlkdjfm, sequi.
                        </p>
                    </div>
                    </div>
                    <div className="avatar-group">
                    <div className="user-avatar">
                        <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="image" />
                    </div>
                    <div className="flex-1">
                        <p className="avatar-name">David Johnson</p>
                        <p className="avatar-msg">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Earujdsajf djf df dfjdkj dlkjfl.kjl dlkjf lkjlkdjfm, sequi.
                        </p>
                    </div>
                    </div>
                    <div className="avatar-group">
                    <div className="user-avatar">
                        <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="image" />
                    </div>
                    <div className="flex-1">
                        <p className="avatar-name">David Johnson</p>
                        <p className="avatar-msg">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Earujdsajf djf df dfjdkj dlkjfl.kjl dlkjf lkjlkdjfm, sequi.
                        </p>
                    </div>
                    </div>
                    <div className="avatar-group">
                    <div className="user-avatar">
                        <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="image" />
                    </div>
                    <div className="flex-1">
                        <p className="avatar-name">David Johnson</p>
                        <p className="avatar-msg">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Earujdsajf djf df dfjdkj dlkjfl.kjl dlkjf lkjlkdjfm, sequi.
                        </p>
                    </div>
                    </div>
                    <div className="avatar-group">
                    <div className="user-avatar">
                        <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="image" />
                    </div>
                    <div className="flex-1">
                        <p className="avatar-name">David Johnson</p>
                        <p className="avatar-msg">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Earujdsajf djf df dfjdkj dlkjfl.kjl dlkjf lkjlkdjfm, sequi.
                        </p>
                    </div>
                    </div>
                    <div className="avatar-group">
                    <div className="user-avatar">
                        <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="image" />
                    </div>
                    <div className="flex-1">
                        <p className="avatar-name">David Johnson</p>
                        <p className="avatar-msg">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Earujdsajf djf df dfjdkj dlkjfl.kjl dlkjf lkjlkdjfm, sequi.
                        </p>
                    </div>
                    </div>
                    <div className="avatar-group">
                    <div className="user-avatar">
                        <img src={process.env.PUBLIC_URL + "/assets/images/user-profile.jpg"} alt="image" />
                    </div>
                    <div className="flex-1">
                        <p className="avatar-name">David Johnson</p>
                        <p className="avatar-msg">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Earujdsajf djf df dfjdkj dlkjfl.kjl dlkjf lkjlkdjfm, sequi.
                        </p>
                    </div>
                    </div>
                </div>
                <section className="Chat user-chat-show">
                    <div className="ChatHead">
                    <div className="d-block d-lg-none">
                        <Link to="#" className=" user-chat-remove">
                        <i className="fa-solid fa-angle-left" />
                        </Link>
                    </div>
                    <li className="group w-100">
                        <p className="GroupName">MSH Mental Health Support Community</p>
                        <Link to="#" className="avatar user-chat-remove">
                        <img src={process.env.PUBLIC_URL + "/assets/images/mental-health-icon.png"} alt="image" />
                        </Link>
                    </li>
                    {/* <div className="callGroup">
                        <i className="fa-solid fa-phone"></i>
                        <i className="fa-solid fa-video"></i>

                    </div> */}
                    </div>
                    <div className="MessageContainer" id="chatElement">
                    <span />
                    {/* <div className="messageSeperator">Yesterday</div> */}
                    <div className="message me">
                        <p className="messageContent">Hello!</p>
                        <div className="messageDetails">
                        <div className="messageTime">3:21 PM</div>
                        <i className="fa-solid fa-check" />
                        </div>
                    </div>
                    <div className="message me">
                        <p className="messageContent">How are You!</p>
                        <div className="messageDetails">
                        <div className="messageTime">3:22 PM</div>
                        <i className="fa-solid fa-check" />
                        </div>
                    </div>
                    <div className="message you">
                        <div className="messageContent">
                        <div className="video-card-main">
                            <div className="video-card">
                            <div className="video-card-img">
                                <img src={process.env.PUBLIC_URL + "/assets/images/mental-health-video-1.jpg"} alt="image" />
                            </div>
                            <p className="mb-0 title">
                                Behind the Curtain: A Mental Health Story of Family and Friendship:
                                Idella Johnson
                            </p>
                            </div>
                            <a
                            target="_blank"
                            href="https://www.youtube.com/watch?v=zhgTCsYLG5g"
                            className="video-link"
                            >
                            https://www.youtube.com/watch?v=zhgTCsYLG5g
                            </a>
                        </div>
                        </div>
                        <div className="messageDetails">
                        <div className="messageTime">3:30 PM</div>
                        <i className="fa-solid fa-check" />
                        </div>
                    </div>
                    <div className="message you">
                        <div className="messageContent">
                        <div className="video-card-main">
                            <div className="video-card">
                            <div className="video-card-img">
                                <img src={process.env.PUBLIC_URL + "/assets/images/mental-health-video-1.jpg"} alt="image" />
                            </div>
                            <p className="mb-0 title">
                                Nigeria’s Mental Health Crisis. Is Nigeria facing a mental health
                                crisis? (Aljazeera): Dr. Victor Ugo, Dr. Abiye Iruayenama, Dr.
                                Ibrahim Wakawa, and Hauwa Ojeifo
                            </p>
                            </div>
                            <a
                            target="_blank"
                            href="https://www.youtube.com/watch?v=AjdQUJRUeH4"
                            className="video-link"
                            >
                            https://www.youtube.com/watch?v=AjdQUJRUeH4
                            </a>
                        </div>
                        </div>
                        <div className="messageDetails">
                        <div className="messageTime">3:30 PM</div>
                        <i className="fa-solid fa-check" />
                        </div>
                    </div>
                    <div className="message you">
                        <div className="messageContent">
                        <div className="video-card-main">
                            <div className="video-card">
                            <div className="video-card-img">
                                <img src={process.env.PUBLIC_URL + "/assets/images/mental-health-video-1.jpg"} alt="image" />
                            </div>
                            <p className="mb-0 title">
                                Mental Health Is Ignored in Nigeria, And It Is Really A Pandemic
                                -Angela Brown
                            </p>
                            </div>
                            <a
                            target="_blank"
                            href="https://www.youtube.com/watch?v=hsrY_sF1sVY"
                            className="video-link"
                            >
                            https://www.youtube.com/watch?v=hsrY_sF1sVY
                            </a>
                        </div>
                        </div>
                        <div className="messageDetails">
                        <div className="messageTime">3:30 PM</div>
                        <i className="fa-solid fa-check" />
                        </div>
                    </div>
                    <div className="message you">
                        <div className="messageContent">
                        <div className="video-card-main">
                            <div className="video-card">
                            <div className="video-card-img">
                                <img src={process.env.PUBLIC_URL + "/assets/images/mental-health-video-2.png"} alt="image" />
                            </div>
                            <p className="mb-0 title">
                                Is something wrong with me? - Mental Health in Nigeria | Chioma
                                Nwosu | TEDxAsata
                            </p>
                            </div>
                            <a
                            target="_blank"
                            href="https://youtu.be/HB6gie52aBE"
                            className="video-link"
                            >
                            https://youtu.be/HB6gie52aBE
                            </a>
                        </div>
                        </div>
                        <div className="messageDetails">
                        <div className="messageTime">3:30 PM</div>
                        <i className="fa-solid fa-check" />
                        </div>
                    </div>
                    <div className="message you">
                        <div className="messageContent">
                        <div className="video-card-main">
                            <div className="video-card">
                            <div className="video-card-img">
                                <img src={process.env.PUBLIC_URL + "/assets/images/mental-health-video-2.png"} alt="image" />
                            </div>
                            <p className="mb-0 title">
                                Mental Illness, Causes and Treatment. Dr. Laja Ibraheem's
                                Prescription for Living A Fulfilling life
                            </p>
                            </div>
                            <a
                            target="_blank"
                            href="https://www.youtube.com/watch?v=mJqmvajG6-k"
                            className="video-link"
                            >
                            https://www.youtube.com/watch?v=mJqmvajG6-k
                            </a>
                        </div>
                        </div>
                        <div className="messageDetails">
                        <div className="messageTime">3:32 PM</div>
                        <i className="fa-solid fa-check" />
                        </div>
                    </div>
                    {/* <div class="messageSeperator">Today</div> */}
                    <div className="message you">
                        <div className="messageContent">
                        <div className="video-card-main">
                            <div className="video-card">
                            <div className="video-card-img">
                                <img src={process.env.PUBLIC_URL + "/assets/images/mental-health-video-2.png"} alt="image" />
                            </div>
                            <p className="mb-0 title">
                                Accessing Therapy For Mental Health In Nigeria | Rubbin' Mind Aanu
                                Jide-Ojo MBPsS
                            </p>
                            </div>
                            <a
                            target="_blank"
                            href="https://www.youtube.com/watch?v=lcp-Ha7Y7t8"
                            className="video-link"
                            >
                            https://www.youtube.com/watch?v=lcp-Ha7Y7t8
                            </a>
                        </div>
                        </div>
                        <div className="messageDetails">
                        <div className="messageTime">8:09 AM</div>
                        <i className="fa-solid fa-check" />
                        </div>
                    </div>
                    <div className="message you">
                        <div className="messageContent">
                        <div className="video-card-main">
                            <div className="video-card">
                            <div className="video-card-img">
                                <img src={process.env.PUBLIC_URL + "/assets/images/mental-health-video-1.jpg"} alt="image" />
                            </div>
                            <p className="mb-0 title">
                                Mental Health in Nigeria - Arise News Report: Itoro Udofia (Arise
                                News), Amuta Stone, Vanessa Adebayo (Africa's Mental Health
                                Matters), Chime Asonye (Nigerian Mental Health), Dele Asobele
                                (Anti-Suicide and Depression Squad)
                            </p>
                            </div>
                            <a
                            target="_blank"
                            href="https://www.youtube.com/watch?v=ouPjHIGktdI"
                            className="video-link"
                            >
                            https://www.youtube.com/watch?v=ouPjHIGktdI
                            </a>
                        </div>
                        </div>
                        <div className="messageDetails">
                        <div className="messageTime">3:21 AM</div>
                        <i className="fa-solid fa-check" />
                        </div>
                    </div>
                    </div>
                    <form id="MessageForm">
                    <div className="chat-input-group">
                        <input
                        type="text"
                        placeholder="Enter Message..."
                        id="MessageInput"
                        />
                        <span className="input-group-text p-0" id="basic-addon2">
                        <label htmlFor="formFile" className="form-label mb-0">
                            {" "}
                            <i className="fa-solid fa-paperclip font_18" />
                        </label>
                        <input
                            className="form-control d-none"
                            type="file"
                            id="formFile"
                        />
                        </span>
                    </div>
                    <button className="Send">
                        <i className="fa-solid fa-paper-plane" />
                    </button>
                    </form>
                </section>
                </div>
            </div>
            <Footer/>
        </main>  
    </>
  )
}

export default MentalHealthCommunity;