import React, { useState, useEffect } from 'react'
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { findPrescriptionListFromChimeConversation, patientInformation, prescription } from "../../../api/apiHandler";
import Swal from 'sweetalert2';
import queryString from 'query-string';
import moment from 'moment';

function AutoPrescription() {

    const navigate = useNavigate();
    const location = useLocation();
    console.log('location.state: ', location.state);
    const params = queryString.parse(window.location.search);
    console.log('params: ', params);

    const [meetingData,setMeetingData] = useState(params);
    console.log('meetingData: ', meetingData);
    const [medicineList,setMedicineList] = useState();
    console.log('medicineList: ', medicineList);
    const [patientId,setPatientId] = useState(meetingData.patient_id);
    console.log('patientId: ', patientId);

    const [patientData,setPatientData] = useState(null);

    useEffect(() => {
        if(meetingData != null){
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })

            const findPrescriptionObj = {
                "meeting_id" : meetingData.meeting_id
            }

            findPrescriptionListFromChimeConversation(findPrescriptionObj).then((response) => {
                response = JSON.parse(response)
                Swal.close()
                if (response.code == process.env.REACT_APP_SUCCESS) {
                    setMedicineList(response.data.response)
                    setPatientId(response.data.patient_id)

                }else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })
            getPatientDetails()
        }
    }, [])

    const getPatientDetails = () => {
        if(patientId != 0){
            patientInformation({"patient_id" : patientId}).then((response) => {
                response = JSON.parse(response)
                console.log("response",response);
                if (response.code == process.env.REACT_APP_SUCCESS) {
                    setPatientData(response.data)
                } else {
                    setPatientData(null)
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })
        }
    }

    const handleSendRx = () => {
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        var params = {
            "patient_id": patientId,
            "meeting_id": meetingData.meeting_id,
            "appointment_id": meetingData.appointment_id,
            "prescription_date": moment().utc().format('YYYY-MM-DD'),
            "first_name": patientData != null ? patientData.first_name : null,
            "last_name": patientData != null ? patientData.last_name : null,
            "age": patientData != null ? patientData.age : 0,
            "date_of_birth": null,
            "gender": null,
            "county": patientData != null ? patientData.country : null,
            "state": patientData != null ? patientData.state : null,
            "city": patientData != null ? patientData.city : null,
            "address": patientData != null ? patientData.address : null,
            "zipcode": null,
            "physician_first_name": null,
            "physician_last_name": null,
            "additional_direction": null,
            "permission_code": null,
        }
        console.log('params', params)
        prescription(params).then((response) => {
            response = JSON.parse(response)
            console.log("response",response);
            if (response.code == process.env.REACT_APP_SUCCESS) {
                const sendRxObj = {
                    "prescription_id" : response.data.id,
                    "patient_id" : patientId,
                    "appointment_id" : meetingData.appointment_id
                }
                console.log("sendRxObj",sendRxObj);
                navigate('/doctor/chemist-list',{ state : sendRxObj })
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
            Swal.close()
        })
    }

    // const handleGoToPrescription = () => {
    //     console.log("go to prescription");
    //     const prescriptionObj = {
    //         "patient_id" : patientId,
    //         "appointment_id" : meetingData.appointment_id,
    //         "meeting_id" : meetingData.meeting_id
    //     }
    //     navigate('/doctor/prescription',{ state : prescriptionObj }) 
    // }

  return (
    <>
        <main className="bg-color flex-column">
            <Header/>
                <section className="doctorsec-pad share-medical">
                    <div className="container">
                    <div className="card">
                        <div className="card-body doctor-table-card">
                        <div className="table-responsive">
                            <table className="doctor-list-table table table-bordered">
                            <thead>
                                <tr>
                                <th scope="row">Page</th>
                                <th>Name of Drug</th>
                                <th>Strength</th>
                                <th>Unit</th>
                                <th>Form</th>
                                <th>Dose</th>
                                <th>Frequency</th>
                                <th>Intakes or Applications</th>
                                <th>Route</th>
                                <th>Refills</th>
                                <th>Quantity</th>
                                <th>Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                {medicineList && medicineList.map((item,index) => {
                                    return (
                                        <tr id={item.id} key={index}>
                                            <td scope="row">{item.id}</td>
                                            <td>
                                                <h3 className="doc-details">{item.medicine_name}</h3>
                                            </td>
                                            <td>{item.strength}</td>
                                            <td>{item.strength_type}</td>
                                            <td>{item.medicine_type}</td>
                                            <td>{item.dose}</td>
                                            <td>{item.frequency}</td>
                                            <td>{item.intake}</td>
                                            <td>{item.route}</td>
                                            <td>{item.refills}</td>
                                            <td>{item.quantity}</td>
                                            <td>{item.comments}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                            </table>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6 col-lg-4">
                            <button className="submit-btn mt-3 w-100" onClick={handleSendRx}>Send Rx</button>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </section>
            <Footer/>
        </main> 
    </>
  )
}

export default AutoPrescription;
