import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { patientfeedback } from '../../../api/apiHandler';
import Swal from 'sweetalert2';
import { loginStoreData } from '../common/RedirectPathMange';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import moment from 'moment/moment';

function App() {
  const [useAiva, setUseAiva] = useState('1');
  const [recommendAiva, setRecommendAiva] = useState('1');
  const [comments, setComments] = useState('');
  const [rating, setRating] = useState('1');
  const [showModal, setShowModal] = useState(false);
  const [PR3, setPR3] = useState('1');
  const [PR4, setPR4] = useState('1');
  const [F1, setF1] = useState('1');
  const [F2, setF2] = useState('1');
  const [F3, setF3] = useState('1');
  const [F4, setF4] = useState('1');
  const [F5, setF5] = useState('1');
  const [F6, setF6] = useState('1');
  const [F7, setF7] = useState('1');
  const [F8, setF8] = useState('1');
  const [F9, setF9] = useState('1');
  const [F10, setF10] = useState('1');
  const [F11, setF11] = useState('1');
  const [F12, setF12] = useState('1');
  const [F13, setF13] = useState('1');
  const [F14, setF14] = useState('1');
  const [F15, setF15] = useState('1');
  const [MR2, setMR2] = useState('1');
  const [MR3, setMR3] = useState('1');
  const [MR4, setMR4] = useState('1');
  const [R1, setR1] = useState('1');
  const [R2, setR2] = useState('1');
  const [R3, setR3] = useState('1');
  const [R4, setR4] = useState('1');

  function getYesterday() {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return yesterday;
  }

  let yesterdayDate = getYesterday();

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
    navigate("/patient/home");
  };
  const handleChange = (event) => {
    if(event.target.name=='rating'){
      setRating(event.target.value);
    }
    if(event.target.name=='useAiva'){
      setUseAiva(event.target.value);
    }
    if(event.target.name=='recommendAiva'){
      setRecommendAiva(event.target.value);
    }
    if(event.target.name=='comments'){
      setComments(event.target.value);
    }
    if(event.target.name=='pr_3'){
      setPR3(event.target.value);
    }
    if(event.target.name=='pr_4'){
      setPR4(event.target.value);
    }
    if(event.target.name=='f_1'){
      setF1(event.target.value);
    }
    if(event.target.name=='f_2'){
      setF2(event.target.value);
    }
    if(event.target.name=='f_3'){
      setF3(event.target.value);
    }
    if(event.target.name=='f_4'){
      setF4(event.target.value);
    }
    if(event.target.name=='f_5'){
      setF5(event.target.value);
    }
    if(event.target.name=='f_6'){
      setF6(event.target.value);
    }
    if(event.target.name=='f_7'){
      setF7(event.target.value);
    }
    if(event.target.name=='f_8'){
      setF8(event.target.value);
    }
    if(event.target.name=='f_9'){
      setF9(event.target.value);
    }
    if(event.target.name=='f_10'){
      setF10(event.target.value);
    }
    if(event.target.name=='f_11'){
      setF11(event.target.value);
    }
    if(event.target.name=='f_12'){
      setF12(event.target.value);
    }
    if(event.target.name=='f_13'){
      setF13(event.target.value);
    }
    if(event.target.name=='f_14'){
      setF14(event.target.value);
    }
    if(event.target.name=='f_15'){
      setF15(event.target.value);
    }
    if(event.target.name=='mr_2'){
      setMR2(event.target.value);
    }
    if(event.target.name=='mr_3'){
      setMR3(event.target.value);
    }
    if(event.target.name=='mr_4'){
      setMR4(event.target.value);
    }
    if(event.target.name=='r_1'){
      setR1(event.target.value);
    }
    if(event.target.name=='r_2'){
      setR2(event.target.value);
    }
    if(event.target.name=='r_3'){
      setR3(event.target.value);
    }
    if(event.target.name=='r_4'){
      setR4(event.target.value);
    }
    
  };

  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("Please enter first name")
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field')
      .max(128, "first name must be at most 128 character"),
    middle_name: Yup.string()
      .required("Please enter middle initial")
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field')
      .max(128, "middle initial must be at most 128 character"),
    last_name: Yup.string()
      .required("Please enter last name")
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field')
      .max(128, "last name must be at most 128 character"),
    email1: Yup.string().email("Please enter the valid email")
      .required("Please enter email"),
    email2: Yup.string().email("Please enter the valid email")
      .required("Please enter email"),
    phone_number1: Yup.string()
      .required("Please enter phone number")
      .matches('^[0-9]', 'Only numbers are allowed for this field')
      .min(8, "Phone number must be at least 8 numbers")
      .max(14, "Phone number must be at most 14 numbers"),
    phone_number2: Yup.string()
      .required("Please enter phone number")
      .matches('^[0-9]', 'Only numbers are allowed for this field')
      .min(8, "Phone number must be at least 8 numbers")
      .max(14, "Phone number must be at most 14 numbers"),
    country_name: Yup.string()
      .required("Please enter country name")
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field'),
    state_name: Yup.string()
      .required("Please enter state name")
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field'),
    date: Yup.date()
      .typeError('Please select date')
      .required("Please select date")
      // .typeError("Please select date")
      .min(yesterdayDate, "Selected date must not be in the past"),
    city_name: Yup.string()
      .required("Please enter city name")
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field'),
    easeOfUse: Yup.string()
      .required("Please enter ease of use"),
    feedback: Yup.string()
      .required("Please enter what else you want AIVA to do?"),
    // rating: Yup.number()
    //   .typeError('Please select a rating')
    //   .required('Please select a rating'),
    ai_1: Yup.string()
      .required('Please select option'),
    ai_2: Yup.string()
      .required('Please select option'),
    ai_3: Yup.string()
      .required('Please select option'),
    ai_4: Yup.string()
      .required('Please select option'),
    p_1: Yup.string()
      .required('Please select option'),
    p_2: Yup.string()
      .required('Please select option'),
    p_3: Yup.string()
      .required('Please select option'),
    p_4: Yup.string()
      .required('Please select option'),
    p_5: Yup.string()
      .required('Please select option'),
    pr_1: Yup.string()
      .required('Please select option'),
    pr_2: Yup.string()
      .required('Please select option'),
    mr_1: Yup.string()
      .required('Please select option'),
  });
  
  
  
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = (data) => {
    console.log("data",data);
    var newData = {
      "user_type":localStorage.getItem("user_role"),
      "first_name": data.first_name,
      "middle_name": data.middle_name,
      "last_name": data.last_name,
      "email1": data.email1,
      "email2": data.email2,
      "phone_no1": data.phone_number1,
      "phone_no2": data.phone_number2,
      "country": data.country_name,
      "state": data.state_name,
      "city": data.city_name,
      "date": moment(data.date).format('YYYY-MM-DD'),
      "overall_rating": rating,
      "use_aiva": useAiva,
      "ease_of_use": data.easeOfUse,
      "recommend_aiva": recommendAiva,
      "like_aiva_to_do": data.feedback,
      "comments": comments,
      "ai_vital_sign_app":F1,
      "ai_doc_1":F2,
      "ai_doc_2":F3,
      "ai_doc_3":F4,
      "gpt_automatic_star_pilot_realtime":F5,
      "one_stop_health_wellness_care":F6,
      "online_offline_services_b2b2c_marketplace":F7,
      "human_doctor_conference_room":F8,
      "b2b2c_marketplace":F9,
      "starpilot_safety_qc_check":F10,
      "error_proof_prescription":F11,
      "qc_safety_checked_prescription":F12,
      "qc_on_doctor_prevent_mistake":F13,
      "qc_on_patient_report_diagnosis":F14,
      "real_time_guidance_of_doctor":F15,
      "vital_signs_app":data.ai_1,
      "ai_doctors_apps":data.ai_2,
      "ai_vital_and_doctor_apps_are_easy":data.ai_3,
      "would_you_use_to_prevent_you_from_sick":data.ai_4,
      "on_the_platform":data.p_1,
      "on_the_vital_signs_ai_doctors":data.p_2,
      "on_the_human_doctors_pharmacy_video_conference":data.p_3,
      "on_the_medical_community_support_room":data.p_4,
      "on_the_prayer_support_room":data.p_5,
      "how_regulary_would_you_visit":data.pr_1,
      "would_you_participate_ongoing_prayers":data.pr_2,
      "would_you_sugget_sick_friends_relatives":PR3,
      "would_you_like_particular_pastors":PR4,
      "what_other_features_would_you_like_to_see": data.prayer_room_comments,
      "how_regulary_would_you_visit_medical_support_rooms":data.mr_1,
      "would_you_participate_ongoing_discussions":MR2,
      "would_you_submit_questions_to_doctors":MR3,
      "would_you_attend_webinars":MR4,
      "features_want_to_see_medical_support_room": data.medical_community_room_comments,
      "total_healthcare_costs_to_pay_monthly":R1,
      "would_you_pay":R2,
      "would_you_use_platform_to_pay":R3,
      "would_you_recomment_this_to_pay_monthly":R4,
      "item_you_love_about_platform": data.gc_1,
      "item_dont_like_but_improve": data.gc_2,
      "universal_primary_care_objectives": data.gc_3,
      "what_works_well_on_platform": data.gc_4,
      "what_does_works_well_on_platform": data.gc_5,
      "part_of_platform_services": data.gc_6,
      "why_visit_regular": data.gc_7,
      "changes_on_vitals_apps": data.gc_8
    }
    console.log('newData: ', newData);
    // console.log('newData',JSON.stringify(newData))
    Swal.fire({
      title: 'Please wait...',
      didOpen: () => {
          Swal.showLoading()
      }
    })
    patientfeedback(newData).then((res) => {
        Swal.close()
        var response = JSON.parse(res)
        console.log('response=>',response)
        if (response.code == process.env.REACT_APP_SUCCESS) {
          console.log('suucesss')
          reset()
          openModal(); 
        }
        else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: response.message,
            toast: true,
            showConfirmButton: false,
            timer: 2000
          })
        }
    });
  }

  return (
    <>
    <main className="bg-color">
      <Header />
      <section className="share-medical">
        <div className="container">
          <div className="">
            <p className="font_22 font_600 mb-0">Feedback</p>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <Link to="/patient/home" className="font_14">
                    Home
                    </Link>
                </li>
                <li
                    className="breadcrumb-item active font_14"
                    aria-current="page"
                >
                    Feedback
                </li>
                </ol>
            </nav>
          </div>
          {showModal && (
          <Modal show={openModal} onHide={closeModal} centered id="modal-emergency" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content modal-custom">
              <div className="modal-body pb-0">
              <p className="modal-msg text-blue">Thank you for your feedback!</p>
              </div>
              <div className="modal-footer border-0">
                <button type="button" className="modal-button" onClick={closeModal}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
          )}
          <div className="form-fields">
            <div className="row justify-content-center pt-3 pb-5">
              <div className="col-lg-10 col-xl-10 col-12">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="feedback-sec profle_input_fields">
                    <p className="font_22 font_600 mb-0">Monte Sereno Health Feedback Form</p>
                    <hr className="mb-4" />
                    <div className="row">
                      <div className="col-lg-6 col-xl-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="firstName" className="form-label">First Name</label>
                          <input type="text" {...register('first_name')} className="form-control" placeholder="Please enter first name" />
                          <div className="invalid-feedback">{errors.first_name?.message}</div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="middleInitial" className="form-label">Middle Initial</label>
                          <input type="text" {...register('middle_name')} className="form-control" placeholder="Please enter middle initial" />
                          <div className="invalid-feedback">{errors.middle_name?.message}</div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="lastName" className="form-label">Last Name</label>
                          <input type="text" {...register('last_name')} className="form-control" placeholder="Please enter last name" />
                          <div className="invalid-feedback">{errors.last_name?.message}</div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="email1" className="form-label">Email1</label>
                          <input type="email" {...register('email1')} className="form-control" placeholder="Please enter email" />
                          <div className="invalid-feedback">{errors.email1?.message}</div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-xl-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="email2" className="form-label">Email2</label>
                          <input type="email" {...register('email2')} className="form-control" placeholder="Please enter email" />
                          <div className="invalid-feedback">{errors.email2?.message}</div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="phoneNumber1" className="form-label">Phone Number1</label>
                          <input type="text" {...register('phone_number1')} className="form-control" placeholder="Please enter phone number" />
                          <div className="invalid-feedback">{errors.phone_number1?.message}</div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="phoneNumber2" className="form-label">Phone Number2</label>
                          <input type="text" {...register('phone_number2')} className="form-control" placeholder="Please enter phone number" />
                          <div className="invalid-feedback">{errors.phone_number2?.message}</div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="country" className="form-label">Country</label>
                          <input type="text" {...register('country_name')} className="form-control"  placeholder="Please enter country name" />
                          <div className="invalid-feedback">{errors.country_name?.message}</div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="state" className="form-label">State</label>
                          <input type="text" {...register('state_name')} className="form-control" placeholder="Please enter state name" />
                          <div className="invalid-feedback">{errors.state_name?.message}</div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="city" className="form-label">City</label>
                          <input type="text" {...register('city_name')} className="form-control" placeholder="Please enter city name" />
                          <div className="invalid-feedback">{errors.city_name?.message}</div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-xl-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="easeOfUse" className="form-label">Ease Of Use</label>
                          <input type="text" {...register('easeOfUse')} className="form-control" placeholder="Please enter ease of use" />
                          <div className="invalid-feedback">{errors.easeOfUse?.message}</div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-xl-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="feedback" className="form-label">What Else Would You Like Aiva To Do?</label>
                          <input type="text" {...register('feedback')} className="form-control" placeholder="Please enter what else you want AIVA to do?" />
                          <div className="invalid-feedback">{errors.feedback?.message}</div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-6 col-12">
                        <div className="mb-3">
                          <label className="form-label">Would You Use Aiva?</label>
                          <div className="d-flex gap-3 align-items-center">
                            <div className="form-check form-custom d-flex align-items-center gap-1">
                              <input
                                className="form-check-input radio"
                                type="radio"
                                name="useAiva"
                                id="useAivaYes"
                                {...register('useAiva')}
                                value="1"
                                checked={useAiva === "1"}
                                onChange={handleChange}
                              />
                              <label className="form-check-label" htmlFor="useAivaYes">
                                Yes
                              </label>
                            </div>

                            <div className="form-check form-custom d-flex align-items-center gap-1">
                              <input
                                className="form-check-input radio"
                                type="radio"
                                name="useAiva"
                                id="useAivaNo"
                                {...register('useAiva')}
                                value="0"
                                checked={useAiva === "0"}
                                onChange={handleChange}
                              />
                              <label className="form-check-label" htmlFor="useAivaNo">
                                No
                              </label>
                            </div>
                            <div className="invalid-feedback">{errors.useAiva?.message}</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-6 col-12">
                        <div className="mb-3">
                          <label className="form-label">Would You Recommend Aiva to Friends and Family?</label>
                          <div className="d-flex gap-3 align-items-center">
                            <div className="form-check form-custom d-flex align-items-center gap-1">
                              <input
                                className="form-check-input radio"
                                type="radio"
                                name="recommendAiva"
                                id="recommendAivaYes"
                                value="1"
                                {...register('recommendAiva')}
                                checked={recommendAiva === "1"}
                                onChange={handleChange}
                              />
                              <label className="form-check-label" htmlFor="recommendAivaYes">
                                Yes
                              </label>
                            </div>

                            <div className="form-check form-custom d-flex align-items-center gap-1">
                              <input
                                className="form-check-input radio"
                                type="radio"
                                name="recommendAiva"
                                id="recommendAivaNo"
                                {...register('recommendAiva')}
                                value="0"
                                checked={recommendAiva === "0"}
                                onChange={handleChange}
                              />
                              <label className="form-check-label" htmlFor="recommendAivaNo">
                                No
                              </label>
                            </div>
                            <div className="invalid-feedback">{errors.recommendAiva?.message}</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="date" className="form-label">Date</label>
                          <input type="date" {...register('date')} className="form-control" />
                          <div className="invalid-feedback">{errors.date?.message}</div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="rating" className="form-label">Rating review</label>
                          <div className="star-rating mb-3">
                            <input id="star-5" type="radio" name="rating" value="5" {...register('rating')}
                                checked={rating === "5"}
                                onChange={handleChange}
                              />
                            <label htmlFor="star-5" title="5 stars">
                              <i className="active fa fa-star" aria-hidden="true"></i>
                            </label>
                            <input id="star-4" type="radio" name="rating" value="4" {...register('rating')}
                                checked={rating === "4"}
                                onChange={handleChange}/>
                            <label htmlFor="star-4" title="4 stars">
                              <i className="active fa fa-star" aria-hidden="true"></i>
                            </label>
                            <input id="star-3" type="radio" name="rating" value="3" {...register('rating')}
                                checked={rating === "3"}
                                onChange={handleChange}/>
                            <label htmlFor="star-3" title="3 stars">
                              <i className="active fa fa-star" aria-hidden="true"></i>
                            </label>
                            <input id="star-2" type="radio" name="rating" value="2" {...register('rating')}
                                checked={rating === "2"}
                                onChange={handleChange}/>
                            <label htmlFor="star-2" title="2 stars">
                              <i className="active fa fa-star" aria-hidden="true"></i>
                            </label>
                            <input id="star-1" type="radio" name="rating" value="1" {...register('rating')}
                                checked={rating === "1"}
                                onChange={handleChange}/>
                            <label htmlFor="star-1" title="1 stars">
                              <i className="active fa fa-star" aria-hidden="true"></i>
                            </label>

                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-xl-12 col-12">
                        <div className="mb-3">
                          <label htmlFor="comments" className="form-label">Comments & Suggestions</label>
                          <textarea
                            className="form-control"
                            placeholder="Leave a comment here"
                            id="comments"
                            {...register('comments')} 
                            name="comments"
                            style={{ height: "100px" }}
                            value={comments}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <p className="font_22  font_600 text-blue  mb-0">
                        WHAT FEATURES WOULD MAKE YOU USE DIFFERENT PARTS OF THE PLATFORM?
                      </p>
                      <hr className="mb-4" />
                      <div className="row">
                        <div className="col-lg-6 col-xl-6 col-12">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              1. AI Vital Signs App
                            </label>
                            <div className="star-rating mb-3">
                              <input id="stara-5" type="radio" name="ratinga" value="5" {...register('f_1')}
                                  checked={F1 === "5"}
                                  onChange={handleChange}
                                />
                              <label htmlFor="stara-5" title="5 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="stara-4" type="radio" name="ratinga" value="4" {...register('f_1')}
                                  checked={F1 === "4"}
                                  onChange={handleChange}/>
                              <label htmlFor="stara-4" title="4 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="stara-3" type="radio" name="ratinga" value="3" {...register('f_1')}
                                  checked={F1 === "3"}
                                  onChange={handleChange}/>
                              <label htmlFor="stara-3" title="3 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="stara-2" type="radio" name="ratinga" value="2" {...register('f_1')}
                                  checked={F1 === "2"}
                                  onChange={handleChange}/>
                              <label htmlFor="stara-2" title="2 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="stara-1" type="radio" name="ratinga" value="1" {...register('f_1')}
                                  checked={F1 === "1"}
                                  onChange={handleChange}/>
                              <label htmlFor="stara-1" title="1 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              AI Doctor 1
                            </label>
                            <div className="star-rating mb-3">
                              <input id="starb-5" type="radio" name="ratingb" value="5" {...register('f_2')}
                                  checked={F2 === "5"}
                                  onChange={handleChange}
                                />
                              <label htmlFor="starb-5" title="5 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starb-4" type="radio" name="ratingb" value="4" {...register('f_2')}
                                  checked={F2 === "4"}
                                  onChange={handleChange}/>
                              <label htmlFor="starb-4" title="4 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starb-3" type="radio" name="ratingb" value="3" {...register('f_2')}
                                  checked={F2 === "3"}
                                  onChange={handleChange}/>
                              <label htmlFor="starb-3" title="3 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starb-2" type="radio" name="ratingb" value="2" {...register('f_2')}
                                  checked={F2 === "2"}
                                  onChange={handleChange}/>
                              <label htmlFor="starb-2" title="2 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starb-1" type="radio" name="ratingb" value="1" {...register('f_2')}
                                  checked={F2 === "1"}
                                  onChange={handleChange}/>
                              <label htmlFor="starb-1" title="1 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              AI Doctor 2
                            </label>
                            <div className="star-rating mb-3">
                              <input id="starc-5" type="radio" name="ratingc" value="5" {...register('f_3')}
                                  checked={F3 === "5"}
                                  onChange={handleChange}
                                />
                              <label htmlFor="starc-5" title="5 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starc-4" type="radio" name="ratingc" value="4" {...register('f_3')}
                                  checked={F3 === "4"}
                                  onChange={handleChange}/>
                              <label htmlFor="starc-4" title="4 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starc-3" type="radio" name="ratingc" value="3" {...register('f_3')}
                                  checked={F3 === "3"}
                                  onChange={handleChange}/>
                              <label htmlFor="starc-3" title="3 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starc-2" type="radio" name="ratingc" value="2" {...register('f_3')}
                                  checked={F3 === "2"}
                                  onChange={handleChange}/>
                              <label htmlFor="starc-2" title="2 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starc-1" type="radio" name="ratingc" value="1" {...register('f_3')}
                                  checked={F3 === "1"}
                                  onChange={handleChange}/>
                              <label htmlFor="starc-1" title="1 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              AI Doctor 3
                            </label>
                            <div className="star-rating mb-3">
                              <input id="stard-5" type="radio" name="ratingd" value="5" {...register('f_4')}
                                  checked={F4 === "5"}
                                  onChange={handleChange}
                                />
                              <label htmlFor="stard-5" title="5 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="stard-4" type="radio" name="ratingd" value="4" {...register('f_4')}
                                  checked={F4 === "4"}
                                  onChange={handleChange}/>
                              <label htmlFor="stard-4" title="4 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="stard-3" type="radio" name="ratingd" value="3" {...register('f_4')}
                                  checked={F4 === "3"}
                                  onChange={handleChange}/>
                              <label htmlFor="stard-3" title="3 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="stard-2" type="radio" name="ratingd" value="2" {...register('f_4')}
                                  checked={F4 === "2"}
                                  onChange={handleChange}/>
                              <label htmlFor="stard-2" title="2 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="stard-1" type="radio" name="ratingd" value="1" {...register('f_4')}
                                  checked={F4 === "1"}
                                  onChange={handleChange}/>
                              <label htmlFor="stard-1" title="1 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              3. GPT starpilot automatic real-time guidance of worldwide doctors
                            </label>
                            <div className="star-rating mb-3">
                              <input id="stare-5" type="radio" name="ratinge" value="5" {...register('f_5')}
                                  checked={F5 === "5"}
                                  onChange={handleChange}
                                />
                              <label htmlFor="stare-5" title="5 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="stare-4" type="radio" name="ratinge" value="4" {...register('f_5')}
                                  checked={F5 === "4"}
                                  onChange={handleChange}/>
                              <label htmlFor="stare-4" title="4 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="stare-3" type="radio" name="ratinge" value="3" {...register('f_5')}
                                  checked={F5 === "3"}
                                  onChange={handleChange}/>
                              <label htmlFor="stare-3" title="3 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="stare-2" type="radio" name="ratinge" value="2" {...register('f_5')}
                                  checked={F5 === "2"}
                                  onChange={handleChange}/>
                              <label htmlFor="stare-2" title="2 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="stare-1" type="radio" name="ratinge" value="1" {...register('f_5')}
                                  checked={F5 === "1"}
                                  onChange={handleChange}/>
                              <label htmlFor="stare-1" title="1 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              4. ONE-STOP HEALTH AND WELLNESS CARE{" "}
                            </label>
                            <div className="star-rating mb-3">
                              <input id="starf-5" type="radio" name="ratingf" value="5" {...register('f_6')}
                                  checked={F6 === "5"}
                                  onChange={handleChange}
                                />
                              <label htmlFor="starf-5" title="5 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starf-4" type="radio" name="ratingf" value="4" {...register('f_6')}
                                  checked={F6 === "4"}
                                  onChange={handleChange}/>
                              <label htmlFor="starf-4" title="4 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starf-3" type="radio" name="ratingf" value="3" {...register('f_6')}
                                  checked={F6 === "3"}
                                  onChange={handleChange}/>
                              <label htmlFor="starf-3" title="3 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starf-2" type="radio" name="ratingf" value="2" {...register('f_6')}
                                  checked={F6 === "2"}
                                  onChange={handleChange}/>
                              <label htmlFor="starf-2" title="2 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starf-1" type="radio" name="ratingf" value="1" {...register('f_6')}
                                  checked={F6 === "1"}
                                  onChange={handleChange}/>
                              <label htmlFor="starf-1" title="1 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              5. Seamless Online and Offline Services B2B2C Marketplace - Every
                              service in One place - One-Stop{" "}
                            </label>
                            <div className="star-rating mb-3">
                              <input id="starg-5" type="radio" name="ratingg" value="5" {...register('f_7')}
                                  checked={F7 === "5"}
                                  onChange={handleChange}
                                />
                              <label htmlFor="starg-5" title="5 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starg-4" type="radio" name="ratingg" value="4" {...register('f_7')}
                                  checked={F7 === "4"}
                                  onChange={handleChange}/>
                              <label htmlFor="starg-4" title="4 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starg-3" type="radio" name="ratingg" value="3" {...register('f_7')}
                                  checked={F7 === "3"}
                                  onChange={handleChange}/>
                              <label htmlFor="starg-3" title="3 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starg-2" type="radio" name="ratingg" value="2" {...register('f_7')}
                                  checked={F7 === "2"}
                                  onChange={handleChange}/>
                              <label htmlFor="starg-2" title="2 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starg-1" type="radio" name="ratingg" value="1" {...register('f_7')}
                                  checked={F7 === "1"}
                                  onChange={handleChange}/>
                              <label htmlFor="starg-1" title="1 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              6. Human Doctor in Conference Room{" "}
                            </label>
                            <div className="star-rating mb-3">
                              <input id="starh-5" type="radio" name="ratingh" value="5" {...register('f_8')}
                                  checked={F8 === "5"}
                                  onChange={handleChange}
                                />
                              <label htmlFor="starh-5" title="5 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starh-4" type="radio" name="ratingh" value="4" {...register('f_8')}
                                  checked={F8 === "4"}
                                  onChange={handleChange}/>
                              <label htmlFor="starh-4" title="4 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starh-3" type="radio" name="ratingh" value="3" {...register('f_8')}
                                  checked={F8 === "3"}
                                  onChange={handleChange}/>
                              <label htmlFor="starh-3" title="3 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starh-2" type="radio" name="ratingh" value="2" {...register('f_8')}
                                  checked={F8 === "2"}
                                  onChange={handleChange}/>
                              <label htmlFor="starh-2" title="2 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starh-1" type="radio" name="ratingh" value="1" {...register('f_8')}
                                  checked={F8 === "1"}
                                  onChange={handleChange}/>
                              <label htmlFor="starh-1" title="1 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              7. B2B2C Marketplace{" "}
                            </label>
                            <div className="star-rating mb-3">
                              <input id="stari-5" type="radio" name="ratingi" value="5" {...register('f_9')}
                                  checked={F9 === "5"}
                                  onChange={handleChange}
                                />
                              <label htmlFor="stari-5" title="5 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="stari-4" type="radio" name="ratingi" value="4" {...register('f_9')}
                                  checked={F9 === "4"}
                                  onChange={handleChange}/>
                              <label htmlFor="stari-4" title="4 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="stari-3" type="radio" name="ratingi" value="3" {...register('f_9')}
                                  checked={F9 === "3"}
                                  onChange={handleChange}/>
                              <label htmlFor="stari-3" title="3 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="stari-2" type="radio" name="ratingi" value="2" {...register('f_9')}
                                  checked={F9 === "2"}
                                  onChange={handleChange}/>
                              <label htmlFor="stari-2" title="2 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="stari-1" type="radio" name="ratingi" value="1" {...register('f_9')}
                                  checked={F9 === "1"}
                                  onChange={handleChange}/>
                              <label htmlFor="stari-1" title="1 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              8. StarPilot QC and Safety services{" "}
                            </label>
                            <div className="star-rating mb-3">
                              <input id="starj-5" type="radio" name="ratingj" value="5" {...register('f_10')}
                                  checked={F10 === "5"}
                                  onChange={handleChange}
                                />
                              <label htmlFor="starj-5" title="5 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starj-4" type="radio" name="ratingj" value="4" {...register('f_10')}
                                  checked={F10 === "4"}
                                  onChange={handleChange}/>
                              <label htmlFor="starj-4" title="4 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starj-3" type="radio" name="ratingj" value="3" {...register('f_10')}
                                  checked={F10 === "3"}
                                  onChange={handleChange}/>
                              <label htmlFor="starj-3" title="3 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starj-2" type="radio" name="ratingj" value="2" {...register('f_10')}
                                  checked={F10 === "2"}
                                  onChange={handleChange}/>
                              <label htmlFor="starj-2" title="2 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starj-1" type="radio" name="ratingj" value="1" {...register('f_10')}
                                  checked={F10 === "1"}
                                  onChange={handleChange}/>
                              <label htmlFor="starj-1" title="1 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              9. Error-proof Prescription{" "}
                            </label>
                            <div className="star-rating mb-3">
                              <input id="stark-5" type="radio" name="ratingk" value="5" {...register('f_11')}
                                  checked={F11 === "5"}
                                  onChange={handleChange}
                                />
                              <label htmlFor="stark-5" title="5 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="stark-4" type="radio" name="ratingk" value="4" {...register('f_11')}
                                  checked={F11 === "4"}
                                  onChange={handleChange}/>
                              <label htmlFor="stark-4" title="4 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="stark-3" type="radio" name="ratingk" value="3" {...register('f_11')}
                                  checked={F11 === "3"}
                                  onChange={handleChange}/>
                              <label htmlFor="stark-3" title="3 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="stark-2" type="radio" name="ratingk" value="2" {...register('f_11')}
                                  checked={F11 === "2"}
                                  onChange={handleChange}/>
                              <label htmlFor="stark-2" title="2 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="stark-1" type="radio" name="ratingk" value="1" {...register('f_11')}
                                  checked={F11 === "1"}
                                  onChange={handleChange}/>
                              <label htmlFor="stark-1" title="1 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              10. QC/Safety Checked Prescription{" "}
                            </label>
                            <div className="star-rating mb-3">
                              <input id="starl-5" type="radio" name="ratingl" value="5" {...register('f_12')}
                                  checked={F12 === "5"}
                                  onChange={handleChange}
                                />
                              <label htmlFor="starl-5" title="5 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starl-4" type="radio" name="ratingl" value="4" {...register('f_12')}
                                  checked={F12 === "4"}
                                  onChange={handleChange}/>
                              <label htmlFor="starl-4" title="4 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starl-3" type="radio" name="ratingl" value="3" {...register('f_12')}
                                  checked={F12 === "3"}
                                  onChange={handleChange}/>
                              <label htmlFor="starl-3" title="3 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starl-2" type="radio" name="ratingl" value="2" {...register('f_12')}
                                  checked={F12 === "2"}
                                  onChange={handleChange}/>
                              <label htmlFor="starl-2" title="2 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starl-1" type="radio" name="ratingl" value="1" {...register('f_12')}
                                  checked={F12 === "1"}
                                  onChange={handleChange}/>
                              <label htmlFor="starl-1" title="1 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              11. QC on doctor to prevent mistakes
                            </label>
                            <div className="star-rating mb-3">
                              <input id="starm-5" type="radio" name="ratingm" value="5" {...register('f_13')}
                                  checked={F13 === "5"}
                                  onChange={handleChange}
                                />
                              <label htmlFor="starm-5" title="5 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starm-4" type="radio" name="ratingm" value="4" {...register('f_13')}
                                  checked={F13 === "4"}
                                  onChange={handleChange}/>
                              <label htmlFor="starm-4" title="4 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starm-3" type="radio" name="ratingm" value="3" {...register('f_13')}
                                  checked={F13 === "3"}
                                  onChange={handleChange}/>
                              <label htmlFor="starm-3" title="3 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starm-2" type="radio" name="ratingm" value="2" {...register('f_13')}
                                  checked={F13 === "2"}
                                  onChange={handleChange}/>
                              <label htmlFor="starm-2" title="2 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starm-1" type="radio" name="ratingm" value="1" {...register('f_13')}
                                  checked={F13 === "1"}
                                  onChange={handleChange}/>
                              <label htmlFor="starm-1" title="1 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              12. QC on patient to help symptom reporting and diagnosis
                            </label>
                            <div className="star-rating mb-3">
                              <input id="starn-5" type="radio" name="ratingn" value="5" {...register('f_14')}
                                  checked={F14 === "5"}
                                  onChange={handleChange}
                                />
                              <label htmlFor="starn-5" title="5 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starn-4" type="radio" name="ratingn" value="4" {...register('f_14')}
                                  checked={F14 === "4"}
                                  onChange={handleChange}/>
                              <label htmlFor="starn-4" title="4 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starn-3" type="radio" name="ratingn" value="3" {...register('f_14')}
                                  checked={F14 === "3"}
                                  onChange={handleChange}/>
                              <label htmlFor="starn-3" title="3 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starn-2" type="radio" name="ratingn" value="2" {...register('f_14')}
                                  checked={F14 === "2"}
                                  onChange={handleChange}/>
                              <label htmlFor="starn-2" title="2 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="starn-1" type="radio" name="ratingn" value="1" {...register('f_14')}
                                  checked={F14 === "1"}
                                  onChange={handleChange}/>
                              <label htmlFor="starn-1" title="1 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              13. Real-time guidance of doctor by checking patient record and web
                              info
                            </label>
                            <div className="star-rating mb-3">
                              <input id="staro-5" type="radio" name="ratingo" value="5" {...register('f_15')}
                                  checked={F15 === "5"}
                                  onChange={handleChange}
                                />
                              <label htmlFor="staro-5" title="5 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="staro-4" type="radio" name="ratingo" value="4" {...register('f_15')}
                                  checked={F15 === "4"}
                                  onChange={handleChange}/>
                              <label htmlFor="staro-4" title="4 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="staro-3" type="radio" name="ratingo" value="3" {...register('f_15')}
                                  checked={F15 === "3"}
                                  onChange={handleChange}/>
                              <label htmlFor="staro-3" title="3 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="staro-2" type="radio" name="ratingo" value="2" {...register('f_15')}
                                  checked={F15 === "2"}
                                  onChange={handleChange}/>
                              <label htmlFor="staro-2" title="2 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                              <input id="staro-1" type="radio" name="ratingo" value="1" {...register('f_15')}
                                  checked={F15 === "1"}
                                  onChange={handleChange}/>
                              <label htmlFor="staro-1" title="1 stars">
                                <i className="active fa fa-star" aria-hidden="true"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      <p className="font_22  font_600 text-blue  mb-0">
                        AI VITAL SIGNS AND AI DOCTOR APPS
                      </p>
                      <hr className="mb-4" />
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              Vital Signs App
                            </label>
                            <select className="form-select" aria-label="Default select example" {...register('ai_1')}>
                              <option value="">Select Options</option>
                              <option value={1}>Once/week</option>
                              <option value={2}>Twice/week</option>
                              <option value={3}>Once/day</option>
                              <option value={4}>Twice/day</option>
                              <option value={5}>Over three times/day</option>
                            </select>
                          <div className="invalid-feedback">{errors.ai_1?.message}</div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              AI doctors
                            </label>
                            <select className="form-select" aria-label="Default select example" {...register('ai_2')}>
                              <option value="">Select Options</option>
                              <option value={1}>Once/week</option>
                              <option value={2}>Twice/week</option>
                              <option value={3}>Once/day</option>
                              <option value={4}>Twice/day</option>
                              <option value={5}>Over three times/day</option>
                            </select>
                          <div className="invalid-feedback">{errors.ai_2?.message}</div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              Are the AI Vitals and doctor apps easy to use?
                            </label>
                            <div className="row">
                              <div className="col-lg-6">
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  {...register('ai_3')}
                                >
                                  <option value="">Select Options</option>
                                  <option value={1}>Not Very Easy</option>
                                  <option value={2}>Not Easy</option>
                                  <option value={3}>Somewhat Easy</option>
                                  <option value={4}>Easy</option>
                                  <option value={5}>Very Easy</option>
                                </select>
                              </div>
                              <div className="invalid-feedback">{errors.ai_3?.message}</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              Would you use these apps to prevent you from getting sick?
                            </label>
                            <div className="row">
                              <div className="col-lg-6">
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  {...register('ai_4')}
                                >
                                  <option value="">Select Options</option>
                                  <option value={1}>Not Very Easy</option>
                                  <option value={2}>Not Easy</option>
                                  <option value={3}>Somewhat Easy</option>
                                  <option value={4}>Easy</option>
                                  <option value={5}>Very Easy</option>
                                </select>
                              </div>
                              <div className="invalid-feedback">{errors.ai_4?.message}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      <p className="font_22  font_600 text-blue  mb-0">
                        HOW LONG WOULD YOU STAY ON THE PLATFORM SECTIONS?
                      </p>
                      <hr className="mb-4" />
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              On the platform
                            </label>
                            <select className="form-select" aria-label="Default select example" {...register('p_1')}>
                              <option value="">Select Minutes</option>
                              <option value={1}>5 - 10 minutes</option>
                              <option value={2}>10 - 20 minutes</option>
                              <option value={3}>20 - 30 minutes</option>
                              <option value={4}>30 - 40 minutes</option>
                              <option value={5}>Over 50 minutes</option>
                            </select>
                          <div className="invalid-feedback">{errors.p_1?.message}</div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              On the vital signs and AI doctors
                            </label>
                            <select className="form-select" aria-label="Default select example" {...register('p_2')}>
                              <option value="">Select Minutes</option>
                              <option value={1}>5 - 10 minutes</option>
                              <option value={2}>10 - 20 minutes</option>
                              <option value={3}>20 - 30 minutes</option>
                              <option value={4}>30 - 40 minutes</option>
                              <option value={5}>Over 50 minutes</option>
                            </select>
                          <div className="invalid-feedback">{errors.p_2?.message}</div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              On the Human Doctor and Pharmacy sections and Videoconference
                              centers
                            </label>
                            <div className="row">
                              <div className="col-lg-6">
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  {...register('p_3')}
                                >
                                  <option value="">Select Minutes</option>
                                  <option value={1}>5 - 10 minutes</option>
                                  <option value={2}>10 - 20 minutes</option>
                                  <option value={3}>20 - 30 minutes</option>
                                  <option value={4}>30 - 40 minutes</option>
                                  <option value={5}>Over 50 minutes</option>
                                </select>
                              </div>
                              <div className="invalid-feedback">{errors.p_3?.message}</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              On the Medical Community Support Rooms
                            </label>
                            <div className="row">
                              <div className="col-lg-6">
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  {...register('p_4')}
                                >
                                  <option value="">Select Minutes</option>
                                  <option value={1}>5 - 10 minutes</option>
                                  <option value={2}>10 - 20 minutes</option>
                                  <option value={3}>20 - 30 minutes</option>
                                  <option value={4}>30 - 40 minutes</option>
                                  <option value={5}>Over 50 minutes</option>
                                </select>
                              </div>
                              <div className="invalid-feedback">{errors.p_4?.message}</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              On the Prayer Support Rooms
                            </label>
                            <div className="row">
                              <div className="col-lg-6">
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  {...register('p_5')}
                                >
                                  <option value="">Select Minutes</option>
                                  <option value={1}>5 - 10 minutes</option>
                                  <option value={2}>10 - 20 minutes</option>
                                  <option value={3}>20 - 30 minutes</option>
                                  <option value={4}>30 - 40 minutes</option>
                                  <option value={5}>Over 50 minutes</option>
                                </select>
                              </div>
                              <div className="invalid-feedback">{errors.p_5?.message}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      <p className="font_22  font_600 text-blue  mb-0">PRAYER SUPPORT ROOMS</p>
                      <hr className="mb-4" />
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              1. How regularly would you visit?{" "}
                            </label>
                            <select className="form-select" aria-label="Default select example" {...register('pr_1')}>
                              <option value="">Select Options</option>
                              <option value={1}>Once/week</option>
                              <option value={2}>Twice/week</option>
                              <option value={3}>Once/day</option>
                              <option value={4}>Twice/day</option>
                              <option value={5}>Over three times/day</option>
                            </select>
                          <div className="invalid-feedback">{errors.pr_1?.message}</div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              2. Would you participate in the 24/7 ongoing prayers?{" "}
                            </label>
                            <select className="form-select" aria-label="Default select example" {...register('pr_2')}>
                              <option value="">Select Options</option>
                              <option value={1}>Once/week</option>
                              <option value={2}>Twice/week</option>
                              <option value={3}>Once/day</option>
                              <option value={4}>Twice/day</option>
                              <option value={5}>Over three times/day</option>
                            </select>
                            <div className="invalid-feedback">{errors.pr_2?.message}</div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 col-12">
                            <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                                3. Would you submit names of sick friends and relatives so that the
                                community can pray for them (all privacy protected)?
                            </label>
                            <div className="d-flex gap-3 align-items-center">
                                <div className="form-check form-custom d-flex align-items-center gap-1">
                                <input
                                    className="form-check-input radio"
                                    type="radio"
                                    name="flexRadioDefault1"
                                    id="flexRadioDefault4"
                                    {...register('pr_3')}
                                    value="1"
                                    checked={PR3 === "1"}
                                    onChange={handleChange}
                                />
                                <label className="form-check-label" htmlFor="flexRadioDefault4">
                                    Yes
                                </label>
                                </div>
                                <div className="form-check form-custom d-flex align-items-center gap-1">
                                <input
                                    className="form-check-input radio"
                                    type="radio"
                                    name="flexRadioDefault1"
                                    id="flexRadioDefault4"
                                    {...register('pr_3')}
                                    value="0"
                                    checked={PR3 === "0"}
                                    onChange={handleChange}
                                />
                                <label className="form-check-label" htmlFor="flexRadioDefault4">
                                    No
                                </label>
                                </div>
                                <div className="invalid-feedback">{errors.pr_3?.message}</div>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 col-12">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              4. Would you like particular Pastors to participate in prayer
                              sessions?
                            </label>
                            <div className="d-flex gap-3 align-items-center">
                              <div className="form-check form-custom d-flex align-items-center gap-1">
                                <input
                                  className="form-check-input radio"
                                  type="radio"
                                  name="flexRadioDefault1"
                                  id="flexRadioDefault5"
                                  {...register('pr_4')}
                                  value="1"
                                  checked={PR4 === "1"}
                                  onChange={handleChange}
                                />
                                <label className="form-check-label" htmlFor="flexRadioDefault5">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check form-custom d-flex align-items-center gap-1">
                                <input
                                  className="form-check-input radio"
                                  type="radio"
                                  name="flexRadioDefault1"
                                  id="flexRadioDefault5"
                                  {...register('pr_4')}
                                  value="0"
                                  checked={PR4 === "0"}
                                  onChange={handleChange}
                                />
                                <label className="form-check-label" htmlFor="flexRadioDefault5">
                                  No
                                </label>
                              </div>
                              <div className="invalid-feedback">{errors.pr_4?.message}</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-xl-12 col-12">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              5. What other features would you like to see in the prayer room?
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Leave a comment here"
                              id="floatingTextarea2"
                              style={{ height: 100 }}
                              defaultValue={""}
                              {...register('prayer_room_comments')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-3">
                        <p className="font_22  font_600 text-blue  mb-0">
                          MEDICAL COMMUNITY SUPPORT ROOMS
                        </p>
                        <hr className="mb-4" />
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label htmlFor="exampleFormControlInput1" className="form-label">
                                1. How regularly would you visit?{" "}
                              </label>
                              <select className="form-select" aria-label="Default select example" {...register('mr_1')}>
                                <option value="">Select Options</option>
                                <option value={1}>Once/week</option>
                                <option value={2}>Twice/week</option>
                                <option value={3}>Once/day</option>
                                <option value={4}>Twice/day</option>
                                <option value={5}>Over three times/day</option>
                              </select>
                            <div className="invalid-feedback">{errors.mr_1?.message}</div>
                            </div>
                          </div>
                          <div className="col-lg-12 col-xl-12 col-12">
                            <div className="mb-3">
                              <label htmlFor="exampleFormControlInput1" className="form-label">
                                2. Would you participate in the 24/7 ongoing discussions with
                                specialist doctors and other people having medical challenges?{" "}
                              </label>
                              <div className="star-rating mb-3">
                                <input id="starp-5" type="radio" name="ratingp" value="5" {...register('mr_2')}
                                    checked={MR2 === "5"}
                                    onChange={handleChange}
                                  />
                                <label htmlFor="starp-5" title="5 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                <input id="starp-4" type="radio" name="ratingp" value="4" {...register('mr_2')}
                                    checked={MR2 === "4"}
                                    onChange={handleChange}/>
                                <label htmlFor="starp-4" title="4 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                <input id="starp-3" type="radio" name="ratingp" value="3" {...register('mr_2')}
                                    checked={MR2 === "3"}
                                    onChange={handleChange}/>
                                <label htmlFor="starp-3" title="3 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                <input id="starp-2" type="radio" name="ratingp" value="2" {...register('mr_2')}
                                    checked={MR2 === "2"}
                                    onChange={handleChange}/>
                                <label htmlFor="starp-2" title="2 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                <input id="starp-1" type="radio" name="ratingp" value="1" {...register('mr_2')}
                                    checked={MR2 === "1"}
                                    onChange={handleChange}/>
                                <label htmlFor="starp-1" title="1 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-xl-6 col-12">
                            <div className="mb-3">
                              <label htmlFor="exampleFormControlInput1" className="form-label">
                                3. Would you submit questions to doctors?{" "}
                              </label>
                              <div className="star-rating mb-3">
                                <input id="starq-5" type="radio" name="ratingq" value="5" {...register('mr_3')}
                                    checked={MR3 === "5"}
                                    onChange={handleChange}
                                  />
                                <label htmlFor="starq-5" title="5 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                <input id="starq-4" type="radio" name="ratingq" value="4" {...register('mr_3')}
                                    checked={MR3 === "4"}
                                    onChange={handleChange}/>
                                <label htmlFor="starq-4" title="4 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                <input id="starq-3" type="radio" name="ratingq" value="3" {...register('mr_3')}
                                    checked={MR3 === "3"}
                                    onChange={handleChange}/>
                                <label htmlFor="starq-3" title="3 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                <input id="starq-2" type="radio" name="ratingq" value="2" {...register('mr_3')}
                                    checked={MR3 === "2"}
                                    onChange={handleChange}/>
                                <label htmlFor="starq-2" title="2 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                <input id="starq-1" type="radio" name="ratingq" value="1" {...register('mr_3')}
                                    checked={MR3 === "1"}
                                    onChange={handleChange}/>
                                <label htmlFor="starq-1" title="1 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 col-xl-12 col-12">
                            <div className="mb-3">
                              <label htmlFor="exampleFormControlInput1" className="form-label">
                                4. Would you attend webinars offered by specialists in the fields
                                (e.g. Cardiovasular, hypertension, diabetes, cancer, sexual health
                                and reproduction, wellness and nutrition, mental health, well
                                mother and child?)
                              </label>
                              <div className="star-rating mb-3">
                                <input id="starr-5" type="radio" name="ratingr" value="5" {...register('mr_4')}
                                    checked={MR4 === "5"}
                                    onChange={handleChange}
                                  />
                                <label htmlFor="starr-5" title="5 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                <input id="starr-4" type="radio" name="ratingr" value="4" {...register('mr_4')}
                                    checked={MR4 === "4"}
                                    onChange={handleChange}/>
                                <label htmlFor="starr-4" title="4 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                <input id="starr-3" type="radio" name="ratingr" value="3" {...register('mr_4')}
                                    checked={MR4 === "3"}
                                    onChange={handleChange}/>
                                <label htmlFor="starr-3" title="3 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                <input id="starr-2" type="radio" name="ratingr" value="2" {...register('mr_4')}
                                    checked={MR4 === "2"}
                                    onChange={handleChange}/>
                                <label htmlFor="starr-2" title="2 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                <input id="starr-1" type="radio" name="ratingr" value="1" {...register('mr_4')}
                                    checked={MR4 === "1"}
                                    onChange={handleChange}/>
                                <label htmlFor="starr-1" title="1 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 col-xl-12 col-12">
                            <div className="mb-3">
                              <label htmlFor="exampleFormControlInput1" className="form-label">
                                5. What features would you like to see IN THE Medical Community
                                Support Room?
                              </label>
                              <textarea
                                className="form-control"
                                placeholder="Leave a comment here"
                                id="floatingTextarea2"
                                style={{ height: 100 }}
                                defaultValue={""}
                                {...register('medical_community_room_comments')}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3">
                        <p className="font_22  font_600 text-blue  mb-0">
                          HOW MUCH WOULD YOU BE READY TO PAY MONTHLY TO USE THE PLATFORM?
                        </p>
                        <hr className="mb-4" />
                        <div className="row">
                          <div className="col-lg-12 col-xl-12 col-12">
                            <div className="mb-3">
                              <label htmlFor="exampleFormControlInput1" className="form-label">
                                1. What % of your total healthcare costs is NGN1000/month?
                              </label>
                              <div className="star-rating mb-3">
                                <div>
                                  <div>
                                    <input
                                      id="stars-5"
                                      type="checkbox"
                                      name="ratingss"
                                      value="5"
                                      {...register('r_1')}
                                      checked={R1 === "5"}
                                      onChange={handleChange}
                                    />
                                    <label htmlFor="stars-5" title="5 stars">
                                      <i className="active fa fa-star" aria-hidden="true"></i>
                                    </label>
                                  </div>
                                  <p className="mb-0">10%&lt; </p>
                                </div>
                                <div>
                                  <div>
                                    <input
                                      id="stars-4"
                                      type="checkbox"
                                      name="ratingss"
                                      value="4"
                                      {...register('r_1')}
                                      checked={R1 === "4"}
                                      onChange={handleChange}
                                    />
                                    <label htmlFor="stars-4" title="4 stars">
                                      <i className="active fa fa-star" aria-hidden="true"></i>
                                    </label>
                                  </div>
                                  <p className="mb-0">6-8% </p>
                                </div>
                                <div>
                                  <div>
                                    <input
                                      id="stars-3"
                                      type="checkbox"
                                      name="ratingss"
                                      value="3"
                                      {...register('r_1')}
                                      checked={R1 === "3"}
                                      onChange={handleChange}
                                    />
                                    <label htmlFor="stars-3" title="3 stars">
                                      <i className="active fa fa-star" aria-hidden="true"></i>
                                    </label>
                                  </div>
                                  <p className="mb-0">4-6% </p>
                                </div>
                                <div>
                                  <div>
                                    <input
                                      id="stars-2"
                                      type="checkbox"
                                      name="ratingss"
                                      value="2"
                                      {...register('r_1')}
                                      checked={R1 === "2"}
                                      onChange={handleChange}
                                    />
                                    <label htmlFor="stars-2" title="2 stars">
                                      <i className="active fa fa-star" aria-hidden="true"></i>
                                    </label>
                                  </div>
                                  <p className="mb-0">2-4% </p>
                                </div>
                                <div>
                                  <div>
                                    <input
                                      id="stars-1"
                                      type="checkbox"
                                      name="ratingss"
                                      value="1"
                                      {...register('r_1')}
                                      checked={R1 === "1"}
                                      onChange={handleChange}
                                    />
                                    <label htmlFor="stars-1" title="1 star">
                                      <i className="active fa fa-star" aria-hidden="true"></i>
                                    </label>
                                  </div>
                                  <p className="mb-0">0-2% </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 col-xl-12 col-12">
                            <div className="mb-3">
                              <label htmlFor="exampleFormControlInput1" className="form-label">
                                2. Would you pay N1000/month if it helped prevent illness and
                                saved you N1250/month in healthcare costs?{" "}
                              </label>
                              <div className="star-rating mb-3">
                                <input id="start-5" type="radio" name="ratingt" value="5" {...register('r_2')}
                                    checked={R2 === "5"}
                                    onChange={handleChange}
                                  />
                                <label htmlFor="start-5" title="5 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                <input id="start-4" type="radio" name="ratingt" value="4" {...register('r_2')}
                                    checked={R2 === "4"}
                                    onChange={handleChange}/>
                                <label htmlFor="start-4" title="4 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                <input id="start-3" type="radio" name="ratingt" value="3" {...register('r_2')}
                                    checked={R2 === "3"}
                                    onChange={handleChange}/>
                                <label htmlFor="start-3" title="3 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                <input id="start-2" type="radio" name="ratingt" value="2" {...register('r_2')}
                                    checked={R2 === "2"}
                                    onChange={handleChange}/>
                                <label htmlFor="start-2" title="2 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                <input id="start-1" type="radio" name="ratingt" value="1" {...register('r_2')}
                                    checked={R2 === "1"}
                                    onChange={handleChange}/>
                                <label htmlFor="start-1" title="1 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 col-xl-12 col-12">
                            <div className="mb-3">
                              <label htmlFor="exampleFormControlInput1" className="form-label">
                                3. Would you use this platform regularly?
                              </label>
                              <div className="star-rating mb-3">
                                <input id="staru-5" type="radio" name="ratingu" value="5" {...register('r_3')}
                                    checked={R3 === "5"}
                                    onChange={handleChange}
                                  />
                                <label htmlFor="staru-5" title="5 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                <input id="staru-4" type="radio" name="ratingu" value="4" {...register('r_3')}
                                    checked={R3 === "4"}
                                    onChange={handleChange}/>
                                <label htmlFor="staru-4" title="4 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                <input id="staru-3" type="radio" name="ratingu" value="3" {...register('r_3')}
                                    checked={R3 === "3"}
                                    onChange={handleChange}/>
                                <label htmlFor="staru-3" title="3 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                <input id="staru-2" type="radio" name="ratingu" value="2" {...register('r_3')}
                                    checked={R3 === "2"}
                                    onChange={handleChange}/>
                                <label htmlFor="staru-2" title="2 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                <input id="staru-1" type="radio" name="ratingu" value="1" {...register('r_3')}
                                    checked={R3 === "1"}
                                    onChange={handleChange}/>
                                <label htmlFor="staru-1" title="1 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 col-xl-12 col-12">
                            <div className="mb-3">
                              <label htmlFor="exampleFormControlInput1" className="form-label">
                                4. Would you recommend this platform to friends and family?
                              </label>
                              <div className="star-rating mb-3">
                                <input id="starv-5" type="radio" name="ratingv" value="5" {...register('r_4')}
                                    checked={R4 === "5"}
                                    onChange={handleChange}
                                  />
                                <label htmlFor="starv-5" title="5 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                <input id="starv-4" type="radio" name="ratingv" value="4" {...register('r_4')}
                                    checked={R4 === "4"}
                                    onChange={handleChange}/>
                                <label htmlFor="starv-4" title="4 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                <input id="starv-3" type="radio" name="ratingv" value="3" {...register('r_4')}
                                    checked={R4 === "3"}
                                    onChange={handleChange}/>
                                <label htmlFor="starv-3" title="3 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                <input id="starv-2" type="radio" name="ratingv" value="2" {...register('r_4')}
                                    checked={R4 === "2"}
                                    onChange={handleChange}/>
                                <label htmlFor="starv-2" title="2 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                <input id="starv-1" type="radio" name="ratingv" value="1" {...register('r_4')}
                                    checked={R4 === "1"}
                                    onChange={handleChange}/>
                                <label htmlFor="starv-1" title="1 stars">
                                  <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3">
                        <p className="font_22  font_600 text-blue  mb-0">GENERAL COMMENTS</p>
                        <hr className="mb-4" />
                        <div className="col-lg-12 col-xl-12 col-12">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              What are your “WOW” items you really love about the platform?
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Leave a comment here"
                              id="floatingTextarea2"
                              style={{ height: 100 }}
                              defaultValue={""}
                              {...register('gc_1')}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-xl-12 col-12">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              What are your “NO-WAY” items you don't like and want to see improved
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Leave a comment here"
                              id="floatingTextarea2"
                              style={{ height: 100 }}
                              defaultValue={""}
                              {...register('gc_2')}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-xl-12 col-12">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              What factors do you think will help address our universal primary
                              care objectives?
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Leave a comment here"
                              id="floatingTextarea2"
                              style={{ height: 100 }}
                              defaultValue={""}
                              {...register('gc_3')}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-xl-12 col-12">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              What works well on the platform?
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Leave a comment here"
                              id="floatingTextarea2"
                              style={{ height: 100 }}
                              defaultValue={""}
                              {...register('gc_4')}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-xl-12 col-12">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              What does not work well on the platform?
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Leave a comment here"
                              id="floatingTextarea2"
                              style={{ height: 100 }}
                              defaultValue={""}
                              {...register('gc_5')}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-xl-12 col-12">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              What else would you like to see as part of platform services?
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Leave a comment here"
                              id="floatingTextarea2"
                              style={{ height: 100 }}
                              defaultValue={""}
                              {...register('gc_6')}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-xl-12 col-12">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              What would make you revisit the platform regularly?
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Leave a comment here"
                              id="floatingTextarea2"
                              style={{ height: 100 }}
                              defaultValue={""}
                              {...register('gc_7')}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-xl-12 col-12">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              What would you like to change on these vitals and doctor apps?
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Leave a comment here"
                              id="floatingTextarea2"
                              style={{ height: 100 }}
                              defaultValue={""}
                              {...register('gc_8')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-md-6">
                        <button type="submit" className="submit-btn mt-3 w-100">Submit</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
    </>
  );
}

export default App;