import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { doctorAppointment, getDoctorSpecialities } from '../../../api/apiHandler';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import $ from 'jquery';

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";

import FeatherIcon from "feather-icons-react";
// import Tooltip from '@material-ui/core/Tooltip';
// import Tooltip from '@mui/material/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
// import IconButton from '@mui/material/IconButton';
import Swal from 'sweetalert2';
import { renderToStaticMarkup } from 'react-dom/server'
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import ReactDOM from 'react-dom';
import Select from 'react-select';


function DoctorAppointment() {
    const navigate = useNavigate();

    const [doctorAppointmentList, setDoctorAppointmentList] = useState(null);
    const [doctorSpecialities,setDoctorSpecialities] = useState([]);
    const [totalRecordCount, setTotalRecordCount] = useState(0)
    const [pageNo, setPageNo] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchSpecialityId, setSearchSpecialityId] = useState('');
    
    useEffect(() => {
      if(searchQuery == ''){
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading();
            }
        });
      }

        doctorAppointment({ "page": pageNo, "record_count": perPage, "search_text": searchQuery, "search_speciality_id":searchSpecialityId }).then((res) => {
            var response = JSON.parse(res)
            if (response.code == process.env.REACT_APP_SUCCESS) {
              Swal.close()
              setDoctorAppointmentList(response.data.doctors)
              setTotalRecordCount(Math.ceil(response.data.total_record_count / perPage))
            }
            else {
              setDoctorAppointmentList([]);
            }
          });

    }, [searchQuery, searchSpecialityId, pageNo]);

    useEffect(()=>{
      getDoctorSpecialities({}).then((response) => {
        response = JSON.parse(response)
        if (response.code == process.env.REACT_APP_SUCCESS) {
          response.data.map((item)=>{
              const specilityObj = {
                  label: item.name, 
                  value: item.id,
                  icon: item.icon,
              }
              doctorSpecialities.push(specilityObj)
          })
          // setDoctorSpecialities(response.data)
        }
      })
    },[])

    
    const handlePageClick = (event) => {
        setPageNo(event.selected + 1)
    };

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
    };

    const handleSearchSpecialityId = (data) => {
      // console.log("sss>>>>>>>>>>",data.value);
      setSearchSpecialityId(data.value)
    }

    if (doctorAppointmentList == null) return <> </>

    return (
      <>
      <Header />
      <section className="doctorsec-pad">
        <div className="container">
          <div className="mt-4">
            <p className="font_22 font_600 mb-0">Book Appointments </p>
            {/* <nav style={{ '--bs-breadcrumb-divider': '>' }} aria-label="breadcrumb">
              <ol className="breadcrumb">
                <Link to="/doctor/home" className="font_14">
                  Home
                </Link>
                <li className="breadcrumb-item active font_14" aria-current="page"> Doctors</li>
              </ol>
            </nav> */}
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                    <Link to="/patient/home" className="font_14">
                        Home
                    </Link>
                    </li>
                    <li className="breadcrumb-item active font_14" aria-current="page"> Book Appointments</li>
                </ol>
            </nav>
          </div>
          <div className="card">
            <div className="card-body doctor-table-card">
              <div className="table-responsive">
              <div className="row justify-content-end">
              <div className="search-bar col-lg-3 col-md-3">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearch}
                    />
              </div>
              <div className="col-lg-3 col-md-3">
                <Select
                    className="react-select__menu"
                    classNamePrefix="select"
                    name="speacialization"
                    options={doctorSpecialities}
                    onChange={handleSearchSpecialityId}
                  />
                  {/* <select onChange={handleSearchSpecialityId}>
                    <option value="">Select specialization</option>
                    {doctorSpecialities.map((item, key) => (
                    <option key={key} value={item.id}>
                        {item.name}
                    </option>
                    ))}
                  </select> */}
              </div>
              </div>
              <table id="mangeUser" className="table table-hover dataTable js-exportable">
                    <thead>
                        <tr>
                            <th >ID</th>
                            <th >Doctor Name</th>
                            <th >Phone Number</th>
                            <th >Email Address</th>
                            <th >Action</th>
                            {/* <th >Address</th>
                            <th >Hospital</th>
                            <th >Status</th>
                            <th >Action</th> */}
                        </tr>
                    </thead>

                    <tbody>

                        {
                            doctorAppointmentList && doctorAppointmentList.map((item, index) => {
                                return (
                                    <tr id={item.id} key={index}>
                                        <td >
                                            {item.id}
                                        </td>
                                        <td>
                                            <h3 className="doc-details">
                                                <div className="doc-profile">
                                                    <img src={item.profile_image} alt="" />
                                                </div>
                                                {item.first_name} {item.middle_name} {item.last_name}
                                            </h3>
                                        </td>
                                        <td >
                                            {item.country_code + " " + item.phone_no}
                                        </td>
                                        <td >
                                            {item.email}
                                        </td>
                                        <td>
                                            {/* <Link to={`/patient/appointment/id=${item.id}`} className="connect-btn">Book Appointment</Link> */}
                                            <Link to={`/patient/appointment/${item.id}`} className="connect-btn">Book Appointment</Link>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=" > "
                    onPageChange={handlePageClick}
                    pageCount={totalRecordCount}
                    previousLabel=" < "
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    disabledClassName="disabled"
                    activeClassName="active"
                    activeLinkClassName="active-link"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
    );
}

export default DoctorAppointment;