import React,{useState,useEffect} from 'react';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import { Link, useNavigate } from 'react-router-dom';
import { patientAppointmentRequests,connectedPatientList } from '../../../api/apiHandler';

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';

function ShareMedicalList() {

  const navigate = useNavigate();
  const [patientMedicalList, setPatientMedicalList] = useState([]);
  const [totalRecordCount, setTotalRecordCount] = useState(0)
  const [pageNo, setPageNo] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if(searchQuery == ''){
      Swal.fire({
        title: 'Please wait...',
        didOpen: () => {
            Swal.showLoading();
        }
      });
    }
    connectedPatientList({ "page": pageNo, "record_count": perPage, "search_text": searchQuery}).then((response) => {
      Swal.close();
      response = JSON.parse(response)
      console.log("response",response);
      if (response.code == process.env.REACT_APP_SUCCESS) {
        setPatientMedicalList(response.data.patient_data)
        setTotalRecordCount(Math.ceil(response.data.total_record_count / perPage))
      } else if (response.code == process.env.REACT_APP_ERROR) {
        setPatientMedicalList([]);
        Swal.fire({
            position: 'top-end',
            icon: 'info',
            title: response.message,
            toast: true,
            showConfirmButton: false,
            timer: 2000
        })
      } else {
          setPatientMedicalList([]);
          Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: response.message,
              toast: true,
              showConfirmButton: false,
              timer: 2000
          })
      }
    })

    // Swal.close();
  }, [searchQuery, pageNo]);


  const handlePageClick = (event) => {
    setPageNo(event.selected + 1)
  };

  
  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  const handleShareMedicalRecord = (patientId) => {
    navigate('/doctor/share-medical',{ state : patientId })
  }

  return (
    <main className="bg-color">
        <Header/>
        <section className="doctorsec-pad share-medical">
            <div className="container">
            <div>
                <p className="font_22 font_600 mb-0">Share Medical Record</p>
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                    <Link to="/" className="font_14">
                        Home
                    </Link>
                    </li>
                    <li className="breadcrumb-item active font_14" aria-current="page">
                    Share Medical Record
                    </li>
                </ol>
                </nav>
            </div>
            <div className="card">
            <div className="card-body doctor-table-card">
              <div className="table-responsive">
                <div className="row justify-content-end">
                <div className="search-bar col-lg-3 col-md-3">
                      <input
                          className="form-control"
                          type="text"
                          placeholder="Search..."
                          value={searchQuery}
                          onChange={handleSearch}
                      />
                </div>
                </div>
                <table id="mangeUser" className="table table-hover dataTable js-exportable">
                  <thead>
                    <tr>
                      <th >ID</th>
                      <th >Patient Name</th>
                      <th >Phone Number</th>
                      <th >Email</th>
                      <th >Action</th>
                    </tr>
                  </thead>

                  <tbody>

                    {
                      patientMedicalList && patientMedicalList.map((item, index) => {
                        return (
                          <tr id={item.patient_information.id} key={index}>
                            <td >
                              {item.patient_information.id}
                            </td>

                            <td>
                              <h3 className="doc-details">
                                <div className="doc-profile">
                                  <img src={item.patient_information.profile_image} alt="" />
                                </div>
                                {/* {item.first_name} {item.middle_name} {item.last_name} */}
                                {item.patient_information.patient_full_name}
                              </h3>
                            </td>
                            <td >
                              {item.patient_information.country_code + " " + item.patient_information.patient_phone_no}
                              {/* {item.patient_information.patient_phone_no} */}
                            </td>
                            <td >
                              {item.patient_information.patient_email}
                            </td>
                            <td >
                                <button className="connect-btn w-fit-content" onClick={()=>handleShareMedicalRecord(item.patient_information.id)}>Share Medical Record</button>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=" > "
                  onPageChange={handlePageClick}
                  pageCount={totalRecordCount}
                  previousLabel=" < "
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  disabledClassName="disabled"
                  activeClassName="active"
                  activeLinkClassName="active-link"
                />
              </div>
            </div>
          </div>
            </div>
        </section>
        <Footer/>
    </main>
  )
}

export default ShareMedicalList;
