import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Signup from './pages/common/auth/Signup';
import EmailVerification from './pages/common/auth/EmailVerification';
import Login from './pages/common/auth/Login';
import ForgotPassword from './pages/common/auth/ForgotPassword';
import ChangePassword from './pages/common/auth/ChangePassword';
import Logout from './pages/common/auth/Logout';
import PageNotFound from './pages/common/404/PageNotFound';
import MeetingRoom from './pages/common/chime/MeetingRoom';
// import MeetingRoom from './pages/common/chime/TranscriptComponent';

import ChatMoslem from './pages/common/prayer-room/ChatMoslem';
import ChatChristian from './pages/common/prayer-room/ChatChristian';

import CancerCommunity from './pages/common/medical-support/CancerCommunity'
import CardiovascularCommunity from './pages/common/medical-support/CardiovascularCommunity';
import DiabetesCommunity from './pages/common/medical-support/DiabetesCommunity';
import GastroIntestinal from './pages/common/medical-support/GastroIntestinal';
import GeriatricsCommunity from './pages/common/medical-support/GeriatricsCommunity';
import HypertensionCommunity from './pages/common/medical-support/HypertensionCommunity';
import InfeciousCommunity from './pages/common/medical-support/InfeciousCommunity';
import MentalHealthCommunity from './pages/common/medical-support/MentalHealthCommunity';
import ObGynCommunity from './pages/common/medical-support/ObGynCommunity';
import PediatricsCommunity from './pages/common/medical-support/PediatricsCommunity';
import WellnessNutrition from './pages/common/medical-support/WellnessNutrition';

import Home from './pages/patient/home/Home';
import Isabel from './pages/patient/home/SitePalIntegration';
import EditProfile from './pages/patient/profile/Profile';
import PointOfCare from './pages/patient/profile/PointOfCare';
import SearchChatGpt from './pages/patient/profile/SearchChatGpt';
import DoctorListInPatient from './pages/patient/doctor/DoctorList';
import DoctorAppointment from './pages/patient/doctor/DoctorAppointment';
import DoctorMeetingStatus from './pages/patient/meeting-status/MeetingStatus';
import MyAppointments from './pages/patient/my-appointments/MyAppointments';
// import AboutUs from './pages/patient/static/AboutUsPage';
// import Policy from './pages/patient/static/PrivacyPolicyPage';
// import Terms from './pages/patient/static/Terms&ConditionPage';
import AboutUs from './pages/patient/static/StaticAboutUsPage';
import Policy from './pages/patient/static/StaticPrivacyPolicyPage';
import Terms from './pages/patient/static/StaticTerms&ConditionPage';
import SplashScreen from './pages/patient/splash-screen/SplashScreen';
import Feedback from './pages/patient/feedback/Feedback';
import PatientShareMedical from './pages/patient/share-medical/ShareMedical';
import PermissionCode from './pages/patient/permission-code/PermissionCode';
import ReportGenerate from './pages/patient/report/ReportGenerate';
import ReportGeneration from './pages/patient/report/ReportGeneration';
import BookAppointment from './pages/patient/doctor/BookAppointment';
import AiDoctor2 from './pages/patient/ai-doctor-2/AiDoctor2';
import AiDoctor3 from './pages/patient/ai-doctor-3/AiDoctor3';
import DoctorOptions from './pages/patient/doctor-options/DoctorOptions';
import PatientDocsumoResults from './pages/patient/ocr/DocsumoResults';
import PatientChatgpt from './pages/patient/ocr/Chatgpt';

import DoctorHome from './pages/doctor/home/DoctorHome';
import DoctorPoc from './pages/doctor/doctor-poc/DoctorPoc';
import DoctorFeedback from './pages/doctor/feedback/Feedback';
import PatientList from './pages/doctor/patient/PatientList';
import DoctorEditProfile from './pages/doctor/profile/DoctorProfile';
import ShareMedical from './pages/doctor/share-medical/ShareMedical';
import DoctorSplashScreen from './pages/doctor/splash-screen/SplashScreen';
// import DoctorAboutUs from './pages/doctor/static/AboutUsPage';
// import DoctorPolicy from './pages/doctor/static/PrivacyPolicyPage';
// import DoctorTerms from './pages/doctor/static/Terms&ConditionPage';
import DoctorAboutUs from './pages/doctor/static/StaticAboutUsPage';
import DoctorPolicy from './pages/doctor/static/StaticPrivacyPolicyPage';
import DoctorTerms from './pages/doctor/static/StaticTerms&ConditionPage';
import DoctorList from './pages/doctor/doctor-list/DoctorList';
import DoctorIsabel from './pages/doctor/home/SitePalIntegration';
import Prescription from './pages/doctor/prescription/Prescription';
import DoctorSearchChatGpt from './pages/doctor/profile/SearchChatGpt';
import Appointment from './pages/doctor/doctor-appoinment/DoctorAppointment';
import DoctorBinah from './pages/doctor/binah/Binah';
import DoctorReportGenerate from './pages/doctor/report/ReportGenerate';
import DoctorReportGeneration from './pages/doctor/report/ReportGeneration';
import NewDoctorList from './pages/doctor/doctor-list/NewDoctorList';
import DoctorAvailability from './pages/doctor/doctor-availability/DoctorAvailability';
import PatientAppointment from './pages/doctor/patient/PatientAppoinment';
import MeetingStatus from './pages/doctor/meeting-status/MeetingStatus';
import PermissionCodeRequest from './pages/doctor/permission-code-request/PermissionCodeRequest';
import SetTimeslot from './pages/doctor/set-timeslot/SetTimeslot';
import ShareMedicalList from './pages/doctor/share-medical/ShareMedicalList';
import AutoPrescription from './pages/doctor/prescription/AutoPrescription';
import ChemistList from './pages/doctor/chemist-list/ChemistList';
import DocsumoResults from './pages/doctor/ocr/DocsumoResults';
import Chatgpt from './pages/doctor/ocr/Chatgpt';
import DoctorOwnOptions from './pages/doctor/doctor-options/DoctorOptions';

function App() {

  const audioVideoValue = "audio value"
  const appStateValue = "app state value"


  if (!localStorage.getItem("user_login_type_role", false)) {
    return (
      <div className="App h-100">
        <BrowserRouter basename={'/'}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/patient/policy" element={<Policy />} />
            <Route path="/patient/terms" element={<Terms />} />
            <Route path="/patient/about-us" element={<AboutUs />} />
            <Route path="/patient/home" element={<Home />} />
            <Route path="/patient/splash-screen" element={<SplashScreen />} />
            <Route path="/verify/:token" element={<EmailVerification />} />
            <Route path="/meeting/:meetingid" element={<MeetingRoom />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </div>
    )
  } else if (localStorage.getItem("user_login_type_role") == 1){

    return (
      <div className="App h-100">
        <BrowserRouter basename={'/'}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/patient/home" element={<Home />} />
            <Route path="/patient/splash-screen" element={<SplashScreen />} />
            <Route path="/patient/isabel" element={<Isabel />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/patient/edit-profile" element={<EditProfile />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/patient/point-of-care" element={<PointOfCare />} />
            <Route path="/patient/search-chat-gpt/:poc_type" element={<SearchChatGpt />} />
            <Route path="/patient/doctor-list" element={<DoctorListInPatient />} />
            <Route path="/patient/doctor-appointment" element={<DoctorAppointment />} />
            <Route path="/patient/meeting-status" element={<DoctorMeetingStatus />} />
            <Route path="/patient/my-appointments" element={<MyAppointments />} />
            <Route path="/patient/policy" element={<Policy />} />
            <Route path="/patient/terms" element={<Terms />} />
            <Route path="*" element={<PageNotFound />} />
            <Route path="/patient/about-us" element={<AboutUs />} />
            <Route path="/patient/feedback" element={<Feedback />} />
            <Route path="/verify/:token" element={<EmailVerification />} />
            <Route path="/patient/appointment/:id" element={<BookAppointment />} />
            <Route path="/patient/share-medical" element={<PatientShareMedical />} />
            <Route path="/patient/permission-code" element={<PermissionCode />} />
            <Route path="/patient/report-generate" element={<ReportGenerate />} />
            <Route path="/patient/report-generate/:uuid" element={<ReportGenerate />} />
            <Route path="/patient/report-generation" element={<ReportGeneration />} />
            {/* <Route path="/meeting/:meetingid" element={<MeetingRoom />} />
            <Route path="/meeting-room/:appointmentid" element={<MeetingRoom />} /> */}
            <Route path="/meeting/:meetingid" element={<MeetingRoom />} />
            <Route path="/meeting" element={<MeetingRoom />} />
            <Route path="/patient/ai-doctor-2" element={<AiDoctor2 />} />
            <Route path="/patient/ai-doctor-3" element={<AiDoctor3 />} />
            <Route path="/patient/choose-options" element={<DoctorOptions />} />
            <Route path="/patient/ocr" element={<PatientDocsumoResults />} />
            <Route path="/patient/chatgpt" element={<PatientChatgpt />} />

            <Route path="/patient/chatmoslem" element={<ChatMoslem />} />
            <Route path="/patient/chatchristian" element={<ChatChristian />} />

            <Route path="/patient/cancercommunity" element={<CancerCommunity />} />
            <Route path="/patient/cardiovascularcommunity" element={<CardiovascularCommunity />} />
            <Route path="/patient/diabetescommunity" element={<DiabetesCommunity />} />
            <Route path="/patient/gastrointestinalcommunity" element={<GastroIntestinal />} />
            <Route path="/patient/geriatricscommunity" element={<GeriatricsCommunity />} />
            <Route path="/patient/hypertensioncommunity" element={<HypertensionCommunity />} />
            <Route path="/patient/infeciouscommunity" element={<InfeciousCommunity />} />
            <Route path="/patient/mentalhealthcommunity" element={<MentalHealthCommunity />} />
            <Route path="/patient/obgyncommunity" element={<ObGynCommunity />} />
            <Route path="/patient/pediatricscommunity" element={<PediatricsCommunity />} />
            <Route path="/patient/wellnessnutrition" element={<WellnessNutrition />} />
          </Routes>
        </BrowserRouter>
      </div>
    )
  } else {

    return (
      <div className="App h-100">
        <BrowserRouter basename={'/'}>
          <Routes>
            <Route path="/" element={<DoctorHome />} />
            <Route path="/doctor/home" element={<DoctorHome />} />
            <Route path="/doctor/point-of-care" element={<DoctorPoc />} />
            <Route path="/doctor/feedback" element={<DoctorFeedback />} />
            <Route path="/doctor/patient-list" element={<PatientList />} />
            <Route path="/doctor/edit-profile" element={<DoctorEditProfile />} />
            <Route path="/doctor/share-medical" element={<ShareMedical />} />
            <Route path="/doctor/splash-screen" element={<DoctorSplashScreen />} />
            <Route path="/doctor/about-us" element={<DoctorAboutUs />} />
            <Route path="/doctor/policy" element={<DoctorPolicy />} />
            <Route path="/doctor/terms" element={<DoctorTerms />} />
            <Route path="/doctor/doctor-list" element={<DoctorList />} />
            <Route path="/doctor/new-doctor-list" element={<NewDoctorList />} />
            <Route path="/doctor/isabel" element={<DoctorIsabel />} />
            <Route path="/doctor/prescription" element={<Prescription />} />
            <Route path="/doctor/search-chat-gpt/:poc_type" element={<DoctorSearchChatGpt />} />
            <Route path="/doctor/doctor-appointment" element={<Appointment />} />
            <Route path="/doctor/binah" element={<DoctorBinah />} />  
            <Route path="/doctor/report-generate" element={<DoctorReportGenerate />} />
            <Route path="/doctor/report-generate/:uuid" element={<DoctorReportGenerate />} />
            <Route path="/doctor/report-generation" element={<DoctorReportGeneration />} />
            <Route path="/doctor/doctor-availability" element={<DoctorAvailability />} />
            <Route path="/doctor/patient-appointment" element={<PatientAppointment />} />
            <Route path="/doctor/meeting-status" element={<MeetingStatus />} />
            <Route path="/doctor/permission-code-request" element={<PermissionCodeRequest />} />
            <Route path="/doctor/set-timeslot" element={<SetTimeslot />} />
            <Route path="/doctor/share-medical-list" element={<ShareMedicalList />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/verify/:token" element={<EmailVerification />} />
            <Route path="*" element={<PageNotFound />} />
            {/* <Route path="/meeting/:appointmentid" element={<MeetingRoom />} />
            <Route path="/meeting-room/:meetingid" element={<MeetingRoom />} /> */}
            <Route path="/meeting/:meetingid" element={<MeetingRoom />} />
            <Route path="/meeting" element={<MeetingRoom />} />
            <Route path="/doctor/auto-prescription" element={<AutoPrescription />} />
            <Route path="/doctor/chemist-list" element={<ChemistList />} />
            <Route path="/doctor/ocr" element={<DocsumoResults />} />
            <Route path="/doctor/chatgpt" element={<Chatgpt />} />
            <Route path="/doctor/choose-options" element={<DoctorOwnOptions />} />

            <Route path="/doctor/chatmoslem" element={<ChatMoslem />} />
            <Route path="/doctor/chatchristian" element={<ChatChristian />} />

            <Route path="/doctor/cancercommunity" element={<CancerCommunity />} />
            <Route path="/doctor/cardiovascularcommunity" element={<CardiovascularCommunity />} />
            <Route path="/doctor/diabetescommunity" element={<DiabetesCommunity />} />
            <Route path="/doctor/gastrointestinalcommunity" element={<GastroIntestinal />} />
            <Route path="/doctor/geriatricscommunity" element={<GeriatricsCommunity />} />
            <Route path="/doctor/hypertensioncommunity" element={<HypertensionCommunity />} />
            <Route path="/doctor/infeciouscommunity" element={<InfeciousCommunity />} />
            <Route path="/doctor/mentalhealthcommunity" element={<MentalHealthCommunity />} />
            <Route path="/doctor/obgyncommunity" element={<ObGynCommunity />} />
            <Route path="/doctor/pediatricscommunity" element={<PediatricsCommunity />} />
            <Route path="/doctor/wellnessnutrition" element={<WellnessNutrition />} />
          </Routes>
        </BrowserRouter>
      </div>    
    )

  }
}

export default App;
