import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';

function Header() {
  
  const navigate = useNavigate();

  function logoutFun() {
    navigate("/logout");
  }
  function loginFun() {
    navigate("/");
    
  }

  //check user login or not
  if (localStorage.getItem("user_login_type_role", false) && localStorage.getItem("user_login_type_role") == 1) {
    return (
    
      <header>
        <nav className="navbar navbar-expand-lg navbar-light bg-white header-nav">
          <div className="container">
            <Link to="/patient/home" className="navbar-brand"><img src={process.env.PUBLIC_URL + "/assets/images/icon/logo.svg"} alt="" /></Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 gap-2">
                <li className="nav-item">
                  <Link to="/patient/home" className="nav-link active">Home</Link>
                </li>
                {/* <li className="nav-item">
                  <Link to="/patient/about-us" className="nav-link">About us</Link>
                </li> */}
                {/* <li className="nav-item">
                  <Link to="/patient/binah" className="nav-link">Binah Start</Link>
                </li> */}
                
                <li className="nav-item">
                  <Link className="nav-link starpilot-btn p-0" to="#">
                      <img src={process.env.PUBLIC_URL + "/assets/images/icon/starpilot.png"} alt="" />
                      <p className="nav-link nav-text mb-0">SP</p>
                  </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link starpilot-btn p-0" to="/patient/point-of-care">
                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/poc-icon.png"} alt="" />
                        <p className="nav-link nav-text mb-0">POC</p>
                    </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="#"
                    className="nav-link awareness-dropdown dropdown-toggle"
                    id="navbarDropdown1"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/burgundy-ribbon.png"} alt="image" />
                  </Link>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                    <li>
                      <Link className="dropdown-item" to="/patient/cancercommunity">
                        Cancer
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/patient/cardiovascularcommunity">
                        Cardiovascular
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/patient/diabetescommunity">
                        Diabetes
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/patient/gastrointestinalcommunity">
                        Gastro Intestinal
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/patient/geriatricscommunity">
                        Geriatrics
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/patient/hypertensioncommunity">
                        Hypertension
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/patient/infeciouscommunity">
                        Infectious Diseases
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/patient/mentalhealthcommunity">
                        Mental Health
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/patient/obgyncommunity">
                        OB/GYN
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/patient/pediatricscommunity">
                        Pediatrics
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/patient/wellnessnutrition">
                        Wellness &amp; Nutrition
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <li className="nav-item">
                  <Link to="/patient/chatmoslem" className="nav-link prayer-button ">
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/prayer.svg"} alt="image" />
                  </Link>
                </li> */}
                <li className="nav-item dropdown">
                    <Link to="#" className="nav-link awareness-dropdown dropdown-toggle" id="navbarDropdown1"
                        role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/prayer.svg"} alt="image" />
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                        <li><Link className="dropdown-item" to="/patient/chatchristian">Christian</Link></li>
                        <li><Link className="dropdown-item" to="/patient/chatmoslem">Moslem</Link></li>
                    </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {/* <img src={localStorage.getItem("profile_image")} alt="User" width="30px"/> */}
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/user-blue-icon.png"} className="dropdown-icon" alt="User"/>
                    
                  </Link>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li>
                      <Link to="/patient/edit-profile" className="dropdown-item">
                        My Profile
                      </Link>
                    </li>
                    <li>
                    <Link to="/change-password" className="dropdown-item">
                      Change Password
                    </Link>
                    </li>
                    <li>
                    <Link to="/patient/feedback" className="dropdown-item">
                    Feedback
                    </Link>
                    </li>
                    <li>
                    <Link to="/patient/point-of-care" className="dropdown-item">
                    Point of care
                    </Link>
                    </li>
                    <li>
                    <Link className="dropdown-item" to="/patient/my-appointments">
                    My Appointments
                    </Link>
                    </li>
                    <li>
                    <Link className="dropdown-item" to="/patient/meeting-status">
                    Meeting Status
                    </Link>
                    </li>
                    <li>
                    <Link className="dropdown-item" to="/patient/permission-code">
                    Permission Code
                    </Link>
                    </li>
                    <li>
                    <Link className="dropdown-item" to="/patient/ocr">
                    OCR
                    </Link>
                    </li>
                  </ul>
                </li>
              </ul>
              <div className="ms-3">
                <button className="logout-btn" onClick={logoutFun}>
                  <i className="mdi mdi-power text-danger"></i> Logout
                </button>
              </div>
            </div>
          </div>
        </nav>
      </header>
  
    )
  } else if (localStorage.getItem("user_login_type_role", false) && localStorage.getItem("user_login_type_role") == 2){
    return (

      <header>
        <nav className="navbar navbar-expand-lg navbar-light bg-white header-nav">
          <div className="container">
            <Link to="/doctor/home" className="navbar-brand"><img src={process.env.PUBLIC_URL + "/assets/images/icon/logo.svg"} alt="" /></Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 gap-2">
                <li className="nav-item">
                  <Link to="/doctor/home" className="nav-link active">Home</Link>
                </li>
                {/* <li className="nav-item">
                  <Link to="/doctor/about-us" className="nav-link">About us</Link>
                </li> */}
                {/* <li className="nav-item">
                  <Link to="/patient/binah" className="nav-link">Binah Start</Link>
                </li> */}

                <li className="nav-item">
                  <Link className="nav-link starpilot-btn p-0" to="#">
                      <img src={process.env.PUBLIC_URL + "/assets/images/icon/starpilot.png"} alt="" />
                      <p className="nav-link nav-text mb-0">SP</p>
                  </Link>
                </li>
                <li className="nav-item dropdown">
                    <Link className="nav-link starpilot-btn p-0 dropdown-toggle" to="#" id="navbarDropdown" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/poc-icon.png"} alt="" />
                        <p className="nav-link nav-text mb-0">POC</p>
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li>
                        <Link className="dropdown-item" to="/doctor/meeting-status">
                          Prescription
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/doctor/point-of-care">
                          Point of Care
                        </Link>
                      </li>
                    </ul>

                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="#"
                    className="nav-link awareness-dropdown dropdown-toggle"
                    id="navbarDropdown1"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/burgundy-ribbon.png"} alt="image" />
                  </Link>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                    <li>
                      <Link className="dropdown-item" to="/doctor/cancercommunity">
                        Cancer
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/doctor/cardiovascularcommunity">
                        Cardiovascular
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/doctor/diabetescommunity">
                        Diabetes
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/doctor/gastrointestinalcommunity">
                        Gastro Intestinal
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/doctor/geriatricscommunity">
                        Geriatrics
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/doctor/hypertensioncommunity">
                        Hypertension
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/doctor/infeciouscommunity">
                        Infectious Diseases
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/doctor/mentalhealthcommunity">
                        Mental Health
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/doctor/obgyncommunity">
                        OB/GYN
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/doctor/pediatricscommunity">
                        Pediatrics
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/doctor/wellnessnutrition">
                        Wellness &amp; Nutrition
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <li className="nav-item">
                  <Link to="/doctor/chatmoslem" className="nav-link prayer-button ">
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/prayer.svg"} alt="image" />
                  </Link>
                </li> */}
                <li className="nav-item dropdown">
                    <Link to="#" className="nav-link awareness-dropdown dropdown-toggle" id="navbarDropdown1"
                        role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/prayer.svg"} alt="image" />
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                        <li><Link className="dropdown-item" to="/doctor/chatchristian">Christian</Link></li>
                        <li><Link className="dropdown-item" to="/doctor/chatmoslem">Moslem</Link></li>
                    </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {/* <img src={localStorage.getItem("profile_image")} alt="User" width="30px"/> */}
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/user-blue-icon.png"} className="dropdown-icon" alt="User"/>
                    
                  </Link>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li>
                      <Link to="/doctor/edit-profile" className="dropdown-item">
                        My Profile
                      </Link>
                    </li>
                    <li>
                    <Link to="/change-password" className="dropdown-item">
                      Change Password
                    </Link>
                    </li>
                    <li>
                    <Link to="/doctor/feedback" className="dropdown-item">
                    Feedback
                    </Link>
                    </li>
                    <li>
                    <Link to="/doctor/point-of-care" className="dropdown-item">
                    Point of care
                    </Link>
                    </li>
                    <li>
                    <Link to="/doctor/set-timeslot" className="dropdown-item">
                    Set Timeslot
                    </Link>
                    </li>
                    <li>
                    <Link to="/doctor/meeting-status" className="dropdown-item">
                    Meeting Status
                    </Link>
                    </li>
                    <li>
                    <Link to="/doctor/permission-code-request" className="dropdown-item">
                    Permission Code Request
                    </Link>
                    </li>
                    <li>
                    <Link to="/doctor/ocr" className="dropdown-item">
                    OCR
                    </Link>
                    </li>
                  </ul>
                </li>
              </ul>
              <div className="ms-3">
                <button className="logout-btn" onClick={logoutFun}>
                  <i className="mdi mdi-power text-danger"></i> Logout
                </button>
              </div>
            </div>
          </div>
        </nav>
      </header>

    )
  }
  
  else {
    return (
    
      <header>
        <nav className="navbar navbar-expand-lg navbar-light bg-white header-nav">
          <div className="container">
            <Link to="/patient/home" className="navbar-brand"><img src={process.env.PUBLIC_URL + "/assets/images/logo--b.png"} alt="" /></Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 gap-2">
                <li className="nav-item">
                  <Link to="/patient/home" className="nav-link active">Home</Link>
                </li>
                
                {/* <li className="nav-item">
                  <Link to="/patient/about-us" className="nav-link">About us</Link>
                </li> */}
              </ul>
              <div className="ms-3">
                <button className="logout-btn" onClick={loginFun}>
                  <i className="mdi mdi-power text-danger"></i> Login
                </button>
              </div>
            </div>
          </div>
        </nav>
      </header>

    )
  }
  
}

export default Header;